import React from "react";
import { useNavigate } from "react-router-dom";
import { RoutesPaths } from "../Router/constant";

export default function Header() {
  const navigate = useNavigate();
  return (
    <header className="desktopHeader">
      <div className="container">
        <div className="header">
          <div className="row align-items-center">
            <div className="col-12 col-md-6 col-lg-3">
              <div className="logo">
                <span onClick={() => navigate(RoutesPaths.HOME)}>
                  <img src="assets/img/kimbocorp.svg" alt="kimbocorp.svg" />
                </span>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="primary-menu-menu">
                <ul className="menu">
                  <li>
                    <span>
                      Platform{" "}
                      <img alt="down-arrow" src="assets/img/chevron-down.svg" />
                    </span>
                  </li>
                  <li>
                    <a href="/">
                      Use cases{" "}
                      <img alt="down-arrow" src="assets/img/chevron-down.svg" />
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      Why kimbocorp{" "}
                      <img alt="down-arrow" src="assets/img/chevron-down.svg" />
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      Overview{" "}
                      <img alt="down-arrow" src="assets/img/chevron-down.svg" />
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      Contact{" "}
                      <img alt="down-arrow" src="assets/img/chevron-down.svg" />
                    </a>
                  </li>
                </ul>

                <div id="mobile" className="banner-button">
                  <button type="button" className="primary-button">
                    Login
                  </button>
                  <button
                    type="button"
                    // onClick={() => navigate(RoutesPaths.MESSAGESCREEN)}
                    className="secondary-button">
                    Book a demo
                    <img alt="zap" src="assets/img/zap-fast.svg" />
                  </button>
                </div>
              </div>
            </div>
            <div id="desktop" className="col-12 col-md-6 col-lg-3">
              <div className="header-button">
                <button type="button" className="primary-button">
                  Login
                </button>
                <button
                  type="button"
                  // onClick={() => navigate(RoutesPaths.MESSAGESCREEN)}
                  className="secondary-button">
                  Book a demo
                  <img alt="zap" src="assets/img/zap-fast.svg" />
                </button>
              </div>
            </div>
            <div id="mobile" className="bar">
              <img alt="nav" src="assets/img/nav-bar.svg" />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
