import './App.css';
import Footer from './components/Footer';
import Header from './components/Header';
import RouteComponent from './Router/router';
import 'swiper/css';
import 'swiper/css/pagination';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import { useEffect } from 'react';
import moment from 'moment';

function App() {
  useEffect(() => {
    if (!localStorage.getItem('session')) {
      const timestamp = moment().format("DDMMYYYYHHmmss");
      const randomString = Math.random().toString(36).substring(2, 15);
      const sessionId = `${timestamp}-${randomString}`;
      localStorage.setItem('session', sessionId);
    }
  }, []);
  return (
    <>
      <Header />
      <ScrollToTop />
      <section className="body">
        <RouteComponent></RouteComponent>
      </section>
      <Footer />
    </>
  );
}

export default App;
