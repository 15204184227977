import React from "react";
import HeaderBanner from "../components/HeaderBanner";
import SwiperContainer from "../components/SwiperContainer";
import RequestADemo from "../components/RequestADemo";
import NewsLetter from "../components/NewsLetter";

export default function Enterprise() {
  return (
    <div>
      <div className="banner">
        <HeaderBanner
          headerText="Comprehensive Solutions for Enterprises to Build and Manage Commercial Ventures"
          subHeaderText="Empowering Venture Builders, Law Firms, Accountants, Consultants, and Financial Advisors with Integrated Tools and Capabilities"
          desc="At kimbocorp, we provide comprehensive solutions for enterprises such as venture builders, law firms, accountants, management consultants, venture capitalists, private equity firms, financial advisors, investment bankers, brokers, and promoters. Our platform equips you with all the tools and capabilities needed to create and manage commercial ventures, allowing you to build each venture at your own pace and with the highest standards of governance and efficiency."
          img={"assets/img/enterprise-banner-img.png"}
          isDemoHeader={2}
        />
      </div>
      <div className="our-apporach-bg-top">
        <img alt="" src="assets/img/consequences-top-shade.png" />
      </div>
      <div className="our-approach governance">
        <div className="container">
          <div className="our-approach-inner">
            <div className="row text-center">
              <div className="col-md-12 our-approach-content">
                <h2>Our Approach</h2>
                <p>Our #1 priority is your business</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/enterprise-appr-1.svg" />
                  </div>
                  <h4>Improving Governance</h4>
                  <div className="based-accounting-p">
                    <p>
                      We enhance your governance framework, ensuring compliance
                      with statutory and regulatory requirements while fostering
                      transparency and accountability.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/enterprise-appr-2.svg" />
                  </div>
                  <h4>Better Stakeholder Management</h4>
                  <div className="based-accounting-p">
                    <p>
                      Our platform helps you engage and appoint stakeholders
                      effectively, providing valuable advice and documents to
                      streamline processes.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/enterprise-appr-3.svg" />
                  </div>
                  <h4>Data-Driven Decision Making</h4>
                  <div className="based-accounting-p">
                    <p>
                      By leveraging structured data, we help influence client
                      decision-making processes, providing insights and
                      analytics that drive better outcomes.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/enterprise-appr-4.svg" />
                  </div>
                  <h4>Seamless User Experience</h4>
                  <div className="based-accounting-p">
                    <p>
                      We ensure a seamless and non-aversive user experience,
                      making it easy for you and your clients to navigate and
                      utilize our platform.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/enterprise-appr-5.svg" />
                  </div>
                  <h4>Staying Informed</h4>
                  <div className="based-accounting-p">
                    <p>
                      We keep you updated with the latest statutory and company
                      acts, ensuring you remain compliant and informed about all
                      relevant regulations.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="our-apporach-bg-top div-img-block">
        <img alt="" src="assets/img/consequences-bottom-shade-only.png" />
      </div>

      <div className="benefit-solutions taking-action-slot transparent-bg">
        <div className="container">
          <div className="row text-center">
            <div className="col-md-12 our-approach-content">
              <h2>Why Choose kimbocorp?</h2>
              <p>By choosing Kimbocorp, enterprises can</p>
            </div>
          </div>
          <div className="row taking-action-row">
            <div className="col-md-4 p-4">
              <div className="taking-action">
                <div className="taking-action-icon">
                  <img alt="" src="assets/img/enterprise-whychoose-1.svg" />
                </div>
                <h4>Service Clients Better</h4>
                <div className="taking-action-p">
                  <p>
                    Improve your ability to service clients with comprehensive
                    governance and stakeholder management tools.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 p-4">
              <div className="taking-action">
                <div className="taking-action-icon">
                  <img alt="" src="assets/img/enterprise-whychoose-2.svg" />
                </div>
                <h4>Enhance Governance</h4>
                <div className="taking-action-p">
                  <p>
                    Implement robust governance practices that ensure compliance
                    and accountability.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 p-4">
              <div className="taking-action">
                <div className="taking-action-icon">
                  <img alt="" src="assets/img/enterprise-whychoose-3.svg" />
                </div>
                <h4>Engage Stakeholders</h4>
                <div className="taking-action-p">
                  <p>
                    Effectively engage and appoint stakeholders, providing them
                    with the necessary guidance and documentation.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 p-4">
              <div className="taking-action">
                <div className="taking-action-icon">
                  <img alt="" src="assets/img/enterprise-whychoose-4.svg" />
                </div>
                <h4>Data-Driven Approach</h4>
                <div className="taking-action-p">
                  <p>
                    Utilize data to understand client needs and influence
                    decision-making processes.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 p-4">
              <div className="taking-action">
                <div className="taking-action-icon">
                  <img alt="" src="assets/img/enterprise-whychoose-5.svg" />
                </div>
                <h4>Improve User Experience</h4>
                <div className="taking-action-p">
                  <p>
                    Deliver a seamless user experience that enhances client
                    satisfaction.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 p-4">
              <div className="taking-action">
                <div className="taking-action-icon">
                  <img alt="" src="assets/img/enterprise-whychoose-6.svg" />
                </div>
                <h4>Leverage Efficient Capabilities</h4>
                <div className="taking-action-p">
                  <p>
                    Connect to the efficient capabilities that countries like
                    Singapore offers, from banking to regulatory compliance.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="our-apporach-bg-top">
        <img alt="" src="assets/img/our-approach-top-bg-top.png" />
      </div>
      <div className="benefit-solutions accounting">
        <div className="container">
          <div className="benefit-solutions-inner">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="benefit-sol-content">
                  <h2>Consequences of Not Taking Action</h2>
                  <p>Without our comprehensive solutions, you could face:</p>

                  <ul>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/e-ib-1.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>Disconnected Client Relationships</h5>
                        <p>
                          Not being connected with your clients and commercial
                          ventures can lead to missed opportunities and
                          misunderstandings.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/e-ib-2.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>Data Deficiency</h5>
                        <p>
                          Not having data to understand your client's state of
                          mind can hinder your ability to make informed
                          decisions.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/e-ib-3.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>Financial Inaccuracies</h5>
                        <p>
                          Inefficient Operations: Not being connected to
                          Singapore’s efficient capabilities can result in
                          operational inefficiencies and increased costs.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/e-ib-4.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>Aversive Sales Techniques</h5>
                        <p>
                          Relying on aggressive sales techniques can alienate
                          clients and harm your business.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/e-ib-4.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>Lack of Goodwill</h5>
                        <p>
                          Relying on aggressive sales techniques can alienate
                          clients and harm your business.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div id="desktop" className="col-md-6">
                <div className="benefit-sol-content-img">
                  <span className="">
                    <img alt="" src="assets/img/ib-talking.png" className="" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="our-apporach-bg-top">
        <img alt="" src="assets/img/our-approach-top-bg-bottom.png" />
      </div>

      <div className="benefit-solutions invtment-business--benefit-soutions">
        <div className="container">
          <div className="benefit-solutions-inner">
            <div className="row text-center">
              <div className="col-md-12 our-approach-content">
                <h2>Integrated and Holistic Approach</h2>
                <p>Our #1 priority is your business</p>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="benefit-sol-content">
                  <h2>kimbocorp’s integrated approach:</h2>
                  <ul>
                    <li>
                      <div className="benefit-icons">
                        <img
                          alt=""
                          src="assets/img/enterprise-app-icon-1.svg"
                        />
                      </div>
                      <div className="benefit-content">
                        <h5>Holistic Integration</h5>
                        <p>
                          Our platform provides an integrated approach,
                          combining all necessary tools and capabilities under
                          one roof.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="benefit-icons">
                        <img
                          alt=""
                          src="assets/img/enterprise-app-icon-2.svg"
                        />
                      </div>
                      <div className="benefit-content">
                        <h5>Seamless Service Delivery</h5>
                        <p>
                          From governance to stakeholder management and
                          data-driven insights, we ensure a cohesive and
                          efficient service delivery.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="benefit-icons">
                        <img
                          alt=""
                          src="assets/img/enterprise-app-icon-3.svg"
                        />
                      </div>
                      <div className="benefit-content">
                        <h5>Comprehensive Support</h5>
                        <p>
                          We cover all aspects of your enterprise needs,
                          allowing you to focus on building and managing
                          commercial ventures effectively.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div id="desktop" className="col-md-6">
                <div className="benefit-sol-content-img">
                  <span className="">
                    <img
                      alt=""
                      src="assets/img/enterprise-app-image-1.png"
                      className=""
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="row align-items-center flex-row-reverse margin-top-consequences">
              <div className="col-md-6">
                <div className="benefit-sol-content">
                  <h2>Piece-Meal Providers:</h2>
                  <ul>
                    <li>
                      <div className="benefit-icons">
                        <img
                          alt=""
                          src="assets/img/enterprise-app-icon-4.svg"
                        />
                      </div>
                      <div className="benefit-content">
                        <h5>Enhanced Efficiency</h5>
                        <p>
                          Streamlined processes and integrated tools reduce
                          complexity and improve operational efficiency.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="benefit-icons">
                        <img
                          alt=""
                          src="assets/img/enterprise-app-icon-5.svg"
                        />
                      </div>
                      <div className="benefit-content">
                        <h5>Better Client Relationships</h5>
                        <p>
                          Holistic support and valuable insights help you build
                          stronger, more connected client relationships.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="benefit-icons">
                        <img
                          alt=""
                          src="assets/img/enterprise-app-icon-6.svg"
                        />
                      </div>
                      <div className="benefit-content">
                        <h5>Informed Decision Making</h5>
                        <p>
                          Access to comprehensive data and analytics enables
                          better decision-making and improved business outcomes.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="benefit-icons">
                        <img
                          alt=""
                          src="assets/img/enterprise-app-icon-7.svg"
                        />
                      </div>
                      <div className="benefit-content">
                        <h5>Compliance and Governance</h5>
                        <p>
                          Robust governance frameworks and up-to-date compliance
                          support ensure your business remains compliant and
                          accountable.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div id="desktop" className="col-md-6">
                <div className="benefit-sol-content-img">
                  <span className="">
                    <img
                      alt=""
                      src="assets/img/enterprise-app-image-2.png"
                      className=""
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="our-apporach-bg-top">
        <img alt="" src="assets/img/success-story-top-shade.png" />
      </div>
      <div className="success-stories">
        <div className="success-stories-holder">
          <div className="success-stories-content">
            <h2>Success Stories</h2>
            <p>What our client say about our services</p>
          </div>
        </div>

        <div className="swiper-container success-stories-carousel">
          <SwiperContainer />
        </div>
      </div>

      <RequestADemo />
      <NewsLetter />
    </div>
  );
}
