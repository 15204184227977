import React from "react";
import SwiperContainer from "../components/SwiperContainer";
import NewsLetter from "../components/NewsLetter";
import RequestADemo from "../components/RequestADemo";
import HeaderBanner from "../components/HeaderBanner";

export default function ActiveBusiness() {
  return (
    <div>
      <div className="banner">
        <HeaderBanner
          headerText="Comprehensive Solutions for Active Operations Businesses"
          subHeaderText="Everything You Need to Successfully Manage Wholesale Trading, Professional Services, Consulting, and More"
          desc=" At kimbocorp, we specialize in building and supporting active operations businesses, including wholesale trading, professional services, consulting, and any business that involves receiving revenues for selling a product or service. Our ready-made company plans encompass all necessary services, from bank accounts and payment gateways to accounting and statutory compliance, ensuring you have everything you need to run your active operations business seamlessly from Singapore."
          img={"assets/img/active-business-banner.png"}
          isDemoHeader={2}
        />
      </div>
      <div className="our-apporach-bg-top">
        <img alt="" src="assets/img/consequences-top-shade.png" />
      </div>
      <div className="our-approach governance">
        <div className="container">
          <div className="our-approach-inner">
            <div className="row text-center">
              <div className="col-md-12 our-approach-content">
                <h2>Our Approach</h2>
                <p>Our #1 priority is your business</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/ab1.svg" />
                  </div>
                  <h4>Comprehensive Company Formation</h4>
                  <div className="based-accounting-p">
                    <p>
                      We provide fully-formed, ready-made companies tailored to
                      your specific business needs. Each company is meticulously
                      prepared to meet all regulatory and operational
                      requirements.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/ab2.svg" />
                  </div>
                  <h4>Bank Accounts and Payment Gateways</h4>
                  <div className="based-accounting-p">
                    <p>
                      We assist in setting up robust bank accounts and payment
                      gateways, providing seamless financial operations for your
                      business activities.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/ab3.svg" />
                  </div>
                  <h4>Accounting and Bookkeeping</h4>
                  <div className="based-accounting-p">
                    <p>
                      Our comprehensive accounting and bookkeeping services
                      ensure accurate financial reporting and compliance with
                      relevant standards, covering everything from bookkeeping
                      to financial statements and tax filings.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/ab4.svg" />
                  </div>
                  <h4>Statutory and Regulatory Compliance</h4>
                  <div className="based-accounting-p">
                    <p>
                      Navigating the complexities of statutory and regulatory
                      compliance is simplified with our expert support. We
                      ensure your business meets all necessary legal
                      requirements, including ongoing compliance and governance.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/ab5.svg" />
                  </div>
                  <h4>Secretarial and Director Appointments</h4>
                  <div className="based-accounting-p">
                    <p>
                      We provide secretarial and director appointments to ensure
                      your company’s corporate governance is managed effectively
                      and in accordance with Singaporean regulations.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/ab6.svg" />
                  </div>
                  <h4>Suggested Customers and Business Opportunities</h4>
                  <div className="based-accounting-p">
                    <p>
                      Leveraging our extensive network, we offer suggested
                      customer profiles and business opportunities to help your
                      business grow and thrive.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="our-apporach-bg-top div-img-block">
        <img alt="" src="assets/img/consequences-bottom-shade-only.png" />
      </div>

      <div className="why-choose active-business">
        <div className="container">
          <div className="row align-items-center flex-row-reverse">
            <div className="col-md-6">
              <div className="benefit-sol-content-img">
                <img alt="" src="assets/img/active-business-why-choose.png" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="why-choose-us-block">
                <h2>Why choose kimbocorp?</h2>
                <p>
                  In the dynamic world of active operations businesses, having a
                  comprehensive and integrated solution is crucial. At
                  kimbocorp, we provide everything you need in one package,
                  eliminating the need for piece-meal services and ensuring your
                  business runs smoothly and efficiently.
                </p>
                <div className="banner-button">
                  <button type="button" className="secondary-button">
                    Know more
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="our-apporach-bg-top">
        <img alt="" src="assets/img/our-approach-top-bg-top.png" />
      </div>
      <div className="benefit-solutions accounting">
        <div className="container">
          <div className="benefit-solutions-inner">
            <div className="row align-items-center flex-row-reverse">
              <div className="col-md-6">
                <div className="benefit-sol-content">
                  <h2>Consequences of Not Taking Action</h2>
                  <p>Without our comprehensive solutions, you could face:</p>

                  <ul>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/abct-1.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>Losses Due to Volatile Local Currencies</h5>
                        <p>
                          Exposure to unstable currencies can lead to
                          significant financial losses.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/abct-2.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>Unstable Law-Making Environment</h5>
                        <p>
                          Operating in regions with unpredictable regulatory
                          changes can create legal and operational risks.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/abct-3.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>High Corporate and Sales Taxes</h5>
                        <p>
                          Operating in high-tax jurisdictions can reduce your
                          overall profitability.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/abct-4.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>Foreign Currency Bank Clearance Difficulties</h5>
                        <p>
                          Delays and complications in clearing foreign currency
                          transactions can disrupt your operations.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/abct-5.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>High Foreign Exchange Conversion Rates</h5>
                        <p>
                          Unfavourable exchange rates can erode your profits.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div id="desktop" className="col-md-6">
                <div className="benefit-sol-content-img">
                  <span className="">
                    <img
                      alt=""
                      src="assets/img/active-business-talking.png"
                      className=""
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="our-apporach-bg-top">
        <img alt="" src="assets/img/our-approach-top-bg-bottom.png" />
      </div>

      <div className="benefit-solutions invtment-business--benefit-soutions">
        <div className="container">
          <div className="benefit-solutions-inner">
            <div className="row text-center">
              <div className="col-md-12 our-approach-content">
                <h2>Comparing with Piece-Meal Providers</h2>
                <p>Our #1 priority is your business</p>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="benefit-sol-content">
                  <h2>kimbocorp’s integrated approach:</h2>
                  <ul>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/ac-app-icon-1.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>Seamless Integration</h5>
                        <p>
                          All services are provided under one roof, ensuring
                          seamless integration and efficient management.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/ac-app-icon-2.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>Comprehensive Support</h5>
                        <p>
                          From company formation to banking, accounting, and
                          compliance, we cover all aspects of your active
                          operations business.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/ac-app-icon-3.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>Expert Personnel</h5>
                        <p>
                          We provide experienced secretarial and director
                          appointments, ensuring robust governance and
                          compliance.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div id="desktop" className="col-md-6">
                <div className="benefit-sol-content-img">
                  <span className="">
                    <img
                      alt=""
                      src="assets/img/ac-app-image-1.png"
                      className=""
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="row align-items-center flex-row-reverse margin-top-consequences">
              <div className="col-md-6">
                <div className="benefit-sol-content">
                  <h2>Piece-Meal Providers:</h2>
                  <ul>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/ac-app-icon-4.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>Fragmented Services</h5>
                        <p>
                          Services are often fragmented, leading to coordination
                          challenges and inefficiencies.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/ac-app-icon-5.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>Inconsistent Quality</h5>
                        <p>
                          Varying quality of services from different providers
                          can result in compliance and operational risks.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/ac-app-icon-6.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>Operational Delays</h5>
                        <p>
                          Managing multiple providers can cause delays and
                          increase the time required to set up and run your
                          business.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div id="desktop" className="col-md-6">
                <div className="benefit-sol-content-img">
                  <span className="">
                    <img
                      alt=""
                      src="assets/img/ac-app-image-2.png"
                      className=""
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="our-apporach-bg-top">
        <img alt="" src="assets/img/success-story-top-shade.png" />
      </div>
      <div className="success-stories">
        <div className="success-stories-holder">
          <div className="success-stories-content">
            <h2>Success Stories</h2>
            <p>What our client say about our services</p>
          </div>
        </div>

        <div className="swiper-container success-stories-carousel">
          <SwiperContainer />
        </div>
      </div>

      <RequestADemo />
      <NewsLetter />
    </div>
  );
}
