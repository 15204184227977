import React from "react";
import HeaderBanner from "../components/HeaderBanner";
import RequestADemo from "../components/RequestADemo";
import NewsLetter from "../components/NewsLetter";
import SwiperContainer from "../components/SwiperContainer";

export default function StartBusinessFree() {
  return (
    <div>
      <div className="banner">
        <HeaderBanner
          headerText="Start Your Commercial Venture for Free with Kimbocorp"
          subHeaderText="Empower Your Business Journey with Comprehensive Tools and Expert Support"
          desc="At Kimbocorp, we enable you to start any commercial venture for free, providing you with all the necessary tools, insights, and support to build and grow your business. From governance and stakeholder management to financial efficiency and dedicated advisory, our platform ensures you have everything you need to succeed."
          img={"assets/img/start-business-free-banner-img.png"}
          isDemoHeader={0}
        />
      </div>

      <div className="our-apporach-bg-top">
        <img alt="" src="assets/img/consequences-bottom-shade.png" />
      </div>
      <div className="success-stories">
        <div className="success-stories-holder">
          <div className="success-stories-content">
            <h2>Success Stories</h2>
            <p>What our client say about our services</p>
          </div>
        </div>

        <div className="swiper-container success-stories-carousel">
          <SwiperContainer />
        </div>
      </div>
      <div className="our-approach transparent-bg">
        <div className="container">
          <div className="our-approach-inner">
            <div className="row text-center">
              <div className="col-md-12 our-approach-content">
                <h2>Our Approach</h2>
                <p>
                  How We Allow Users to Start Any Commercial Venture for Free
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="request-demo">
                  <div className="request-demo-holder linear-blue">
                    <div className="row align-items-center">
                      <div className="col-md-6">
                        <h3>Dedicated Advisory Support</h3>
                        <p>
                          Our team of experts offers dedicated advisory support
                          to help you navigate any challenges and make informed
                          decisions.
                        </p>
                      </div>
                      <div className="col-md-6">
                        <div className="req-demo-image">
                          <img
                            alt=""
                            id="desktop"
                            className="demo-preview"
                            src="assets/img/comprehensive-assessment.png"
                          />
                          <img
                            alt=""
                            id="mobile"
                            className="demo-preview"
                            src="assets/img/comprehensive-assessment-mob.png"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="request-demo">
                  <div className="request-demo-holder linear-green">
                    <div className="row align-items-center">
                      <div className="col-md-12">
                        <h3>Seamless Onboarding Process</h3>
                        <p>
                          Our platform guides you through the onboarding
                          process, ensuring a smooth start for your venture
                          without any initial costs.
                        </p>
                      </div>
                      <div className="col-md-12">
                        <div className="req-demo-image  mt-4">
                          <img
                            alt=""
                            id="desktop"
                            className="demo-preview"
                            src="assets/img/linear-green-.png"
                          />
                          <img
                            alt=""
                            id="mobile"
                            className="demo-preview"
                            src="assets/img/linear-green-mob.png"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="request-demo">
                  <div className="request-demo-holder linear-yellow">
                    <div className="row align-items-center">
                      <div className="col-md-12">
                        <h3>Comprehensive Document Management</h3>
                        <p>
                          We provide all the necessary documents for
                          incorporation, compliance, and ongoing management,
                          making it easy to stay organized and compliant.
                        </p>
                      </div>
                      <div className="col-md-12">
                        <div className="req-demo-image mt-4">
                          <img
                            alt=""
                            id="desktop"
                            className="demo-preview"
                            src="assets/img/linear-yellow-.png"
                          />
                          <img
                            alt=""
                            id="mobile"
                            className="demo-preview"
                            src="assets/img/linear-yellow-mob.png"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="our-apporach-bg-top">
        <img alt="" src="assets/img/sbf-approach-top-shade.png" />
      </div>

      <div className="benefit-solutions">
        <div className="container">
          <div className="benefit-solutions-inner">
            <div className="row align-items-center flex-row-reverse">
              <div className="col-md-6">
                <div className="benefit-sol-content">
                  <p className="highlight-text">
                    Banner: Staying Governed While Appointing Stakeholders
                  </p>

                  <h2>Stay Governed While Appointing Stakeholders</h2>
                  <p>
                    Ensure Robust Governance and Seamless Stakeholder Management
                  </p>

                  <ul>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/sbf-ib-1.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>All-In Company Packages</h5>
                        <p>
                          Our packages include everything you need to formalize
                          your venture, from share registry management to
                          compliance and resident director support, all in one
                          place.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/sbf-ib-2.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>Domicile in Singapore or Hong Kong</h5>
                        <p>
                          Easily formalize your venture to be domiciled in
                          Singapore or Hong Kong, leveraging our platform’s
                          comprehensive support and services.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/sbf-ib-3.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>One-Click Formalization</h5>
                        <p>
                          Easily formalize your venture in Singapore or Hong
                          Kong with just one click, making the process quick and
                          seamless.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div id="desktop" className="col-md-6">
                <div className="benefit-sol-content-img">
                  <span className="">
                    <img
                      alt=""
                      src="assets/img/start-free-talking.png"
                      className=""
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="our-apporach-bg-top background-white">
        <img alt="" src="assets/img/sbf-approach-bottom-shade.png" />
      </div>

      <div className="our-approach start-business-free-block">
        <div className="container">
          <div className="our-approach-inner">
            <div className="row text-center">
              <div className="col-md-12 our-approach-content">
                <h2>Additional Features</h2>
                <p>Our #1 priority is your business</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/sbf-appr-1.svg" />
                  </div>
                  <h4>Seamless Onboarding</h4>
                  <div className="based-accounting-p">
                    <p>
                      Our user-friendly platform ensures a smooth and
                      hassle-free onboarding process for you and your
                      stakeholders.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/sbf-appr-2.svg" />
                  </div>
                  <h4>Automated Prompts and Reports</h4>
                  <div className="based-accounting-p">
                    <p>
                      Stay on top of your business activities with automated
                      prompts and comprehensive reports, keeping you informed
                      and proactive.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/sbf-appr-3.svg" />
                  </div>
                  <h4>Ratification and Formalization</h4>
                  <div className="based-accounting-p">
                    <p>
                      Our platform supports the ratification and formalization
                      of agreements and documents, ensuring legal compliance and
                      formal recognition.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/sbf-appr-4.svg" />
                  </div>
                  <h4>Efficient Stakeholder Management</h4>
                  <div className="based-accounting-p">
                    <p>
                      Easily manage and appoint stakeholders with our integrated
                      tools, ensuring clear communication and smooth operations.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/sbf-appr-5.svg" />
                  </div>
                  <h4>Comprehensive Document Management</h4>
                  <div className="based-accounting-p">
                    <p>
                      Access all necessary documents, including incorporation
                      papers, compliance forms, and ongoing management
                      documentation, all in one place.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/sbf-appr-6.svg" />
                  </div>
                  <h4>Easy Appointments and Terminations</h4>
                  <div className="based-accounting-p">
                    <p>
                      Manage appointments and terminations effortlessly,
                      ensuring that your business operations are always aligned
                      with your goals.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="our-apporach-bg-top background-white">
        <img alt="" src="assets/img/stakeholder-top-shade.png" />
      </div>
      <div className="benefit-solutions newstakeholders">
        <div className="container">
          <div className="our-approach-inner">
            <div className="row text-center">
              <div className="col-md-12 our-approach-content">
                <p className="highlight-text">Seamless Stakeholder Introductions</p>
                <h2>Receive New Stakeholder Introductions with Ease</h2>
                <p>
                  Non-Aversive and Seamless Onboarding Experience for All
                  Stakeholders
                </p>
              </div>
            </div>

            <div className="benefit-solutions-inner">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <div className="benefit-sol-content">
                    <ul>
                      <li>
                        <div className="benefit-icons">
                          <img alt="" src="assets/img/sbf-stake-icon-1.svg" />
                        </div>
                        <div className="benefit-content">
                          <h5>Seamless Integration</h5>
                          <p>
                            All services are provided under one roof, ensuring
                            seamless integration and efficient management.
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="benefit-icons">
                          <img alt="" src="assets/img/sbf-stake-icon-2.svg" />
                        </div>
                        <div className="benefit-content">
                          <h5>Relevant personal</h5>
                          <p>
                            We provide suggested profiles and resident directors
                            who understand your specific industry.
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="benefit-icons">
                          <img alt="" src="assets/img/sbf-stake-icon-3.svg" />
                        </div>
                        <div className="benefit-content">
                          <h5>Comprehensive Support</h5>
                          <p>
                            From company formation to fund administration,
                            accounting, and compliance, we cover all aspects of
                            your investment holding company.
                          </p>
                        </div>
                      </li>
                    </ul>
                    <div className="banner-button">
                      <button type="button" className="secondary-button">
                        Book a demo <img alt="" src="assets/img/zap-fast.svg" />
                      </button>
                      <button type="button" className="primary-button">
                        Speak to sales
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 handle-stake-image">
                  <div className="">
                    <img alt="" src="assets/img/stakeholder-bg-text.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <RequestADemo />
      <NewsLetter />
    </div>
  );
}
