import React from "react";
import { Route, Routes } from "react-router-dom";
import { RoutesPaths } from "./constant";
import Home from "../pages/Home";
// import Message from "../pages/chat/Message";
import Accounting from "../pages/Accounting";
import ReadyMadeCompanies from "../pages/ReadyMadeCompanies";
import ActiveBusiness from "../pages/ActiveBusiness";
import AllAdvisory from "../pages/AllAdvisory";
import Enterprise from "../pages/Enterprise";
import Governance from "../pages/Governance";
import HolisticSolutions from "../pages/HolisticSolutions";
import HybridBusiness from "../pages/HybridBusiness";
import InvestmentBusiness from "../pages/InvestmentBusiness";
import SingaporeSolutions from "../pages/SingaporeSolutions";
import StartBusinessFree from "../pages/StartBusinessFree";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import AccountCloseForm from "../pages/AccountCloseForm";
import TermsOfService from "../pages/TermsOfService";
import TermsOfAdvisory from "../pages/TermsOfAdvisory";
import TermsAndConditions from "../pages/TermsAndConditions";
export default function RouteComponent() {
  return (
    <>
      <Routes basename={''}>
        <Route exact path={RoutesPaths.HOME} element={<Home />} />
        {/* <Route exact path={RoutesPaths.MESSAGESCREEN} element={<Message />} /> */}
        <Route exact path={RoutesPaths.ACCOUNTING} element={<Accounting />} />
        <Route exact path={RoutesPaths.READYMADECOMPANIES} element={<ReadyMadeCompanies />} />
        <Route exact path={RoutesPaths.ACTIVEBUSINESS} element={<ActiveBusiness />} />
        <Route exact path={RoutesPaths.ALLADVISORY} element={<AllAdvisory />} />
        <Route exact path={RoutesPaths.ENTERPRISE} element={<Enterprise />} />
        <Route exact path={RoutesPaths.GOVERNANCE} element={<Governance />} />
        <Route exact path={RoutesPaths.HOLISTICSOLUTIONS} element={<HolisticSolutions />} />
        <Route exact path={RoutesPaths.HYBRIDBUSINESS} element={<HybridBusiness />} />
        <Route exact path={RoutesPaths.INVESTMENTBUSINESS} element={<InvestmentBusiness />} />
        <Route exact path={RoutesPaths.SINGAPORESOLUTIONS} element={<SingaporeSolutions />} />
        <Route exact path={RoutesPaths.STARTBUSINESSFREE} element={<StartBusinessFree />} />
        <Route exact path={RoutesPaths.PRIVACYPOLICY} element={<PrivacyPolicy />} />
        <Route exact path={RoutesPaths.ACCOUNTCLOSEORM} element={<AccountCloseForm />} />
        <Route exact path={RoutesPaths.TERMOFSERVICE} element={<TermsOfService />} />
        <Route exact path={RoutesPaths.TERMSOFADVISORY} element={<TermsOfAdvisory />} />
        <Route exact path={RoutesPaths.TERMSANDCONDITIONS} element={<TermsAndConditions />} />
      </Routes>
    </>
  );
}
