import { Modal } from "react-bootstrap";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";

export default function AccountCloseForm() {
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const [showModal, setShowModal] = useState(false);
  const onSubmit = (data) => {
    if (data) {
      setShowModal(true);
    }
  };
  return (
    <div>
      <div className="our-apporach-bg-top">
        <img alt="" src="assets/img/our-approach-top-bg-top.png" />
      </div>
      <div className="our-approach deleteFormPage">
        <div className="container">
          <div className="our-approach-inner">
            <div className="row text-center">
              <div className="col-md-12 our-approach-content">
                <h4>Account Closing Form</h4>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="request-demo">
                  <div className="request-demo-holder linear-blue">
                    <div className="row align-items-center">
                      <div className="col-md-6"></div>
                      <div className="col-md-6">
                        <div className="deleteFormPageHolder">
                          <form
                            onSubmit={handleSubmit(onSubmit)}
                            className="form-group">
                            <div className="formInput">
                              <Controller
                                control={control}
                                render={({ field }) => (
                                  <input
                                    {...field}
                                    maxLength={50}
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter your name"
                                  />
                                )}
                                name="name"
                                rules={{
                                  required: {
                                    value: true,
                                    message: "Name is required",
                                  },
                                }}
                              />
                              {errors && errors.name && (
                                <span className="errorMessage">
                                  {errors?.name?.message}
                                </span>
                              )}
                            </div>
                            <div className="formInput">
                              <Controller
                                control={control}
                                render={({ field }) => (
                                  <input
                                    {...field}
                                    maxLength={50}
                                    autoComplete="nope"
                                    type="text"
                                    className="form-control"
                                    placeholder="Email"
                                  />
                                )}
                                name="email"
                                rules={{
                                  required: {
                                    value: true,
                                    message: "Email is required",
                                  },
                                  pattern: {
                                    value:
                                      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
                                    message: "Email is invalid",
                                  },
                                }}
                              />
                              {errors && errors.email && (
                                <span className="errorMessage">
                                  {errors?.email?.message}
                                </span>
                              )}
                            </div>
                            <div className="formInput">
                              <Controller
                                control={control}
                                render={({ field }) => (
                                  <textarea
                                    {...field}
                                    maxLength={50}
                                    autoComplete="nope"
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter note here"
                                  />
                                )}
                                name="note"
                                rules={{
                                  required: {
                                    value: true,
                                    message: "Note is required",
                                  },
                                }}
                              />
                              {errors && errors.note && (
                                <span className="errorMessage">
                                  {errors?.note?.message}
                                </span>
                              )}
                            </div>
                            <div className="submitbutton">
                              <button
                                type="submit"
                                className="secondary-button">
                                Submit
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="our-apporach-bg-top">
        <img alt="" src="assets/img/our-approach-top-bg-bottom.png" />
      </div>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        className="modal-custom">
        <Modal.Body>
          <div className="deleteModal">
            <h5>Confirm</h5>
            <p>
              Your account closure request has been sent successfully. Please
              wait for a response.
            </p>

            <div className="submitbutton">
              <button
                onClick={() => setShowModal(false)}
                type="submit"
                className="secondary-button">
                Got it
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
