import React from "react";
// import { RoutesPaths } from "../Router/constant";
// import { useNavigate } from "react-router-dom";

export default function HeaderBanner({
  img,
  headerText,
  subHeaderText,
  desc,
  isDemoHeader,
  isCenterBanner,
}) {
  // const navigate = useNavigate();
  return (
    <div className="container">
      <div className="banner-holder">
        <div
          className={`row align-items-center ${
            isCenterBanner ? "text-center" : ""
          }`}>
          {isCenterBanner && <div className="col-md-3"></div>}
          <div className="col-md-6">
            <div className="banner-content">
              <h6>{headerText}</h6>
              <h1>{subHeaderText}</h1>
              <div className="desc">
                <p>{desc}</p>
              </div>

              <div className="banner-button">
                {isDemoHeader === 0 ? (
                  <>
                    <button type="button" className="primary-button">
                      Login
                    </button>
                    <button
                      // onClick={() => navigate(RoutesPaths.MESSAGESCREEN)}
                      type="button"
                      className="secondary-button">
                      Book a demo <img alt="" src="assets/img/zap-fast.svg" />
                    </button>
                  </>
                ) : isDemoHeader === 1 ? (
                  <button type="button" className="secondary-button">
                    Know more <img alt="" src="assets/img/arrow-right.svg" />
                  </button>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          {isCenterBanner && (
            <>
              <div className="col-md-3"></div>
              <div className="col-md-2"></div>
            </>
          )}
          <div
            id="desktop"
            className={`col-md-${isCenterBanner ? "8" : "6"} text-right`}>
            <div className="d-flex justify-content-center banner-image">
              <img alt="" src={img} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
