import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import { Swiper, SwiperSlide } from "swiper/react";

export default function SwiperContainer() {
  const [activeIndex, setActiveIndex] = useState(0); // State to track active (centered) slide

  // Handle slide change event
  const handleSlideChange = (swiper) => {
    setActiveIndex(swiper.activeIndex); // Update the active index whenever the slide changes
  };

  // Dynamic class based on whether the slide is active (center)
  const getSlideClass = (index) => {
    return index === activeIndex ? "center-slide" : "";
  };

  return (
    <Swiper
      slidesPerView={isMobile ? 1 : 4}
      spaceBetween={30}
      className="mySwiper"
      centeredSlides={true} // Initially start without centering
      initialSlide={0} // Start from the first slide (index 0)
      centerInsufficientSlides={true}
      grabCursor={true}
      onSlideChange={handleSlideChange}
      freeMode={true}>
      {[1, 2, 3, 4, 5, 6].map((item, index) => (
        <SwiperSlide key={index} className={getSlideClass(index)}>
          <div className="swiper-slide story-holder">
            <h6 className="date">15 Apr 2023</h6>
            <div className="rating">
              <img alt="" src="assets/img/star-active.svg" />
              <img alt="" src="assets/img/star-active.svg" />
              <img alt="" src="assets/img/star-active.svg" />
              <img alt="" src="assets/img/star-deactive.svg" />
              <img alt="" src="assets/img/star-deactive.svg" />
            </div>
            <div className="description">
              <p>
                Thanks to kimbocorp, we have a robust compliance system in place
                that has saved us from potential fines and legal troubles. Their
                team is knowledgeable and always available for support.
              </p>
            </div>
            <h5 className="title">Charmaine Lee</h5>
            <h5 className="position">Family Office Manager</h5>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
