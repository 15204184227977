import React from "react";
import { RoutesPaths } from "../Router/constant";
import { useNavigate } from "react-router-dom";

export default function Footer() {
  const navigate = useNavigate();
  return (
    <footer>
      <div className="footer-menu">
        <div className="container">
          <div className="footer-menu-inner">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-5">
                <div className="foot-logo-group">
                  <div className="foot-logo">
                    <a href="/">
                      <img
                        src="assets/img/kimbocorp-w.svg"
                        alt="kimbocorp.svg"
                      />
                    </a>
                  </div>
                  <div className="foot-desc">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </p>
                  </div>
                  <div className="foot-social">
                    <ul>
                      <li>
                        <a href="/">
                          <img
                            alt="social-cell.svg"
                            src="assets/img/f-cell.svg"
                          />
                        </a>
                      </li>
                      <li>
                        <a href="/">
                          <img
                            alt="social-cell.svg"
                            src="assets/img/x-cell.svg"
                          />
                        </a>
                      </li>
                      <li>
                        <a href="/">
                          <img
                            alt="social-cell.svg"
                            src="assets/img/i-cell.svg"
                          />
                        </a>
                      </li>
                      <li>
                        <a href="/">
                          <img
                            alt="social-cell.svg"
                            src="assets/img/li-cell.svg"
                          />
                        </a>
                      </li>
                      <li>
                        <a href="/">
                          <img
                            alt="social-cell.svg"
                            src="assets/img/y-cell.svg"
                          />
                        </a>
                      </li>
                      <li>
                        <a href="/">
                          <img
                            alt="social-cell.svg"
                            src="assets/img/p-cell.svg"
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-7">
                <div className="row">
                  <div className="col-6 col-md-4 col-lg-4">
                    <div className="foot-menu mobile-foot-menu-margin-top">
                      <h6>Realise your potential</h6>
                      <ul>
                        <li>
                          <a href="/">Sign contracts</a>
                        </li>
                        <li>
                          <a href="/">Receive suggested profiles</a>
                        </li>
                        <li>
                          <a href="/">Start a fund or financial advisory</a>
                        </li>
                        <li>
                          <a href="/">Appoint shareholders</a>
                        </li>
                        <li>
                          <a href="/">Appoint officers and promoters</a>
                        </li>
                        <li>
                          <a href="/">App store</a>
                        </li>
                      </ul>
                    </div>
                    <div className="foot-menu">
                      <h6>How it works</h6>
                      <ul>
                        <li>
                          <span
                            onClick={() =>
                              navigate(RoutesPaths.STARTBUSINESSFREE)
                            }>
                            Start businesses free
                          </span>
                        </li>
                        <li>
                          <a href="/">Formalise businesses anytime</a>
                        </li>
                        <li>
                          <a href="/">All industry sectors</a>
                        </li>
                        <li>
                          <span
                            onClick={() => navigate(RoutesPaths.ALLADVISORY)}>
                            All advisory included
                          </span>
                        </li>
                        <li>
                          <span
                            onClick={() =>
                              navigate(RoutesPaths.HOLISTICSOLUTIONS)
                            }>
                            Holistic solutions
                          </span>
                        </li>
                        <li>
                          <a href="/">Create global businesses</a>
                        </li>
                        <li>
                          <a href="/">Places of management</a>
                        </li>
                        <li>
                          <a href="/">App store</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-6 col-md-4 col-lg-4">
                    <div className="foot-menu mobile-foot-menu-margin-top">
                      <h6>Solutions</h6>
                      <ul>
                        <li>
                          <span
                            onClick={() =>
                              navigate(RoutesPaths.READYMADECOMPANIES)
                            }>
                            Ready-made companies
                          </span>
                        </li>
                        <li>
                          <a href="/">Compliance</a>
                        </li>
                        <li>
                          <span
                            onClick={() => navigate(RoutesPaths.GOVERNANCE)}>
                            Governance
                          </span>
                        </li>
                        <li>
                          <span
                            onClick={() => navigate(RoutesPaths.ACCOUNTING)}>
                            Accounting
                          </span>
                        </li>
                        <li>
                          <span
                            onClick={() =>
                              navigate(RoutesPaths.INVESTMENTBUSINESS)
                            }>
                            For investment businesses
                          </span>
                        </li>
                        <li>
                          <span
                            onClick={() =>
                              navigate(RoutesPaths.ACTIVEBUSINESS)
                            }>
                            For active businesses
                          </span>
                        </li>
                        <li>
                          <span
                            onClick={() =>
                              navigate(RoutesPaths.HYBRIDBUSINESS)
                            }>
                            For hybrid businesses
                          </span>
                        </li>
                        <li>
                          <span
                            onClick={() => navigate(RoutesPaths.ENTERPRISE)}>
                            Enterprise
                          </span>
                        </li>
                        <li>
                          <span
                            onClick={() =>
                              navigate(RoutesPaths.SINGAPORESOLUTIONS)
                            }>
                            kimbocorp solutions - Singapore
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="foot-menu">
                      <h6>Build holding companies</h6>
                      <ul>
                        <li>
                          <a href="/">kimbocorp holdco</a>
                        </li>
                        <li>
                          <a href="/">kimbocorp stakeholder management</a>
                        </li>
                        <li>
                          <a href="/">Leverage DTAs</a>
                        </li>
                        <li>
                          <a href="/">Tax-residency statues</a>
                        </li>
                        <li>
                          <a href="/">Corporate governance</a>
                        </li>
                        <li>
                          <a href="/">Compliance and Accounting</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-4">
                    <div className="foot-menu mobile-resp-menu">
                      <h6>Resource</h6>
                      <ul>
                        <li>
                          <a href="/">About</a>
                        </li>
                        <li>
                          <a href="/">Careers</a>
                        </li>
                        <li>
                          <a href="/">Case studies</a>
                        </li>
                        <li>
                          <a href="/">Customer services</a>
                        </li>
                        <li>
                          <a href="/">Contact</a>
                        </li>
                        <li>
                          <a href="/">Advisors</a>
                        </li>
                        <li>
                          <a href="/">Partner program</a>
                        </li>
                        <li>
                          <a href="/">Service status</a>
                        </li>
                        <li>
                          <a href="/">Support hub</a>
                        </li>
                        <li>
                          <a href="/">Glossary</a>
                        </li>
                        <li>
                          <a href="/">Community</a>
                        </li>
                        <li>
                          <a href="/">Disclaimer</a>
                        </li>
                        <li>
                          <span
                            onClick={() => navigate(RoutesPaths.PRIVACYPOLICY)}>
                            Privacy policy
                          </span>
                        </li>
                        <li>
                          <span
                            onClick={() => navigate(RoutesPaths.TERMSANDCONDITIONS)}>
                            Terms and conditions
                          </span>
                        </li>
                        <li>
                          <a href="/">IPPC</a>
                        </li>
                        <li>
                          <a href="/">Cookie policy</a>
                        </li>
                        <li>
                          <a href="/">Cookies settings</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-language">
        <div className="container">
          <div className="footer-menu-inner">
            <div className="row">
              <div className="col-md-6">
                <div className="language-selector-holder">
                  <ul className="language-selector">
                    <li className="active">English</li>
                    <li>Français</li>
                    <li>한국어 - 대한민국</li>
                    <li>Español</li>
                    <li>Portuguese</li>
                    <li>Nederlands</li>
                    <li>日本語</li>
                    <li>简体中文</li>
                    <li>繁體中文 (台灣)</li>
                    <li>Deutsch</li>
                  </ul>
                  <p className="handle-language-change">
                    <img alt="globeIcon.svg" src="assets/img/globeIcon.svg" />{" "}
                    <span>English</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        <div className="container">
          <div className="footer-menu-inner">
            <div className="row flex-row-reverse">
              <div className="col-md-6">
                <ul className="copyright-menu">
                  <li>
                    <span onClick={() => navigate(RoutesPaths.PRIVACYPOLICY)}>
                      Privacy
                    </span>
                  </li>
                  <li>
                    <span onClick={() => navigate(RoutesPaths.TERMSANDCONDITIONS)}>
                      Terms and conditions
                    </span>
                  </li>
                  <li>
                    <a href="/">FAQ</a>
                  </li>
                </ul>
              </div>
              <div className="col-md-6">
                <p>&copy; Copyright 2024. All Rights Reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
