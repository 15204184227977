import React from "react";
import SwiperContainer from "../components/SwiperContainer";
import NewsLetter from "../components/NewsLetter";
import RequestADemo from "../components/RequestADemo";
import HeaderBanner from "../components/HeaderBanner";

export default function Accounting() {
  return (
    <div>
      <div className="banner">
        <HeaderBanner
          headerText="Achieve financial transparency with our accrual-based accounting capability"
          subHeaderText="Ensure compliance with SFRS and IFRS standards for reliable financial reporting"
          desc="At kimbocorp, we understand the importance of accurate and transparent financial reporting. Our accrual-based accounting services, compliant with Singapore Financial Reporting Standards (SFRS) and based on International Financial Reporting Standards (IFRS), are designed to provide you with a clear and precise picture of your financial health."
          img={"assets/img/accounting-banner-img.png"}
          isDemoHeader={1}
        />
      </div>
      <div className="our-apporach-bg-top">
        <img alt="" src="assets/img/consequences-top-shade.png" />
      </div>
      <div className="our-approach governance">
        <div className="container">
          <div className="our-approach-inner">
            <div className="row text-center">
              <div className="col-md-12 our-approach-content">
                <h2>Our Approach</h2>
                <p>Our #1 priority is your business</p>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="accounting-approach-content-holder">
                  <ul className="accounting-approach-content-ul">
                    <li>
                      <div className="accounting-approach-icons">
                        <img alt="" src="assets/img/aa1.svg" />
                      </div>
                      <div className="accounting-approach-content">
                        <h5>Emphasis on accrual-based accounting</h5>
                        <p>
                          We focus on accrual-based accounting because it
                          provides a more accurate representation of your
                          financial position by recording revenues when they are
                          earned and expenses when they are incurred, not when
                          cash changes hands. This method encourages users to
                          leverage the tools within the kimbocorp platform, such
                          as contracts and contract administration support. By
                          doing so, you can clearly understand the long-term
                          performance and health of your ventures.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="accounting-approach-icons">
                        <img alt="" src="assets/img/aa2.svg" />
                      </div>
                      <div className="accounting-approach-content">
                        <h5>Integration with kimbocorp platform tools</h5>
                        <p>
                          Our platform is designed to facilitate the
                          accrual-based approach by drawing stakeholders
                          together to work on contracts before services are
                          provided or products are delivered. This bottom-up
                          approach ensures that all financial activities are
                          documented and transparent, leading to more accurate
                          and reliable financial reporting.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="accounting-approach-icons">
                        <img alt="" src="assets/img/aa3.svg" />
                      </div>
                      <div className="accounting-approach-content">
                        <h5>
                          Customised implementation and continuous support
                        </h5>
                        <p>
                          We transition your accounting system to an
                          accrual-based method and provide ongoing support and
                          monitoring to ensure your accounting practices remain
                          compliant with the latest SFRS and IFRS updates.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <div className="accounting-approach-image">
                  <img alt="" src="assets/img/accounting-approach-img.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="our-apporach-bg-top div-img-block">
        <img alt="" src="assets/img/consequences-bottom-shade-only.png" />
      </div>

      <div className="based-accounting">
        <div className="container">
          <div className="based-accounting-inner">
            <div className="row text-center">
              <div className="col-md-12 our-approach-content">
                <h2>Benefits of accrual-based accounting</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/boabc-icon1.svg" />
                  </div>
                  <h4>Improved financial accuracy</h4>
                  <div className="based-accounting-p">
                    <p>
                      Accrual-based accounting recognizes revenues and expenses
                      when they are earned or incurred, providing a more
                      accurate picture of your financial position.
                    </p>
                  </div>
                  <div className="based-accounting-redirect">
                    <a href="/">
                      Redirect to the page{" "}
                      <img alt="" src="assets/img/boabc-icon-redirect.svg" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/boabc-icon2.svg" />
                  </div>
                  <h4>Enhanced financial management</h4>
                  <div className="based-accounting-p">
                    <p>
                      This method helps you better manage your finances by
                      aligning revenues with the expenses incurred to generate
                      them, giving you a clearer understanding of profitability.
                    </p>
                  </div>
                  <div className="based-accounting-redirect">
                    <a href="/">
                      Redirect to the page{" "}
                      <img alt="" src="assets/img/boabc-icon-redirect.svg" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/boabc-icon3.svg" />
                  </div>
                  <h4>Compliance with standards</h4>
                  <div className="based-accounting-p">
                    <p>
                      Our services ensure your financial reporting complies with
                      SFRS and IFRS, which is essential for legal and regulatory
                      adherence, particularly for businesses operating
                      internationally.
                    </p>
                  </div>
                  <div className="based-accounting-redirect">
                    <a href="/">
                      Redirect to the page{" "}
                      <img alt="" src="assets/img/boabc-icon-redirect.svg" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/boabc-icon4.svg" />
                  </div>
                  <h4>Better decision-making</h4>
                  <div className="based-accounting-p">
                    <p>
                      Accrual-based financial statements provide detailed
                      insights into your financial performance, aiding in more
                      informed and strategic decision-making.
                    </p>
                  </div>
                  <div className="based-accounting-redirect">
                    <a href="/">
                      Redirect to the page{" "}
                      <img alt="" src="assets/img/boabc-icon-redirect.svg" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/boabc-icon5.svg" />
                  </div>
                  <h4>Investor confidence</h4>
                  <div className="based-accounting-p">
                    <p>
                      Accurate and transparent financial reporting enhances
                      investor confidence, as it reflects the true financial
                      health of your business.
                    </p>
                  </div>
                  <div className="based-accounting-redirect">
                    <a href="/">
                      Redirect to the page{" "}
                      <img alt="" src="assets/img/boabc-icon-redirect.svg" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="our-apporach-bg-top">
        <img alt="" src="assets/img/our-approach-top-bg-top.png" />
      </div>
      <div className="benefit-solutions accounting">
        <div className="container">
          <div className="benefit-solutions-inner">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="benefit-sol-content">
                  <h2>Consequences of Not Using Accrual-Based Accounting</h2>
                  <p>Without accrual-based accounting, you could face:</p>

                  <ul>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/ac1.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>Inaccurate financial reporting</h5>
                        <p>
                          Cash-based accounting can provide an incomplete
                          picture of your financial health by only recording
                          transactions when cash changes hands.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/ac2.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>Poor financial management</h5>
                        <p>
                          Misalignment of revenues and expenses can lead to poor
                          financial decision-making and management.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/ac3.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>Non-compliance risks</h5>
                        <p>
                          Failure to comply with SFRS and IFRS standards can
                          result in legal and regulatory issues.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/ac4.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>Reduced investor confidence</h5>
                        <p>
                          Inaccurate and non-transparent financial reporting can
                          erode investor trust and confidence.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div id="desktop" className="col-md-6">
                <div className="benefit-sol-content-img">
                  <span className="">
                    <img alt="" src="assets/img/ac1.png" className="" />
                    {/* <!-- <img alt='' src="assets/img/fons-heijnsbroek-91Wj8j053cY-unsplash.jpg" /> --> */}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="our-apporach-bg-top">
        <img alt="" src="assets/img/our-approach-top-bg-bottom.png" />
      </div>

      <div className="our-feature">
        <div className="container">
          <div className="our-feature-inner">
            <div className="row text-center">
              <div className="col-md-3"></div>
              <div className="col-md-6 our-feature-content">
                <div className="description mb-3">
                  <p>Our Features</p>
                </div>
                <h2>
                  Comparing cash-based accounting with accrual-based accounting
                </h2>
                <div className="description">
                  <p>
                    At kimbocorp, we differentiate ourselves from other
                    providers by using a bottom-up approach to accounting:
                  </p>
                </div>
              </div>
              <div className="col-md-3"></div>
            </div>
            <div className="row align-items-center feature-bottom">
              <div className="col-12 col-md-6">
                <div className="feature-content-like">
                  <div className="feature-icon icon1">
                    <img alt="" src="assets/img/feature-icon-1.svg" />
                  </div>
                  <h5>kimbocorp's bottom-up approach</h5>
                  <div className="feature-description">
                    <p>
                      As we say, “Rome is not built in a day but we are with you
                      all the way.” Our platform focuses on venture building by
                      bringing stakeholders together to work on contracts and
                      agreements before services or products are delivered. This
                      method ensures that all financial activities are
                      well-documented, leading to more accurate and reliable
                      financial reporting.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="feature-img">
                  <img alt="" src="assets/img/feature-1.jpg" />
                </div>
              </div>
            </div>
            <div className="row flex-row-reverse align-items-center">
              <div className="col-12 col-md-6">
                <div className="feature-content-like">
                  <div className="feature-icon icon2">
                    <img alt="" src="assets/img/feature-icon-2.svg" />
                  </div>
                  <h5>Other provider's approach</h5>
                  <div className="feature-description">
                    <p>
                      Many providers integrate with accounting software to
                      record transactions after they occur. This top-down
                      approach often records cash transactions first and then
                      encourages users to generate invoices, which can lead to
                      incomplete financial reporting.
                    </p>
                    <p>
                      Our bottom-up approach aligns with our focus on venture
                      building, ensuring that financial activities are driven by
                      the actual business processes and collaborations, rather
                      than merely recording transactions. This method provides a
                      more accurate and comprehensive view of your financial
                      health.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="feature-img">
                  <img alt="" src="assets/img/feature-2.jpg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="our-apporach-bg-top">
        <img alt="" src="assets/img/success-story-top-shade.png" />
      </div>
      <div className="success-stories">
        <div className="success-stories-holder">
          <div className="success-stories-content">
            <h2>Success Stories</h2>
            <p>What our client say about our services</p>
          </div>
        </div>

        <div className="swiper-container success-stories-carousel">
          <SwiperContainer />
        </div>
      </div>

      <RequestADemo />
      <NewsLetter />
    </div>
  );
}
