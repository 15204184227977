import React from "react";
import SwiperContainer from "../components/SwiperContainer";
import NewsLetter from "../components/NewsLetter";
import RequestADemo from "../components/RequestADemo";

export default function AllAdvisory() {
  return (
    <div>
      <div className="banner">
        <div className="container">
          <div className="banner-holder">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="banner-content">
                  <h6>
                    Receive 24/7 Online and Offline Advisory with kimbocorp
                  </h6>
                  <h1>
                    Expert Guidance Anytime, Anywhere for Your Board of
                    Directors
                  </h1>
                  <div className="desc">
                    <p>
                      At kimbocorp, we provide unlimited 24/7 advisory services
                      to your board of directors, ensuring that you have access
                      to expert guidance whenever you need it. Whether online or
                      offline, our platform connects you with resident directors
                      specializing in your industry to offer practical advice
                      and strategic insights that help your business thrive.
                    </p>
                  </div>

                  <div className="banner-button">
                    <button type="button" className="secondary-button">
                      Book a demo <img alt="" src="assets/img/zap-fast.svg" />
                    </button>
                    <button type="button" className="primary-button">
                      Login
                    </button>
                  </div>
                </div>
              </div>
              <div id="desktop" className="col-md-6 text-right">
                <div className="d-flex justify-content-center banner-image">
                  <img
                    alt=""
                    src="assets/img/start-business-free-banner-img.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="our-apporach-bg-top">
        <img alt="" src="assets/img/our-approach-top-bg-top.png" />
      </div>
      <div className="our-approach">
        <div className="container">
          <div className="our-approach-inner">
            <div className="row text-center">
              <div className="col-md-12 our-approach-content">
                <h2>Simple Testimonials</h2>
                <p>What our client say about our services</p>
              </div>
            </div>
            <div className="swiper-container success-stories-carousel">
              <SwiperContainer />
            </div>
          </div>
        </div>
      </div>

      <div className="our-apporach-bg-top">
        <img alt="" src="assets/img/our-approach-top-bg-bottom.png" />
      </div>
      <div className="our-approach transparent-bg">
        <div className="container">
          <div className="our-approach-inner">
            <div className="row text-center">
              <div className="col-md-12 our-approach-content">
                <h2>Our Approach</h2>
                <p>
                  How We Allow Users to Start Any Commercial Venture for Free
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="request-demo">
                  <div className="request-demo-holder linear-blue">
                    <div className="row align-items-center">
                      <div className="col-md-6">
                        <h3>Dedicated Advisory Support</h3>
                        <p>
                          Our team of experts offers dedicated advisory support
                          to help you navigate any challenges and make informed
                          decisions.
                        </p>
                      </div>
                      <div className="col-md-6">
                        <div className="req-demo-image">
                          <img
                            alt=""
                            id="desktop"
                            className="demo-preview"
                            src="assets/img/comprehensive-assessment.png"
                          />
                          <img
                            alt=""
                            id="mobile"
                            className="demo-preview"
                            src="assets/img/comprehensive-assessment-mob.png"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="request-demo">
                  <div className="request-demo-holder linear-green">
                    <div className="row align-items-center">
                      <div className="col-md-12">
                        <h3>Seamless Onboarding Process</h3>
                        <p>
                          Our platform guides you through the onboarding
                          process, ensuring a smooth start for your venture
                          without any initial costs.
                        </p>
                      </div>
                      <div className="col-md-12">
                        <div className="req-demo-image  mt-4">
                          <img
                            alt=""
                            id="desktop"
                            className="demo-preview"
                            src="assets/img/linear-green-.png"
                          />
                          <img
                            alt=""
                            id="mobile"
                            className="demo-preview"
                            src="assets/img/linear-green-mob.png"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="request-demo">
                  <div className="request-demo-holder linear-yellow">
                    <div className="row align-items-center">
                      <div className="col-md-12">
                        <h3>Comprehensive Document Management</h3>
                        <p>
                          We provide all the necessary documents for
                          incorporation, compliance, and ongoing management,
                          making it easy to stay organized and compliant.
                        </p>
                      </div>
                      <div className="col-md-12">
                        <div className="req-demo-image mt-4">
                          <img
                            alt=""
                            id="desktop"
                            className="demo-preview"
                            src="assets/img/linear-yellow-.png"
                          />
                          <img
                            alt=""
                            id="mobile"
                            className="demo-preview"
                            src="assets/img/linear-yellow-mob.png"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="our-apporach-bg-top">
        <img alt="" src="assets/img/uaaa-texture-top.png" />
      </div>

      <div className="benefit-solutions uaaa-texture-bg">
        <div className="container">
          <div className="benefit-solutions-inner">
            <div className="row text-center">
              <div className="col-md-12 our-approach-content">
                <p className="highlight-text">
                  Unlimited Advisory Anytime, Anywhere
                </p>
                <h2>Unlimited Advisory Anytime, Anywhere</h2>
                <p>
                  Expert Guidance for Your Board of Directors from Industry
                  Specialists
                </p>
              </div>
            </div>
            <div className="row text-center">
              <div className="col-md-4 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/uaaa-icon-1.svg" />
                  </div>
                  <h4>Practical Advice for Any Venture</h4>
                  <div className="based-accounting-p">
                    <p>
                      Our platform allows you to receive practical advice
                      tailored to your specific venture, no matter the industry.
                      From strategic management to financial oversight, our
                      resident directors provide insights that drive success.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/uaaa-icon-2.svg" />
                  </div>
                  <h4>24/7 Availability</h4>
                  <div className="based-accounting-p">
                    <p>
                      Whether online or offline, our advisory services are
                      available 24/7, ensuring that your board of directors has
                      continuous access to the guidance and support needed to
                      make informed decisions.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/uaaa-icon-3.svg" />
                  </div>
                  <h4>Industry-Specific Resident Directors</h4>
                  <div className="based-accounting-p">
                    <p>
                      We provide access to resident directors who specialize in
                      various industry sectors, ensuring that your board
                      receives advice from experts who truly understand your
                      business.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="our-apporach-bg-top background-white">
        <img alt="" src="assets/img/uaaa-texture-bottom.png" />
      </div>

      <div className="our-apporach-bg-top">
        <img
          alt=""
          src="assets/img/additional-bottom-shade-white-top-only.png"
        />
      </div>
      <div className="our-approach">
        <div className="container">
          <div className="our-approach-inner">
            <div className="row text-center">
              <div className="col-md-12 our-approach-content">
                <h2>Comprehensive Resident Director Capabilities</h2>
                <p>
                  All businesses will be provided with resident director
                  capabilities, ensuring that your company qualifies as a
                  permanent place of management. Our resident directors are
                  available to advise your board of directors on a wide range of
                  topics, including
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/crdc-icon-1.svg" />
                  </div>
                  <h4>Compliance</h4>
                  <div className="based-accounting-p">
                    <p>
                      Ensure that your business remains compliant with all
                      relevant regulations and standards.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/crdc-icon-2.svg" />
                  </div>
                  <h4>Licensing</h4>
                  <div className="based-accounting-p">
                    <p>
                      Navigate the complexities of licensing with guidance from
                      our experienced resident directors.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/crdc-icon-3.svg" />
                  </div>
                  <h4>Governance</h4>
                  <div className="based-accounting-p">
                    <p>
                      Implement robust governance practices to maintain
                      transparency and accountability.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/crdc-icon-4.svg" />
                  </div>
                  <h4>Strategic Management</h4>
                  <div className="based-accounting-p">
                    <p>
                      Receive expert advice on setting and achieving your
                      business’s long-term goals.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/crdc-icon-5.svg" />
                  </div>
                  <h4>Go-to-Market Strategies</h4>
                  <div className="based-accounting-p">
                    <p>
                      Get insights on how to effectively bring your products or
                      services to market.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/crdc-icon-6.svg" />
                  </div>
                  <h4>Stakeholder Appointments</h4>
                  <div className="based-accounting-p">
                    <p>
                      Receive advice on appointing the right stakeholders to
                      support your business’s growth and success.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/crdc-icon-6.svg" />
                  </div>
                  <h4>Financial Management</h4>
                  <div className="based-accounting-p">
                    <p>
                      Benefit from financial management advice that helps you
                      optimize resources and achieve financial efficiency.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="our-apporach-bg-top">
        <img alt="" src="assets/img/our-approach-top-bg-bottom.png" />
      </div>

      <RequestADemo />
      <NewsLetter />
    </div>
  );
}
