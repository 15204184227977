import React from "react";
import SwiperContainer from "../components/SwiperContainer";
import NewsLetter from "../components/NewsLetter";
import RequestADemo from "../components/RequestADemo";
import HeaderBanner from "../components/HeaderBanner";

export default function Home() {
  return (
    <div>
      <div className="banner">
        <HeaderBanner
          headerText="Ensure peace of mind with our embedded compliance solutions"
          subHeaderText="Compliance is embedded in all ventures you create so that you can focus on building your venture"
          desc="At kimbocorp, we know that navigating the complex world of regulatory compliance can be a significant burden for businesses. Our hassle-free compliance solutions are designed to take the stress out of staying compliant, allowing you to focus on what you do best – running and growing your business."
          img={"assets/img/banner-img.png"}
          isDemoHeader={0}
        />
      </div>
      <div className="our-apporach-bg-top">
        <img alt="" src="assets/img/our-approach-top-bg-top.png" />
      </div>
      <div className="our-approach">
        <div className="container">
          <div className="our-approach-inner">
            <div className="row text-center">
              <div className="col-md-12 our-approach-content">
                <h2>Our Approach</h2>
                <p>Our #1 priority is your business</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="request-demo">
                  <div className="request-demo-holder linear-blue">
                    <div className="row align-items-center">
                      <div className="col-md-6">
                        <h3>Comprehensive compliance assessment</h3>
                        <p>
                          We start with a thorough analysis of your current
                          compliance status, identifying any gaps and areas of
                          risk.
                        </p>
                      </div>
                      <div className="col-md-6">
                        <div className="req-demo-image">
                          <img
                            alt=""
                            id="desktop"
                            className="demo-preview"
                            src="assets/img/comprehensive-assessment.png"
                          />
                          <img
                            alt=""
                            id="mobile"
                            className="demo-preview"
                            src="assets/img/comprehensive-assessment-mob.png"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="request-demo">
                  <div className="request-demo-holder linear-green">
                    <div className="row align-items-center">
                      <div className="col-md-12">
                        <h3>Embedded compliance strategy</h3>
                        <p>
                          Based on the assessment, we embed a compliance plan
                          and team relevant for your business activity and
                          business objectives.
                        </p>
                      </div>
                      <div className="col-md-12">
                        <div className="req-demo-image  mt-4">
                          <img
                            alt=""
                            id="desktop"
                            className="demo-preview"
                            src="assets/img/linear-green-.png"
                          />
                          <img
                            alt=""
                            id="mobile"
                            className="demo-preview"
                            src="assets/img/linear-green-mob.png"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="request-demo">
                  <div className="request-demo-holder linear-yellow">
                    <div className="row align-items-center">
                      <div className="col-md-12">
                        <h3>Implementation and ongoing support</h3>
                        <p>
                          We provide continuous monitoring and support to ensure
                          you remain compliant as regulations evolve.
                        </p>
                      </div>
                      <div className="col-md-12">
                        <div className="req-demo-image mt-4">
                          <img
                            alt=""
                            id="desktop"
                            className="demo-preview"
                            src="assets/img/linear-yellow-.png"
                          />
                          <img
                            alt=""
                            id="mobile"
                            className="demo-preview"
                            src="assets/img/linear-yellow-mob.png"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="our-apporach-bg-top">
        <img alt="" src="assets/img/our-approach-top-bg-bottom.png" />
      </div>

      <div className="why-choose">
        <div className="container">
          <div className="why-choose-inner">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="benefit-sol-content-img">
                  <img alt="" src="assets/img/why-choose.png" />

                  {/* <!-- <svg className="hand-draw-line" xmlns="http://www.w3.org/2000/svg" width="451" height="170" viewBox="0 0 451 170" fill="none">
                                        <g filter="url(#filter0_b_910_969)">
                                            <path d="M9 100.615C10.8026 95.3063 14.3575 91.3376 18.3161 86.9894C35.5513 68.0579 56.3107 51.631 79.6908 38.8064C128.776 11.8816 187.664 -1.8012 243.403 16.8883C275.266 27.5721 310.225 51.308 315.071 83.3207C317.823 101.5 307.983 117.278 293.961 129.993C278.936 143.619 259.499 154.491 238.553 159.248C222.355 162.927 194.168 163.574 192.391 143.341C191.406 132.119 197.785 121.552 205.301 113.007C220.96 95.2068 243.687 84.4585 267.793 78.1726C309.695 67.246 354.435 71.5034 390.715 93.5863C404.501 101.976 432.34 126.855 442 142.37" stroke="white" stroke-opacity="0.1" stroke-width="16.2849" stroke-linecap="round" />
                                        </g>
                                        <defs>
                                            <filter id="filter0_b_910_969" x="-11.3582" y="-12.0957" width="473.715" height="193.716" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                <feGaussianBlur in="BackgroundImageFix" stdDeviation="6.10682" />
                                                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_910_969" />
                                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_910_969" result="shape" />
                                            </filter>
                                        </defs>
                                    </svg> --> */}

                  {/* <!-- <img alt='' className="hand-draw-line" src="assets/img/hand-draw-line.png" /> --> */}
                </div>
              </div>
              <div className="col-md-6">
                <div className="why-choose-us-block">
                  <h2>Why choose kimbocorp?</h2>
                  <p>
                    Compliance is embedded in all ventures you create, and hence
                    you totally avoid navigating compliance independently, which
                    can lead to significant challenges and risks. With
                    kimbocorp, you benefit from our expertise and resources,
                    ensuring your business stays compliant without any hassle
                    and stress.
                  </p>
                  <div className="banner-button">
                    <button type="button" className="secondary-button">
                      Know more
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="our-apporach-bg-top">
        <img alt="" src="assets/img/consequences-top-shade.png" />
      </div>
      <div className="benefit-solutions">
        <div className="container">
          <div className="benefit-solutions-inner">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="benefit-sol-content">
                  <h2>Consequences of not taking action</h2>
                  <p>Without our ready-made solutions, you could face:</p>

                  <ul>
                    <li>
                      <div className="benefit-icons">
                        <img
                          alt=""
                          src="assets/img/regular-fines-panalties.svg"
                        />
                      </div>
                      <div className="benefit-content">
                        <h5>Regulatory Fines and Penalties</h5>
                        <p>
                          Non-compliance can result in costly fines and legal
                          penalties.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="benefit-icons">
                        <img
                          alt=""
                          src="assets/img/operational-disruptions.svg"
                        />
                      </div>
                      <div className="benefit-content">
                        <h5>Operational Disruptions</h5>
                        <p>
                          Regulatory issues can cause significant disruptions to
                          your business operations.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/repetitional-damage.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>Repetitional Damage</h5>
                        <p>
                          Failure to comply with regulations can harm your
                          business’s reputation and customer trust.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/increased-stress.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>Increased Stress</h5>
                        <p>
                          Managing compliance independently can be stressful and
                          time-consuming, diverting your focus from core
                          business activities.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div id="desktop" className="col-md-6">
                <div className="benefit-sol-content-img">
                  <span className="styled-image">
                    <img
                      alt=""
                      src="assets/img/consequence.png"
                      className="styled-image"
                    />
                    {/* <!-- <img alt='' src="assets/img/fons-heijnsbroek-91Wj8j053cY-unsplash.jpg" /> --> */}
                  </span>
                </div>
              </div>
            </div>
            <div className="row align-items-center flex-row-reverse margin-top-consequences">
              <div className="col-md-6">
                <div className="benefit-sol-content">
                  <h2>Benefits of our solutions</h2>
                  <p>By choosing kimbocorp’s ready-made companies, you will:</p>

                  <ul>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/avoid-penalties.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>Avoid Penalties</h5>
                        <p>
                          Ensure your business meets all regulatory
                          requirements, avoiding fines and legal issues.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="benefit-icons">
                        <img
                          alt=""
                          src="assets/img/maintain-smooth-operations.svg"
                        />
                      </div>
                      <div className="benefit-content">
                        <h5>Maintain Smooth Operations</h5>
                        <p>
                          Prevent compliance issues from disrupting your
                          business.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="benefit-icons">
                        <img
                          alt=""
                          src="assets/img/protect-your-reputation.svg"
                        />
                      </div>
                      <div className="benefit-content">
                        <h5>Protect Your Reputation</h5>
                        <p>
                          Demonstrate your commitment to regulatory standards,
                          enhancing customer trust.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/reduce-stress.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>Reduce Stress</h5>
                        <p>
                          Let our experts handle compliance, allowing you to
                          focus on your business.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div id="desktop" className="col-md-6">
                <div className="benefit-sol-content-img">
                  <span className="styled-image">
                    <img
                      alt=""
                      src="assets/img/benefit-solutions.png"
                      className="styled-image"
                    />
                    {/* <!-- <img alt='' src="assets/img/fons-heijnsbroek-91Wj8j053cY-unsplash.jpg" /> --> */}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="our-apporach-bg-top">
        <img alt="" src="assets/img/consequences-bottom-shade.png" />
      </div>

      <div className="success-stories">
        <div className="success-stories-holder">
          <div className="success-stories-content">
            <h2>Success Stories</h2>
            <p>What our client say about our services</p>
          </div>
        </div>

        <div className="swiper-container success-stories-carousel">
          <SwiperContainer />
        </div>
      </div>

      <RequestADemo />
      <NewsLetter />
    </div>
  );
}
