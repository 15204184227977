import React from "react";

export default function TermsOfService() {
  return (
    <div>
      <div className="our-approach transparent-bg privacyPage">
        <div className="container">
          <div className="our-approach-inner">
            <div className="row text-center">
              <div className="col-md-12 our-approach-content">
                <h2>Terms of Service</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <h5>Acceptance of Terms</h5>
                <p>
                  Kimbocorp permits you (“User” or “you” or “your”) to access and
                  use the Site and Content, subject to these T&Cs. By accessing
                  or using any portion of the Site, you acknowledge that you
                  have read, understood, and agree to be bound by these T&Cs. If
                  you are entering into these T&Cs on behalf of a company or
                  other legal entity (“User Entity”), you must have the legal
                  authority to contractually bind such User Entity to these
                  T&Cs, in which case the terms “you” or “your” or “User” will
                  refer to such User Entity. If you lack such legal authority to
                  contractually bind or you do not agree with these T&Cs, you
                  must not accept these T&Cs or access or use the site or
                  content.
                </p>
                <h5>T&C updates</h5>
                <p>
                  Kimbocorp reserves the right, at its sole discretion, to change
                  or modify portions of these T&Cs at any time. Kimbocorp will
                  post the changes to these T&Cs on the Site and will indicate
                  at the top of this page the date these terms were last
                  revised. It is your responsibility to check the T&Cs
                  periodically for changes. Your continued use of the Site and
                  Content after the date any such changes become effective
                  constitutes your acceptance of the new or revised T&Cs.
                </p>
                <h5>General Conditions/Access and Use</h5>
                <p>
                  Authorization to Access and Use Site and Content Subject to
                  your compliance with these T&Cs and the provisions hereof, you
                  may access or use the Site and Content solely for the purpose
                  of your evaluation of Kimbocorp and Kimbocorp’s products and
                  services. You may only link to the Site or Content, or any
                  portion thereof, as expressly permitted by Kimbocorp.
                </p>
                <p>
                  Ownership and Restrictions. All rights, title and interest in
                  and to the Site and Content will remain with and belong
                  exclusively to Kimbocorp. You will not (a) sublicense, resell,
                  rent, lease, transfer, assign, time share or otherwise
                  commercially exploit or make the Site and any Content
                  available to any third party, (b) use the Site and Content in
                  any unlawful manner (including without limitation in violation
                  of any data, privacy or export control laws) or in any manner
                  that interferes with or disrupts the integrity or performance
                  of the Site and Content or their related components, or (c)
                  modify, adapt or hack the Site and Content to, or try to, gain
                  unauthorized access to the restricted portions of the Site and
                  Content or related systems or networks (i.e., circumvent any
                  encryption or other security measures, gain access to any
                  source code or any other underlying form of technology or
                  information, and gain access to any part of the Site and
                  Content, or any other products or services of Kimbocorp that
                  are not readily made available to the general public).
                </p>
                <p>
                  You are not permitted to copy, modify, frame, repost, publicly
                  perform or display, sell, reproduce, distribute, or create
                  derivative works of the Site and Content, except that you may
                  download, display, and print one copy of the publicly
                  available materials (i.e., the Content that does not require
                  an Account name or password to access) on any single computer
                  solely for your personal, non-commercial use, provided that
                  you do not modify the material in any way and you keep intact
                  all copyright, trademark, and other proprietary notices. You
                  agree not to access the Site or Content by any means other
                  than through the interface that is provided by Kimbocorp to
                  access the same. You may not use any “page-scrape,”
                  “deep-link,” “spider,” or “robot or other automatic program,
                  device, algorithm or methodology, or any similar manual
                  process, to access, copy, acquire, or monitor any portion of
                  the Site or any Content, or in any way reproduce or circumvent
                  the presentation or navigational structure of the Site or any
                  Content, to obtain or attempt to obtain any Content or other
                  information through any means not made generally available
                  through the Site by Kimbocorp. Kimbocorp reserves the right to
                  take any lawful measures to prevent any such activity. You may
                  not forge headers or otherwise manipulate identifiers in order
                  to disguise the origin of any message or transmittal you send
                  to Kimbocorp on or through the Site or any service offered on
                  or through the Site. You may not pretend that you are, or that
                  you represent, someone else, or impersonate any other
                  individual or entity.
                </p>
                <p>
                  <span>Responsibility for Your Data</span> You are solely
                  responsible for all data, information and other content, that
                  you upload, post, or otherwise provide or store (hereafter
                  “post(ing)”) in connection with or relating to the Site.
                </p>
                <p>
                  <span>Reservation of Rights</span> Kimbocorp and its licensors
                  each own and retain their respective rights in and to all
                  logos, company names, marks, trademarks, copyrights, trade
                  secrets, know-how, patents and patent applications that are
                  used or embodied in or otherwise related to the Site and
                  Content. Kimbocorp grants no rights or licenses (implied, by
                  estoppel, or otherwise) whatsoever to you under these T&Cs.
                </p>
                <h5>Use of Intellectual Property</h5>
                <p>
                  <span>Rights in User</span> Content By providing your
                  information and other content (“User Content”) on or through
                  the Site and Content, you grant Kimbocorp a worldwide,
                  non-exclusive, perpetual, irrevocable, royalty-free, fully
                  paid, sublicensable and transferable license to use, modify,
                  reproduce, distribute, display, publish and perform User
                  Content in connection with the Site and Content. Kimbocorp has
                  the right, but not the obligation, to monitor the Site and
                  Content and User Content. Kimbocorp may remove or disable any
                  User Content at any time for any reason, or for no reason at
                  all.
                </p>
                <p>
                  <span>Unsecured Transmission of User Content</span> You
                  understand that the operation of the Site and Platform,
                  including User Content, may be unencrypted and involve
                  transmission to Kimbocorp’s third party vendors and hosting
                  partners to operate and maintain the Site and Content.
                  Accordingly, you acknowledge that you bear sole responsibility
                  for adequate security, protection and backup of User Content.
                  Kimbocorp will have no liability to you for any unauthorized
                  access or use of any of User Content, or any corruption,
                  deletion, destruction or loss of any of User Content
                </p>
                <p>
                  <span>Feedback</span> You may submit ideas, suggestions, or
                  comments (“Feedback”) regarding the Site and Content or
                  Kimbocorp’s business, products or services. By submitting any
                  Feedback, you acknowledge and agree that (a) your Feedback is
                  provided by you voluntarily and Kimbocorp may, without any
                  obligations or limitation, use and exploit such Feedback in
                  any manner and for any purpose, (b) you will not seek and are
                  not entitled to any money or other form of compensation,
                  consideration, or attribution with respect to your Feedback
                  regardless of whether Kimbocorp considered or used your
                  Feedback in any manner, and (c) your Feedback is not the
                  confidential or proprietary information of you or any third
                  party.
                </p>
                <p>
                  <span>Your Representations and Warranties.</span>
                  You represent and warrant to Kimbocorp that your activity on
                  the Site and Kimbocorp’s possession and use of User Content as
                  contemplated in these T&Cs do not and will not violate,
                  infringe, or misappropriate any third party’s copyright,
                  trademark, right of privacy or publicity, or other personal or
                  proprietary right, nor does User Content contain any matter
                  that is defamatory, obscene, unlawful, threatening, abusive,
                  tortious, offensive or harassing.
                </p>
                <p>
                  <span>Termination of Access Due to Violations.</span> Kimbocorp
                  may, in its sole discretion and without prior notice,
                  terminate your access to the Site and/or block your future
                  access to the Site if we determine that you have violated
                  these T&Cs or other agreements or guidelines which may be
                  associated with your use of the Site. You also agree that any
                  violation by you of these T&Cs will cause irreparable harm to
                  Kimbocorp, for which monetary damages would be inadequate, and
                  you consent to Kimbocorp obtaining any injunctive or equitable
                  relief that Kimbocorp deems necessary or appropriate in such
                  circumstances, without limiting Kimbocorp’s other available
                  remedies. Further, Kimbocorp may, in its sole discretion and
                  without prior notice, terminate your access to the Site, for
                  cause, which includes (but is not limited to) (1) requests by
                  law enforcement or other government agencies, (2)
                  discontinuance or material modification of the Site or any
                  service offered on or through the Site, or (3) unexpected
                  technical issues or problems.
                </p>
                <h5>NO WARRANTIES AND DISCLAIMER BY KIMBOCORP</h5>
                <p>
                  THE SITE AND CONTENT, AND ALL SERVER AND NETWORK COMPONENTS,
                  ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITH ALL
                  ERRORS AND DEFECTS AND WITHOUT ANY WARRANTIES OF ANY KIND, AND
                  KIMBOCORP EXPRESSLY DISCLAIMS ALL REPRESENTATIONS AND
                  WARRANTIES, INCLUDING ANY IMPLIED WARRANTIES OF ACCURACY,
                  COMPLETENESS, MERCHANTABILITY, FITNESS FOR A PARTICULAR
                  PURPOSE OR NON-INFRINGEMENT, AND ANY REPRESENTATIONS OR
                  WARRANTIES ARISING FROM COURSE OF DEALING, COURSE OF
                  PERFORMANCE OR USAGE OF TRADE. YOU ACKNOWLEDGE THAT KIMBOCORP
                  DOES NOT WARRANT THAT YOUR ACCESS OR USE OR BOTH OF THE SITE
                  AND CONTENT WILL BE UNINTERRUPTED, TIMELY, SECURE, ERROR-FREE
                  OR VIRUS-FREE, AND KIMBOCORP DOES NOT MAKE ANY WARRANTY AS TO
                  THE RESULTS THAT MAY BE OBTAINED FROM USE OF THE SITE AND
                  CONTENT. NO INFORMATION, ADVICE OR SERVICES OBTAINED BY YOU
                  FROM KIMBOCORP OR THROUGH THE SITE WILL CREATE ANY WARRANTY NOT
                  EXPRESSLY STATED IN THESE T&Cs AND YOU SHOULD NOT RELY ON THE
                  SITE AND THE GENERAL CONTENT ALONE AS THE BASIS FOR YOUR
                  BUSINESS DECISIONS.
                </p>
                <p>
                  Kimbocorp reserves the right to do any of the following, at any
                  time, without notice: (1) to modify, suspend or terminate
                  operation of or access to the Site, or any portion of the
                  Site, for any reason; (2) to modify or change the Site, or any
                  portion of the Site, for any reason; and (3) to interrupt the
                  operation of the Site, or any portion of the Site, as
                  necessary to perform routine or non-routine maintenance, error
                  correction, or other changes.
                </p>
                <h5>LIMITED LIABILITY</h5>
                <p>
                  <span>Exclusion of Damages and Limitation of Liability.</span>
                  NEITHER PARTY (“USER AND KIMBOCORP”)) OR ITS AFFILIATES WILL,
                  UNDER ANY CIRCUMSTANCES, BE LIABLE TO THE OTHER PARTY, UNDER
                  ANY LEGAL OR EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT,
                  TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, OR OTHERWISE,
                  FOR CONSEQUENTIAL, INCIDENTAL, INDIRECT, SPECIAL, EXEMPLARY,
                  ENHANCED, OR PUNITIVE DAMAGES ARISING OUT OF OR RELATED TO
                  THIS AGREEMENT, INCLUDING BUT NOT LIMITED TO LOST PROFITS,
                  REVENUE, BUSINESS, OR DATA; BUSINESS INTERRUPTION; OR LOSS OF
                  GOODWILL OR REPUTATION, REGARDLESS OF WHETHER THE PARTY IS
                  APPRISED OF THE LIKELIHOOD OF SUCH DAMAGES OCCURRING OR ANY
                  LOSSES OR DAMAGES WERE OTHERWISE FORESEEABLE.
                </p>
                <p>
                  <span>Jurisdictional Limitations.</span> Some countries and
                  other jurisdictions do not allow the exclusion of implied
                  warranties or limitation of liability for incidental or
                  consequential damages, which means that some of the above
                  limitations may not apply to you. IN THESE JURISDICTIONS,
                  KIMBOCORP’S LIABILITY WILL BE LIMITED TO THE GREATEST EXTENT
                  PERMITTED BY LAW.
                </p>
                <p>
                  <span>Dispute Resolution; Jury Waiver.</span> THESE T&Cs ARE
                  MADE UNDER, AND WILL BE CONSTRUED AND ENFORCED IN ACCORDANCE
                  WITH, THE LAWS OF SINGAPORE APPLICABLE TO AGREEMENTS MADE AND
                  TO BE PERFORMED SOLELY THEREIN, WITHOUT GIVING EFFECT TO
                  PRINCIPLES OF CONFLICTS OF LAW. In any action between or among
                  any of the parties, whether arising out of these T&Cs or
                  otherwise, each of the parties irrevocably and unconditionally
                  (a) consents and submits to the exclusive jurisdiction and
                  venue of the state courts located in Singapore; and (b) WAIVES
                  ANY AND ALL RIGHT TO TRIAL BY JURY IN ANY LEGAL PROCEEDING
                  ARISING OUT OF OR RELATED TO THIS AGREEMENT OR ANY
                  TRANSACTIONS CONTEMPLATED HEREBY.
                </p>
                <p>
                  <span>No Reliance; Due Inquiry.</span> You affirm and agree
                  that, given the general and “as-is” nature of the Site and the
                  Content you are not specifically relying on any statements, or
                  materials contained on the Site or in the Content to make any
                  business decisions. Business setup, Resource allocation and
                  Staffing matters are inherent complex and unique. Should you
                  choose to continue your inquiry into whether Kimbocorp’s
                  service is right for you, you will have an opportunity to make
                  additional inquiry of Kimbocorp and you should make an
                  independent investigation of the suitability of using Kimbocorp
                  for your particular business needs. Only after such
                  appropriate due diligence, thorough review of Kimbocorp’s
                  service offering including the terms and conditions of
                  Kimbocorp’s service agreements, and a through screening of any
                  professional or solution that Kimbocorp proposes to you should
                  you come to any conclusions or make any decisions about
                  whether Kimbocorp, or any business incorporation and
                  continuance, or any professional proposed by Kimbocorp is right
                  for you and your specific project needs or requirements.
                </p>
                <p>
                  <span>Miscellaneous.</span> These T&Cs, and any additions,
                  changes, edits and/or modifications made thereto by Kimbocorp,
                  together with Kimbocorp’s Privacy Policy, constitute the entire
                  agreement between the parties with respect to the portions of
                  the Site available without an account ID or password. Access
                  to certain password-restricted portions of the Site, and any
                  subsequent procurement and use of Kimbocorp‘s services is
                  subject to different, additional agreement(s). Kimbocorp does
                  not conduct business with or seek any commercial transactions
                  with: (x) any entity, citizen or resident of a geographic area
                  in which access to or use of the Kimbocorp services is
                  prohibited by applicable law, decree, regulation, treaty, or
                  administrative act; (y) an entity, citizen or resident of, or
                  located in, a geographic area that is subject to sanctions or
                  embargoes; or (z) an individual, or an individual employed by
                  or associated with an entity, identified as designated
                  individuals and entities as defined in the respective
                  regulations promulgated under the MAS Act , Variable Capital
                  Companies Act , United Nations Act , and the Terrorism
                  (Suppression of Financing) Act or other economic sanction
                  rules of any sovereign nation (collectively, a “Prohibited
                  Counterparty”). By accessing the Site and the Content you
                  represent and warrant that you are not a Prohibited
                  Counterparty and you will not seek or (attempt to) obtain
                  services from or a commercial relationship with Kimbocorp.
                </p>
                <p>
                  These T&Cs and any additions, changes, edits and/or
                  modifications made thereto by Kimbocorp cannot be amended
                  except by Kimbocorp as set forth above. The failure of Kimbocorp
                  to exercise or enforce any right or provision of these T&Cs
                  will not be a waiver of that right. Any notices to Kimbocorp in
                  connection with this agreement will be made by email
                  transmitted to support@kimbocorp.com provided that you also
                  send a copy of such notice via nationally recognized carrier
                  to Kimbocorp Pte Ltd 151 chin swee road manhattan house 02-24
                  Singapore 169876, Attn: Legal Department. In the event that
                  any provision of these T&Cs will be determined to be illegal
                  or unenforceable, that provision will be first revised to give
                  the maximum permissible effect to its original intent or, if
                  such revision is not permitted, that specific provision will
                  be eliminated so that these T&Cs will otherwise remain in full
                  force and effect and enforceable. Kimbocorp does not conduct
                  business with or seek any commercial transactions with: (x)
                  any entity, citizen or resident of a geographic area in which
                  access to or use of the Kimbocorp services is prohibited by
                  applicable law, decree, regulation, treaty, or administrative
                  act; (y) an entity, citizen or resident of, or located in, a
                  geographic area that is subject to sanctions or embargoes; or
                  (z) an individual, or an individual employed by or associated
                  with an entity, identified as designated individuals and
                  entities as defined in the respective regulations promulgated
                  under the MAS Act , Variable Capital Companies Act , United
                  Nations Act , and the Terrorism (Suppression of Financing) Act
                  or other economic sanction rules of any sovereign nation
                  (collectively, a “Prohibited Counterparty”). By accessing the
                  Site and the Content you represent and warrant that you are
                  not a Prohibited Counterparty and you will not seek or
                  (attempt to) obtain services from or a commercial relationship
                  with Kimbocorp.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
