import React from "react";
import HeaderBanner from "../components/HeaderBanner";
import SwiperContainer from "../components/SwiperContainer";
import RequestADemo from "../components/RequestADemo";
import NewsLetter from "../components/NewsLetter";

export default function Governance() {
  return (
    <div>
      <div className="banner">
        <HeaderBanner
          headerText="Strengthen Your Business with Embedded Governance Support"
          subHeaderText="Ensure Robust Governance with Expert Guidance and Meritocratic Management Personnel"
          desc="At kimbocorp, we recognise the critical importance of strong governance in maintaining a healthy and compliant business environment. Our embedded governance support services are designed to seamlessly integrate into your operations, providing continuous oversight, expert guidance, and meritocratic management personnel to help you uphold the highest standards of corporate governance."
          img={"assets/img/governance-banner-img.png"}
          isDemoHeader={1}
        />
      </div>
      <div className="our-apporach-bg-top">
        <img alt="" src="assets/img/consequences-top-shade.png" />
      </div>
      <div className="our-approach governance">
        <div className="container">
          <div className="our-approach-inner">
            <div className="row text-center">
              <div className="col-md-12 our-approach-content">
                <h2>Our Approach</h2>
                <p>Our #1 priority is your business</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="request-demo">
                  <div className="request-demo-holder linear-blue">
                    <div className="row align-items-center">
                      <div className="col-md-6">
                        <h3>Governance Assessment by Management Personnel</h3>
                        <p>
                          Our experienced management personnel conduct a
                          comprehensive evaluation of your current governance
                          framework, identifying strengths and areas for
                          improvement.
                        </p>
                      </div>
                      <div className="col-md-6">
                        <div className="req-demo-image">
                          <img
                            alt=""
                            id="desktop"
                            className="demo-preview"
                            src="assets/img/comprehensive-assessment.png"
                          />
                          <img
                            alt=""
                            id="mobile"
                            className="demo-preview"
                            src="assets/img/comprehensive-assessment-mob.png"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="request-demo">
                  <div className="request-demo-holder linear-green">
                    <div className="row align-items-center">
                      <div className="col-md-12">
                        <h3>Implementation of a Customised Governance Plan</h3>
                        <p>
                          Based on the assessment, our governance experts
                          develop and implement a tailored strategy that aligns
                          with your business objectives and regulatory
                          requirements.
                        </p>
                      </div>
                      <div className="col-md-12">
                        <div className="req-demo-image  mt-4">
                          <img
                            alt=""
                            id="desktop"
                            className="demo-preview"
                            src="assets/img/linear-green-2.png"
                          />
                          <img
                            alt=""
                            id="mobile"
                            className="demo-preview"
                            src="assets/img/linear-green-mob.png"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="request-demo">
                  <div className="request-demo-holder linear-yellow">
                    <div className="row align-items-center">
                      <div className="col-md-12">
                        <h3>
                          Provision and Integration of Additional Personnel
                        </h3>
                        <p>
                          If necessary, we provide additional qualified
                          personnel who are experts in your specific industry,
                          ensuring all governance functions are effectively
                          managed.
                        </p>
                      </div>
                      <div className="col-md-12">
                        <div className="req-demo-image mt-4">
                          <img
                            alt=""
                            id="desktop"
                            className="demo-preview"
                            src="assets/img/linear-yellow-.png"
                          />
                          <img
                            alt=""
                            id="mobile"
                            className="demo-preview"
                            src="assets/img/linear-yellow-mob.png"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="request-demo">
                  <div className="request-demo-holder linear-pink">
                    <div className="row flex-row-reverse align-items-center">
                      <div className="col-md-6">
                        <h3>Continuous Monitoring and Updates</h3>
                        <p>
                          Our team continuously monitors governance laws and
                          principles, updating and implementing necessary
                          changes to keep your business compliant and ahead of
                          regulatory changes.
                        </p>
                      </div>
                      <div className="col-md-6">
                        <div className="req-demo-image">
                          <img
                            alt=""
                            id="desktop"
                            className="demo-preview"
                            src="assets/img/linear-pink-1.png"
                          />
                          <img
                            alt=""
                            id="mobile"
                            className="demo-preview"
                            src="assets/img/linear-pink-1-mob.png"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="our-apporach-bg-top">
        <img alt="" src="assets/img/consequences-bottom-shade-only.png" />
      </div>

      <div className="why-choose">
        <div className="container">
          <div className="why-choose-inner">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="benefit-sol-content-img">
                  <img alt="" src="assets/img/why-choose-2.png" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="why-choose-us-block">
                  <h2>Why choose kimbocorp?</h2>
                  <p>
                    Recent AML/CFT regulations have heightened the focus on
                    governance, making it essential to have knowledgeable and
                    reliable personnel managing your governance needs. At
                    kimbocorp, we predominantly provide governance personnel who
                    understand your specific industry and business activities.
                    This allows you to focus on building your business while we
                    ensure you are compliant with all governance principles.
                  </p>
                  <div className="banner-button">
                    <button type="button" className="secondary-button">
                      Know more
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="our-apporach-bg-top">
        <img alt="" src="assets/img/consequences-top-shade.png" />
      </div>
      <div className="benefit-solutions">
        <div className="container">
          <div className="benefit-solutions-inner">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="benefit-sol-content">
                  <h2>Consequences of not taking action</h2>
                  <p>Without our ready-made solutions, you could face:</p>

                  <ul>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/g1.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>Liabilities of Nominee Personnel</h5>
                        <p>
                          Nominee personnel may not be fit and proper, failing
                          to understand your business activities and potentially
                          impeding processes.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/g2.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>Regulatory Non-Compliance</h5>
                        <p>
                          Weak governance can lead to non-compliance with laws
                          and regulations, resulting in fines and legal issues.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/g3.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>Operational Risks</h5>
                        <p>
                          Poor governance practices can increase the risk of
                          operational failures and financial losses.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/g4.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>Repetitional Damage</h5>
                        <p>
                          Inadequate governance can harm your company’s
                          reputation, eroding stakeholder trust.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div id="desktop" className="col-md-6">
                <div className="benefit-sol-content-img">
                  <span className="">
                    <img alt="" src="assets/img/gc1.png" className="" />
                  </span>
                </div>
              </div>
            </div>
            <div className="row align-items-center flex-row-reverse margin-top-consequences">
              <div className="col-md-6">
                <div className="benefit-sol-content">
                  <h2>Benefits of our solutions</h2>
                  <p>By choosing kimbocorp’s ready-made companies, you will:</p>

                  <ul>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/g5.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>Enhanced Image</h5>
                        <p>
                          Gain a positive image with real contributions from
                          governance personnel, not just in name for statutory
                          purposes.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/g6.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>Reduced Subjectivity</h5>
                        <p>
                          Benefit from personnel familiar with your industry,
                          providing relevant advice and reducing subjective
                          decision-making.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/g7.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>Clear Understanding of Governance</h5>
                        <p>
                          Resident directors will be transparent about the scope
                          and requirements, explaining why they are necessary
                          and ensuring you understand governance principles.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/g8.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>Collaborative Decision-Making</h5>
                        <p>
                          Our resident directors listen and work with you as
                          part of your board, participating in decision-making
                          to achieve business objectives while instilling strong
                          governance.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div id="desktop" className="col-md-6">
                <div className="benefit-sol-content-img">
                  <span className="">
                    <img alt="" src="assets/img/gc2.png" className="" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="our-apporach-bg-top">
        <img alt="" src="assets/img/consequences-bottom-shade.png" />
      </div>

      <div className="success-stories">
        <div className="success-stories-holder">
          <div className="success-stories-content">
            <h2>Success Stories</h2>
            <p>What our client say about our services</p>
          </div>
        </div>

        <SwiperContainer />
      </div>

      <RequestADemo />
      <NewsLetter />
    </div>
  );
}
