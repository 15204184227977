import React from "react";
import SwiperContainer from "../components/SwiperContainer";
import NewsLetter from "../components/NewsLetter";
import RequestADemo from "../components/RequestADemo";
import HeaderBanner from "../components/HeaderBanner";

export default function ReadyMadeCompanies() {
  return (
    <div>
      <div className="banner readymade-banner">
        <HeaderBanner
          headerText="Launch your business with Ready-Made Companies tailored to your needs"
          subHeaderText="Instantly access fully-formed commercial ventures designed for any industry"
          desc="At kimbocorp, we understand that starting a business can be daunting. From securing the right licenses to drafting contracts and finding stakeholders, the process can be overwhelming. That’s why we offer ready-made companies that are fully prepared for any business venture, complete with all the necessary elements to help you hit the ground running."
          img={"assets/img/readymade-banner-img.png"}
          isDemoHeader={0}
          isCenterBanner={true}
        />
      </div>

      <div className="our-approach transparent-bg">
        <div className="container">
          <div className="our-approach-inner">
            <div className="row text-center">
              <div className="col-md-12 our-approach-content">
                <h2>Our Approach</h2>
                <p>Our #1 priority is your business</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="request-demo">
                  <div className="request-demo-holder linear-blue">
                    <div className="row align-items-center">
                      <div className="col-md-6">
                        <h3>Choose your business-activity-specific template</h3>
                        <p>
                          We provide templated companies tailored for various
                          industries. Each template includes pre-arranged
                          stakeholders, counterparties, and all the essential
                          contracts and licensing support.
                        </p>
                      </div>
                      <div className="col-md-6">
                        <div className="req-demo-image">
                          <img
                            alt=""
                            id="desktop"
                            className="demo-preview"
                            src="assets/img/comprehensive-assessment.png"
                          />
                          <img
                            alt=""
                            id="mobile"
                            className="demo-preview"
                            src="assets/img/comprehensive-assessment-mob.png"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="request-demo">
                  <div className="request-demo-holder linear-green">
                    <div className="row align-items-center">
                      <div className="col-md-12">
                        <h3>Receive expert guidance</h3>
                        <p>
                          Our industry experts embedded within each template
                          will advise you on the best practices and strategies
                          for your specific business venture.
                        </p>
                      </div>
                      <div className="col-md-12">
                        <div className="req-demo-image  mt-4">
                          <img
                            alt=""
                            id="desktop"
                            className="demo-preview"
                            src="assets/img/linear-green-.png"
                          />
                          <img
                            alt=""
                            id="mobile"
                            className="demo-preview"
                            src="assets/img/linear-green-mob.png"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="request-demo">
                  <div className="request-demo-holder linear-yellow">
                    <div className="row align-items-center">
                      <div className="col-md-12">
                        <h3>Build your business</h3>
                        <p>
                          Simply work through each element in the template. From
                          finalising contracts to securing necessary licenses,
                          we’ve got everything covered to ensure your business
                          is set up for success.
                        </p>
                      </div>
                      <div className="col-md-12">
                        <div className="req-demo-image mt-4">
                          <img
                            alt=""
                            id="desktop"
                            className="demo-preview"
                            src="assets/img/linear-yellow-.png"
                          />
                          <img
                            alt=""
                            id="mobile"
                            className="demo-preview"
                            src="assets/img/linear-yellow-mob.png"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="our-apporach-bg-top">
        <img alt="" src="assets/img/consequences-top-shade.png" />
      </div>
      <div className="benefit-solutions">
        <div className="container">
          <div className="benefit-solutions-inner">
            <div className="row text-center">
              <div className="col-md-2"></div>
              <div className="col-md-8 our-approach-content">
                <h2>Why choose kimbocorp?</h2>
                <p>
                  Starting a business from scratch is not only time-consuming
                  but also filled with potential pitfalls. With kimbocorp, you
                  can avoid the common startup hurdles by leveraging our
                  ready-made companies that are fully equipped and compliant
                  with industry standards. Focus on growing your business while
                  we take care of the foundational work.
                </p>
              </div>
              <div className="col-md-2"></div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="benefit-sol-content">
                  <h2>Consequences of not taking action</h2>
                  <p>Without our ready-made solutions, you could face:</p>

                  <ul>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/rmc-icon-1.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>Extended Time to Market</h5>
                        <p>
                          Delays in setting up your business can lead to missed
                          opportunities.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/rmc-icon-2.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>Increased Costs</h5>
                        <p>
                          Navigating the startup process independently often
                          results in higher costs due to unforeseen expenses and
                          inefficiencies.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/rmc-icon-3.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>Regulatory Risks</h5>
                        <p>
                          Lack of proper guidance can lead to non-compliance
                          with industry regulations, resulting in fines and
                          legal issues.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/rmc-icon-4.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>Stress and Overwhelm</h5>
                        <p>
                          The complexity of starting a business from scratch can
                          be overwhelming and stressful, potentially impacting
                          your focus and productivity.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div id="desktop" className="col-md-6">
                <div className="benefit-sol-content-img">
                  <span className="styled-image">
                    <img
                      alt=""
                      src="assets/img/rmc-consequence-1.png"
                      className="styled-image"
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="row align-items-center flex-row-reverse margin-top-consequences">
              <div className="col-md-6">
                <div className="benefit-sol-content">
                  <h2>Benefits of our solutions</h2>
                  <p>By choosing kimbocorp’s ready-made companies, you will:</p>

                  <ul>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/rmc-icon-5.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>Accelerate Your Startup Timeline</h5>
                        <p>
                          Launch your business quickly with all foundational
                          elements in place.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/rmc-icon-6.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>Save Money</h5>
                        <p>
                          Avoid unnecessary costs and leverage our streamlined
                          processes.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/rmc-icon-7.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>Ensure Compliance</h5>
                        <p>
                          Rest assured that your business meets all regulatory
                          requirements from the start.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/rmc-icon-8.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>Receive Expert Support</h5>
                        <p>
                          Gain access to industry experts who provide invaluable
                          guidance and support.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div id="desktop" className="col-md-6">
                <div className="benefit-sol-content-img">
                  <span className="styled-image">
                    <img
                      alt=""
                      src="assets/img/rmc-consequence-2.png"
                      className="styled-image"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="our-apporach-bg-top">
        <img alt="" src="assets/img/consequences-bottom-shade.png" />
      </div>

      <div className="success-stories">
        <div className="success-stories-holder">
          <div className="success-stories-content">
            <h2>Success Stories</h2>
            <p>What our client say about our services</p>
          </div>
        </div>

        <div className="swiper-container success-stories-carousel">
          <SwiperContainer />
        </div>
      </div>

      <RequestADemo />
      <NewsLetter />
    </div>
  );
}
