import React from "react";
import RequestADemo from "../components/RequestADemo";
import NewsLetter from "../components/NewsLetter";
import HeaderBanner from "../components/HeaderBanner";

export default function HolisticSolutions() {
  return (
    <div>
      <div class="banner">
        <HeaderBanner
          headerText="Start Your Commercial Venture for Free with Kimbocorp"
          subHeaderText="Empower Your Business Journey with Comprehensive Tools and Expert Support"
          desc="At Kimbocorp, we enable you to start any commercial venture for free, providing you with all the necessary tools, insights, and support to build and grow your business. From governance and stakeholder management to financial efficiency and dedicated advisory, our platform ensures you have everything you need to succeed."
          img={"assets/img/holistic-banner-img.png"}
          isDemoHeader={0}
        />
      </div>
      <div class="our-apporach-bg-top">
        <img alt="" src="assets/img/our-approach-top-bg-top.png" />
      </div>
      <div class="our-approach">
        <div class="container">
          <div class="our-approach-inner">
            <div class="row text-center">
              <div class="col-md-12 our-approach-content">
                <h2>All-In Company Packages</h2>
                <p>
                  Our all-in company packages are designed to cover every
                  critical area of your business operations. Here’s what we
                  include
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 mt-4">
                <div class="based-accounting-block">
                  <div class="based-accounting-icon">
                    <img alt="" src="assets/img/holistic-icon1.svg" />
                  </div>
                  <h4>Compliance</h4>
                  <div class="based-accounting-p">
                    <p>
                      Stay compliant with local and international regulations
                      with our comprehensive compliance services, covering
                      everything from tax filings to regulatory updates.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4 mt-4">
                <div class="based-accounting-block">
                  <div class="based-accounting-icon">
                    <img alt="" src="assets/img/holistic-icon2.svg" />
                  </div>
                  <h4>Account Creation</h4>
                  <div class="based-accounting-p">
                    <p>
                      Set up and manage your company’s financial accounts with
                      ease, supported by our comprehensive banking and payment
                      services.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4 mt-4">
                <div class="based-accounting-block">
                  <div class="based-accounting-icon">
                    <img alt="" src="assets/img/holistic-icon3.svg" />
                  </div>
                  <h4>Governance</h4>
                  <div class="based-accounting-p">
                    <p>
                      Implement robust governance practices with our tools and
                      expert support, ensuring your company operates with
                      transparency and accountability.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 mt-4">
                <div class="based-accounting-block">
                  <div class="based-accounting-icon">
                    <img alt="" src="assets/img/holistic-icon4.svg" />
                  </div>
                  <h4>Share Registry Management</h4>
                  <div class="based-accounting-p">
                    <p>
                      Maintain accurate and up-to-date records of your
                      shareholders, ensuring compliance with legal requirements
                      and smooth shareholder communication.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 mt-4">
                <div class="based-accounting-block">
                  <div class="based-accounting-icon">
                    <img alt="" src="assets/img/holistic-icon5.svg" />
                  </div>
                  <h4>Resident Director Support</h4>
                  <div class="based-accounting-p">
                    <p>
                      Access experienced and qualified resident directors who
                      understand your industry and are dedicated to ensuring
                      your company’s success.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="our-apporach-bg-top div-img-block">
        <img alt="" src="assets/img/additional-bottom-shade-white-bottom.png" />
      </div>

      <div class="our-approach singapore holistic-solutions">
        <div class="container">
          <div class="our-approach-inner">
            <div class="row text-center">
              <div class="col-md-12 our-approach-content">
                <h2>Additional Features</h2>
                <p>
                  To further enhance your business operations, our all-in
                  holistic solutions include
                </p>
              </div>
            </div>
            <div class="row our-approach-inner-block-benefits text-center">
              <div class="col-md-4 mt-4">
                <div class="based-accounting-block">
                  <div class="based-accounting-icon">
                    <img
                      alt=""
                      src="assets/img/additional-feature-icon-1.svg"
                    />
                  </div>
                  <h4>Unlimited Compliance Support</h4>
                  <div class="based-accounting-p">
                    <p>
                      Receive ongoing compliance support, ensuring your venture
                      aligns with all regulatory requirements and standards.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4 mt-4">
                <div class="based-accounting-block">
                  <div class="based-accounting-icon">
                    <img
                      alt=""
                      src="assets/img/additional-feature-icon-2.svg"
                    />
                  </div>
                  <h4>Ongoing Governance</h4>
                  <div class="based-accounting-p">
                    <p>
                      Benefit from continuous governance assistance, helping you
                      maintain transparency and accountability within your
                      company.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4 mt-4">
                <div class="based-accounting-block">
                  <div class="based-accounting-icon">
                    <img
                      alt=""
                      src="assets/img/additional-feature-icon-3.svg"
                    />
                  </div>
                  <h4>Meritocratic Resident Directors</h4>
                  <div class="based-accounting-p">
                    <p>
                      Our resident directors are selected based on merit and
                      industry expertise, providing you with the best guidance
                      and support.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4 mt-4">
                <div class="based-accounting-block">
                  <div class="based-accounting-icon">
                    <img
                      alt=""
                      src="assets/img/additional-feature-icon-4.svg"
                    />
                  </div>
                  <h4>Unlimited Payment Gateways and Crypto Facilities</h4>
                  <div class="based-accounting-p">
                    <p>
                      Seamlessly integrate payment gateways and cryptocurrency
                      facilities to support a wide range of transaction needs.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4 mt-4">
                <div class="based-accounting-block">
                  <div class="based-accounting-icon">
                    <img
                      alt=""
                      src="assets/img/additional-feature-icon-5.svg"
                    />
                  </div>
                  <h4>Unlimited Bank Facilities</h4>
                  <div class="based-accounting-p">
                    <p>
                      Access comprehensive banking facilities to manage your
                      company’s financial operations efficiently and securely.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4 mt-4">
                <div class="based-accounting-block">
                  <div class="based-accounting-icon">
                    <img
                      alt=""
                      src="assets/img/additional-feature-icon-6.svg"
                    />
                  </div>
                  <h4>Unlimited Stakeholder Management and Suggestions</h4>
                  <div class="based-accounting-p">
                    <p>
                      Manage your stakeholders effectively with unlimited
                      support, including personalized suggestions to enhance
                      your business relationships.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4 mt-4">
                <div class="based-accounting-block">
                  <div class="based-accounting-icon">
                    <img
                      alt=""
                      src="assets/img/additional-feature-icon-7.svg"
                    />
                  </div>
                  <h4>Unlimited Contract Administration Support</h4>
                  <div class="based-accounting-p">
                    <p>
                      Our platform offers continuous support for contract
                      administration, ensuring your agreements are well-managed
                      and compliant.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4 mt-4">
                <div class="based-accounting-block">
                  <div class="based-accounting-icon">
                    <img
                      alt=""
                      src="assets/img/additional-feature-icon-8.svg"
                    />
                  </div>
                  <h4>Unlimited Accounting and Bookkeeping Support</h4>
                  <div class="based-accounting-p">
                    <p>
                      Keep your financial records accurate and up-to-date with
                      our unlimited accounting and bookkeeping services.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4 mt-4">
                <div class="based-accounting-block">
                  <div class="based-accounting-icon">
                    <img
                      alt=""
                      src="assets/img/additional-feature-icon-9.svg"
                    />
                  </div>
                  <h4>Unlimited Licensing and Regulatory Filing Support</h4>
                  <div class="based-accounting-p">
                    <p>
                      Ensure all your licensing and regulatory filings are
                      completed accurately and on time with our comprehensive
                      support.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="our-approach governance holistic-gradient">
        <div class="container">
          <div class="our-approach-inner">
            <div class="row text-center">
              <div class="col-md-12 our-approach-content">
                <h2>Our Approach</h2>
                <p>Our #1 priority is your business</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="request-demo">
                  <div class="request-demo-holder linear-blue">
                    <div class="row align-items-center">
                      <div class="col-md-6">
                        <h3>Dedicated Advisory Support</h3>
                        <p>
                          Our team of experts offers dedicated advisory support
                          to help you navigate any challenges and make informed
                          decisions.
                        </p>
                      </div>
                      <div class="col-md-6">
                        <div class="req-demo-image">
                          <img
                            alt=""
                            id="desktop"
                            class="demo-preview"
                            src="assets/img/comprehensive-assessment.png"
                          />
                          <img
                            alt=""
                            id="mobile"
                            class="demo-preview"
                            src="assets/img/comprehensive-assessment-mob.png"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="request-demo">
                  <div class="request-demo-holder linear-green">
                    <div class="row align-items-center">
                      <div class="col-md-12">
                        <h3>Seamless Onboarding Process</h3>
                        <p>
                          Our platform guides you through the onboarding
                          process, ensuring a smooth start for your venture
                          without any initial costs.
                        </p>
                      </div>
                      <div class="col-md-12">
                        <div class="req-demo-image  mt-4">
                          <img
                            alt=""
                            id="desktop"
                            class="demo-preview"
                            src="assets/img/linear-green-.png"
                          />
                          <img
                            alt=""
                            id="mobile"
                            class="demo-preview"
                            src="assets/img/linear-green-mob.png"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="request-demo">
                  <div class="request-demo-holder linear-yellow">
                    <div class="row align-items-center">
                      <div class="col-md-12">
                        <h3>Comprehensive Document Management</h3>
                        <p>
                          We provide all the necessary documents for
                          incorporation, compliance, and ongoing management,
                          making it easy to stay organized and compliant.
                        </p>
                      </div>
                      <div class="col-md-12">
                        <div class="req-demo-image mt-4">
                          <img
                            alt=""
                            id="desktop"
                            class="demo-preview"
                            src="assets/img/linear-yellow-.png"
                          />
                          <img
                            alt=""
                            id="mobile"
                            class="demo-preview"
                            src="assets/img/linear-yellow-mob.png"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="our-apporach-bg-top">
        <img alt="" src="assets/img/additional-bottom-shade-white-top.png" />
      </div>
      <div class="benefit-solutions accounting">
        <div class="container">
          <div class="benefit-solutions-inner">
            <div class="row text-center">
              <div class="col-md-12 our-approach-content">
                <p class="highlight-text">All-In Company Plan</p>
                <h2>All-In Company Plan Starting from $0 a Year</h2>
                <p>Our #1 priority is your business</p>
              </div>
            </div>
            <div class="row align-items-center flex-row-reverse">
              <div class="col-md-6">
                <div class="benefit-sol-content">
                  <h2>kimbocorp’s integrated approach:</h2>

                  <ul>
                    <li>
                      <div class="benefit-icons">
                        <img alt="" src="assets/img/ib-1.svg" />
                      </div>
                      <div class="benefit-content">
                        <p>
                          Guarantee Business Continuity and Financial
                          Efficiencies
                        </p>
                      </div>
                    </li>
                    <li>
                      <div class="benefit-icons">
                        <img alt="" src="assets/img/ib-2.svg" />
                      </div>
                      <div class="benefit-content">
                        <p>
                          Focus on Appointing Stakeholders and Fulfilling
                          Business Activities
                        </p>
                      </div>
                    </li>
                    <li>
                      <div class="benefit-icons">
                        <img alt="" src="assets/img/ib-3.svg" />
                      </div>
                      <div class="benefit-content">
                        <p>
                          At kimbocorp, we guarantee business continuity and
                          financial efficiencies, allowing you to focus on
                          appointing stakeholders and fulfilling your business
                          activities with complete peace of mind.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div id="desktop" class="col-md-6">
                <div class="benefit-sol-content-img">
                  <span class="">
                    <img
                      alt=""
                      src="assets/img/company-plan-year.png"
                      class=""
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="our-apporach-bg-top">
        <img alt="" src="assets/img/our-approach-top-bg-bottom.png" />
      </div>

      <RequestADemo />
      <NewsLetter />
    </div>
  );
}
