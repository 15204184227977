import React from "react";
// import { RoutesPaths } from "../Router/constant";
// import { useNavigate } from "react-router-dom";

export default function RequestADemo() {
  // const navigate = useNavigate();
  return (
    <div className="request-demo main-demo">
      <div className="container">
        <div className="request-demo-holder main-demo">
          <div className="row flex-row-reverse align-items-center">
            <div className="col-md-6">
              <div className="req-demo-image">
                <img
                  alt=""
                  id="desktop"
                  className="demo-preview"
                  src="assets/img/active-business-demo-image.png"
                />
                <img
                  alt=""
                  id="mobile"
                  className="demo-preview"
                  src="assets/img/active-business-demo-image.png"
                />
              </div>
            </div>
            <div className="col-md-6">
              <h3>Request a Demo</h3>
              <p>
                Ready to enhance your governance framework with resident
                directors and qualified individuals? Contact us now to request a
                demo and learn how kimbocorp’s embedded governance services can
                benefit your business.
              </p>
              <div className="request-demo-button">
                <button
                  // onClick={() => navigate(RoutesPaths.MESSAGESCREEN)}
                  type="button"
                  className="secondary-button">
                  Book a demo <img alt="" src="assets/img/zap-fast.svg" />
                </button>
              </div>
              <p className="m-0 not-ready-yet">
                Not ready yet? <a href="/">Learn more</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
