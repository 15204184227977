import React from "react";
import HeaderBanner from "../components/HeaderBanner";
import SwiperContainer from "../components/SwiperContainer";
import RequestADemo from "../components/RequestADemo";
import NewsLetter from "../components/NewsLetter";

export default function SingaporeSolutions() {
  return (
    <div>
      <div className="banner">
        <HeaderBanner
          headerText="Comprehensive Corporate Services for Companies in Singapore"
          subHeaderText="Expert Solutions for Incorporation, Compliance, Accounting, Payroll, Tax, and More"
          desc="At kimbocorp, we offer a full suite of corporate services tailored to meet the needs of companies operating in Singapore. Our services cover every aspect of business administration, from company incorporation and secretarial services to complex tax advisory and fund administration solutions. With kimbocorp, you can ensure that your business remains compliant, efficient, and well-managed."
          img={"assets/img/singapore-banner-img.png"}
          isDemoHeader={0}
        />
      </div>

      <div className="our-apporach-bg-top">
        <img alt="" src="assets/img/consequences-top-shade.png" />
      </div>
      <div className="why-choose singapore-bg">
        <div className="container">
          <div className="why-choose-inner">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="benefit-sol-content-img">
                  <img alt="" src="assets/img/why-choose-img-singapore.png" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="why-choose-us-block">
                  <p className="highlight-text">
                    Special Offer: Guaranteed Lower Rates
                  </p>
                  <h2>Guaranteed Lower Rates than Your Incumbent Provider</h2>
                  <p>
                    We are committed to providing the best value for our
                    services. Upload a quotation from your current provider, and
                    we will offer a discount to guarantee lower rates.
                  </p>
                  <div className="banner-button">
                    <button type="button" className="secondary-button">
                      Upload Quotation for Discount
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="our-apporach-bg-top div-img-block">
        <img alt="" src="assets/img/consequences-bottom-shade-only.png" />
      </div>

      <div className="our-approach singapore">
        <div className="container">
          <div className="our-approach-inner">
            <div className="row text-center">
              <div className="col-md-12 our-approach-content">
                <h2>Our Approach</h2>
                <p>Our #1 priority is your business</p>
              </div>
            </div>
            <div className="row our-approach-inner-block-benefits">
              <div className="col-md-4 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/sing-appr-1.svg" />
                  </div>
                  <h4>Company Secretarial Services</h4>
                  <div className="based-accounting-p">
                    <p>
                      We provide comprehensive company secretarial services to
                      ensure your business complies with Singapore’s regulatory
                      requirements. Our services include maintaining statutory
                      registers, preparing and filing annual returns, and
                      ensuring compliance with the Companies Act.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/sing-appr-2.svg" />
                  </div>
                  <h4>Company Incorporation</h4>
                  <div className="based-accounting-p">
                    <p>
                      Our company incorporation services make it easy to set up
                      a new business in Singapore. We handle everything from
                      name reservation and incorporation documentation to
                      obtaining necessary licenses and permits.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/sing-appr-3.svg" />
                  </div>
                  <h4>Accounting and Bookkeeping</h4>
                  <div className="based-accounting-p">
                    <p>
                      We offer full-service accounting and bookkeeping solutions
                      to keep your financial records accurate and up-to-date.
                      Our services include bookkeeping, financial statement
                      preparation, and management reporting.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/sing-appr-4.svg" />
                  </div>
                  <h4>Payroll Services</h4>
                  <div className="based-accounting-p">
                    <p>
                      Our payroll services ensure that your employees are paid
                      accurately and on time. We handle payroll calculations,
                      statutory deductions, and CPF contributions, ensuring
                      compliance with local regulations.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/sing-appr-5.svg" />
                  </div>
                  <h4>Share Registry Services</h4>
                  <div className="based-accounting-p">
                    <p>
                      We provide share registry services, including the
                      maintenance of share registers, processing share
                      transfers, and managing shareholder communications.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/sing-appr-6.svg" />
                  </div>
                  <h4>Employee Stock Ownership Plan (ESOP)</h4>
                  <div className="based-accounting-p">
                    <p>
                      We assist in the design, implementation, and
                      administration of Employee Stock Ownership Plans, helping
                      you incentivize and retain key employees.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/sing-appr-7.svg" />
                  </div>
                  <h4>Tax Advisory and Filing</h4>
                  <div className="based-accounting-p">
                    <p>
                      Our tax advisory and filing services help you navigate
                      Singapore’s tax landscape. We provide corporate tax
                      planning, GST filing, and ensure compliance with all tax
                      regulations.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/sing-appr-8.svg" />
                  </div>
                  <h4>International Accounting and Tax Services</h4>
                  <div className="based-accounting-p">
                    <p>
                      We offer international accounting and tax services to
                      support your global operations. Our services include
                      cross-border tax planning, transfer pricing, and
                      compliance with international tax regulations.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/sing-appr-9.svg" />
                  </div>
                  <h4>Employee Pass and Tax Residency Applications</h4>
                  <div className="based-accounting-p">
                    <p>
                      We assist with applications for Employment Passes,
                      Dependant’s Passes, and Permanent Residency, ensuring a
                      smooth process for your expatriate employees.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/sing-appr-10.svg" />
                  </div>
                  <h4>XBRL Conversion and Filing</h4>
                  <div className="based-accounting-p">
                    <p>
                      We provide XBRL conversion and filing services, ensuring
                      your financial statements meet the requirements of the
                      Accounting and Corporate Regulatory Authority (ACRA).
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/sing-appr-11.svg" />
                  </div>
                  <h4>Fund Administration Services</h4>
                  <div className="based-accounting-p">
                    <p>
                      Our fund administration services cover the full spectrum
                      of fund management, including NAV calculation, investor
                      reporting, and compliance monitoring.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/sing-appr-12.svg" />
                  </div>
                  <h4>VCC Solutions</h4>
                  <div className="based-accounting-p">
                    <p>
                      We offer Variable Capital Company (VCC) solutions,
                      providing fund managers with a flexible and efficient
                      structure for collective investment schemes.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/sing-appr-13.svg" />
                  </div>
                  <h4>Fund Incorporation and Formation</h4>
                  <div className="based-accounting-p">
                    <p>
                      We assist in the incorporation and formation of various
                      fund structures, ensuring compliance with regulatory
                      requirements and providing ongoing administrative support.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/sing-appr-14.svg" />
                  </div>
                  <h4>Transfer Agency / CRS and FATCA / AML Compliance</h4>
                  <div className="based-accounting-p">
                    <p>
                      Our transfer agency services include investor onboarding,
                      processing transactions, and maintaining shareholder
                      records. We also ensure compliance with CRS, FATCA, and
                      AML regulations.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/sing-appr-15.svg" />
                  </div>
                  <h4>Consolidated Reporting for Funds and Family Offices</h4>
                  <div className="based-accounting-p">
                    <p>
                      We provide consolidated reporting services, delivering
                      comprehensive financial reports that cover all aspects of
                      your funds and family office operations.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="our-apporach-bg-top">
        <img alt="" src="assets/img/consequences-top-shade.png" />
      </div>
      <div className="benefit-solutions taking-action-slot">
        <div className="container">
          <div className="row text-center">
            <div className="col-md-12 our-approach-content">
              <h2>Why Choose kimbocorp?</h2>
              <p>By choosing Kimbocorp, enterprises can</p>
            </div>
          </div>
          <div className="row taking-action-row">
            <div className="col-md-4 p-4">
              <div className="taking-action">
                <div className="taking-action-icon">
                  <img alt="" src="assets/img/sing-why-1.svg" />
                </div>
                <h4>Service Clients Better</h4>
                <div className="taking-action-p">
                  <p>
                    Improve your ability to service clients with comprehensive
                    governance and stakeholder management tools.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 p-4">
              <div className="taking-action">
                <div className="taking-action-icon">
                  <img alt="" src="assets/img/sing-why-2.svg" />
                </div>
                <h4>Enhance Governance</h4>
                <div className="taking-action-p">
                  <p>
                    Implement robust governance practices that ensure compliance
                    and accountability.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 p-4">
              <div className="taking-action">
                <div className="taking-action-icon">
                  <img alt="" src="assets/img/sing-why-3.svg" />
                </div>
                <h4>Data-Driven Approach</h4>
                <div className="taking-action-p">
                  <p>
                    Utilize data to understand client needs and influence
                    decision-making processes.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 p-4">
              <div className="taking-action">
                <div className="taking-action-icon">
                  <img alt="" src="assets/img/sing-why-4.svg" />
                </div>
                <h4>Engage Stakeholders</h4>
                <div className="taking-action-p">
                  <p>
                    Effectively engage and appoint stakeholders, providing them
                    with the necessary guidance and documentation.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 p-4">
              <div className="taking-action">
                <div className="taking-action-icon">
                  <img alt="" src="assets/img/sing-why-5.svg" />
                </div>
                <h4>Improve User Experience</h4>
                <div className="taking-action-p">
                  <p>
                    Deliver a seamless user experience that enhances client
                    satisfaction.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 p-4">
              <div className="taking-action">
                <div className="taking-action-icon">
                  <img alt="" src="assets/img/sing-why-6.svg" />
                </div>
                <h4>Leverage Efficient Capabilities</h4>
                <div className="taking-action-p">
                  <p>
                    Connect to the efficient capabilities that Singapore offers,
                    from banking to regulatory compliance.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-12 p-4">
              <div className="taking-action">
                <div className="taking-action-icon">
                  <img alt="" src="assets/img/sing-why-7.svg" />
                </div>
                <h4>Unlimited Goodwill</h4>
                <div className="taking-action-p">
                  <p>
                    We provide unlimited goodwill based on your immediate
                    business objectives. For example, if you are expanding to a
                    new country and need to connect with stakeholders in that
                    country, we will provide suggested profiles for your
                    connections. This goodwill provision is on a case-by-case
                    basis and needs to be mutually understood.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="our-apporach-bg-top">
        <img alt="" src="assets/img/consequences-bottom-shade.png" />
      </div>

      <div className="success-stories">
        <div className="success-stories-holder">
          <div className="success-stories-content">
            <h2>Success Stories</h2>
            <p>What our client say about our services</p>
          </div>
        </div>

        <div className="swiper-container success-stories-carousel">
          <SwiperContainer />
        </div>
      </div>

      <RequestADemo />
      <NewsLetter />
    </div>
  );
}
