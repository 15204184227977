import React from "react";
import RequestADemo from "../components/RequestADemo";
import NewsLetter from "../components/NewsLetter";
import SwiperContainer from "../components/SwiperContainer";
import HeaderBanner from "../components/HeaderBanner";

export default function HybridBusiness() {
  return (
    <div>
      <div className="banner">
        <HeaderBanner
          headerText="Comprehensive Solutions for Active Operations Businesses"
          subHeaderText="Integrate and Manage Your Active operations and investment activities Seamlessly"
          desc="At kimbocorp, we specialize in building and supporting businesses that combine active operations with investment holding activities. Whether you’re a wholesale trading company looking to invest in other businesses or a consulting firm wanting to hold shares in startups, our ready-made company plans provide all the necessary services to ensure seamless operations and investment management from Singapore."
          img={"assets/img/hybrid-business-banner.png"}
          isDemoHeader={2}
        />
      </div>
      <div className="our-apporach-bg-top">
        <img alt="" src="assets/img/our-approach-top-bg-top.png" />
      </div>
      <div className="our-approach">
        <div className="container">
          <div className="our-approach-inner">
            <div className="row text-center">
              <div className="col-md-12 our-approach-content">
                <h2>Our Approach</h2>
                <p>Our #1 priority is your business</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/ab1.svg" />
                  </div>
                  <h4>Comprehensive Company Formation</h4>
                  <div className="based-accounting-p">
                    <p>
                      We provide fully-formed, ready-made companies tailored to
                      your specific business needs. Each company is meticulously
                      prepared to meet all regulatory and operational
                      requirements.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/ab2.svg" />
                  </div>
                  <h4>Bank Accounts and Payment Gateways</h4>
                  <div className="based-accounting-p">
                    <p>
                      We assist in setting up robust bank accounts and payment
                      gateways, providing seamless financial operations for your
                      business activities.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/ab3.svg" />
                  </div>
                  <h4>Accounting and Bookkeeping</h4>
                  <div className="based-accounting-p">
                    <p>
                      Our comprehensive accounting and bookkeeping services
                      ensure accurate financial reporting and compliance with
                      relevant standards, covering everything from bookkeeping
                      to financial statements and tax filings.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/ab4.svg" />
                  </div>
                  <h4>Statutory and Regulatory Compliance</h4>
                  <div className="based-accounting-p">
                    <p>
                      Navigating the complexities of statutory and regulatory
                      compliance is simplified with our expert support. We
                      ensure your business meets all necessary legal
                      requirements, including ongoing compliance and governance.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/ab5.svg" />
                  </div>
                  <h4>Secretarial and Director Appointments</h4>
                  <div className="based-accounting-p">
                    <p>
                      We provide secretarial and director appointments to ensure
                      your company’s corporate governance is managed effectively
                      and in accordance with Singaporean regulations.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/ab6.svg" />
                  </div>
                  <h4>Suggested Customers and Business Opportunities</h4>
                  <div className="based-accounting-p">
                    <p>
                      Leveraging our extensive network, we offer suggested
                      customer profiles and business opportunities to help your
                      business grow and thrive.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="our-apporach-bg-top div-img-block">
        <img alt="" src="assets/img/our-approach-top-bg-bottom.png" />
      </div>

      <div className="why-choose active-business">
        <div className="container">
          <div className="why-choose-inner">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="benefit-sol-content-img">
                  <img alt="" src="assets/img/hybrid-business-why-choose.png" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="why-choose-us-block">
                  <h2>Why choose kimbocorp?</h2>
                  <p>
                    Combining active operations with investment holding
                    activities can be complex, requiring a holistic and
                    integrated approach. At kimbocorp, we provide everything you
                    need in one package, eliminating the need for piece-meal
                    services and ensuring your business runs smoothly and
                    efficiently.
                  </p>
                  <div className="banner-button">
                    <button type="button" className="secondary-button">
                      Know more
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="our-apporach-bg-top">
        <img alt="" src="assets/img/consequences-top-shade.png" />
      </div>
      <div className="benefit-solutions taking-action-slot">
        <div className="container">
          <div className="row text-center">
            <div className="col-md-12 our-approach-content">
              <h2>Consequences of Not Taking Action</h2>
              <p>Without our comprehensive solutions, you could face</p>
            </div>
          </div>
          <div className="row taking-action-row">
            <div className="col-md-4 p-4">
              <div className="taking-action">
                <div className="taking-action-icon">
                  <img alt="" src="assets/img/ta-icon-1.svg" />
                </div>
                <h4>Increased Complexity</h4>
                <div className="taking-action-p">
                  <p>
                    Managing multiple service providers can lead to
                    inefficiencies and increased complexity.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 p-4">
              <div className="taking-action">
                <div className="taking-action-icon">
                  <img alt="" src="assets/img/ta-icon-2.svg" />
                </div>
                <h4>Regulatory Risks</h4>
                <div className="taking-action-p">
                  <p>
                    Inadequate compliance and licensing can result in legal
                    issues and financial penalties.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 p-4">
              <div className="taking-action">
                <div className="taking-action-icon">
                  <img alt="" src="assets/img/ta-icon-3.svg" />
                </div>
                <h4>Operational Delays</h4>
                <div className="taking-action-p">
                  <p>
                    Piecemeal services can cause delays in setting up and
                    running your business.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 p-4">
              <div className="taking-action">
                <div className="taking-action-icon">
                  <img alt="" src="assets/img/ta-icon-4.svg" />
                </div>
                <h4>Losses Due to Volatile Local Currencies</h4>
                <div className="taking-action-p">
                  <p>
                    Exposure to unstable currencies can lead to significant
                    financial losses.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 p-4">
              <div className="taking-action">
                <div className="taking-action-icon">
                  <img alt="" src="assets/img/ta-icon-5.svg" />
                </div>
                <h4>Additional Liabilities</h4>
                <div className="taking-action-p">
                  <p>
                    Providers who only manage their own scope can miss the
                    holistic picture, leading to increased liabilities.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 p-4">
              <div className="taking-action">
                <div className="taking-action-icon">
                  <img alt="" src="assets/img/ta-icon-6.svg" />
                </div>
                <h4>Unstable Law-Making Environment</h4>
                <div className="taking-action-p">
                  <p>
                    Operating in regions with unpredictable regulatory changes
                    can create legal and operational risks.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 p-4">
              <div className="taking-action">
                <div className="taking-action-icon">
                  <img alt="" src="assets/img/ta-icon-7.svg" />
                </div>
                <h4>High Foreign Exchange Conversion Rates</h4>
                <div className="taking-action-p">
                  <p>Unfavorable exchange rates can erode your profits.</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 p-4">
              <div className="taking-action">
                <div className="taking-action-icon">
                  <img alt="" src="assets/img/ta-icon-8.svg" />
                </div>
                <h4>High Capital Gains, Corporate and Sales Taxes</h4>
                <div className="taking-action-p">
                  <p>
                    Operating in high-tax jurisdictions can reduce your overall
                    profitability.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 p-4">
              <div className="taking-action">
                <div className="taking-action-icon">
                  <img alt="" src="assets/img/ta-icon-9.svg" />
                </div>
                <h4>Foreign Currency Bank Clearance Difficulties</h4>
                <div className="taking-action-p">
                  <p>
                    {" "}
                    Delays and complications in clearing foreign currency
                    transactions can disrupt your operations.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="our-apporach-bg-top">
        <img alt="" src="assets/img/consequences-bottom-shade-only.png" />
      </div>

      <div className="benefit-solutions invtment-business--benefit-soutions">
        <div className="container">
          <div className="benefit-solutions-inner">
            <div className="row text-center">
              <div className="col-md-12 our-approach-content">
                <h2>Comparing with Piece-Meal Providers</h2>
                <p>Our #1 priority is your business</p>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="benefit-sol-content">
                  <h2>kimbocorp’s integrated approach:</h2>
                  <ul>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/hy-app-icon-1.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>Seamless Integration</h5>
                        <p>
                          All services are provided under one roof, ensuring
                          seamless integration and efficient management.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/hy-app-icon-2.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>Comprehensive Support</h5>
                        <p>
                          From company formation to banking, accounting, and
                          compliance, we cover all aspects of your active
                          operations business.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/hy-app-icon-3.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>Expert Personnel</h5>
                        <p>
                          We provide experienced secretarial and director
                          appointments, ensuring robust governance and
                          compliance.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div id="desktop" className="col-md-6">
                <div className="benefit-sol-content-img">
                  <span className="">
                    <img alt="" src="assets/img/hy-app-image-1.png" className="" />
                  </span>
                </div>
              </div>
            </div>
            <div className="row align-items-center flex-row-reverse margin-top-consequences">
              <div className="col-md-6">
                <div className="benefit-sol-content">
                  <h2>Piece-Meal Providers:</h2>
                  <ul>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/hy-app-icon-4.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>Fragmented Services</h5>
                        <p>
                          Services are often fragmented, leading to coordination
                          challenges and inefficiencies.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/hy-app-icon-5.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>Inconsistent Quality</h5>
                        <p>
                          Varying quality of services from different providers
                          can result in compliance and operational risks.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/hy-app-icon-6.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>Operational Delays</h5>
                        <p>
                          Managing multiple providers can cause delays and
                          increase the time required to set up and run your
                          business.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/hy-app-icon-7.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>Limited Scope</h5>
                        <p>
                          Providers who only manage their own scope can miss the
                          holistic picture, leading to additional liabilities
                          and risks.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div id="desktop" className="col-md-6">
                <div className="benefit-sol-content-img">
                  <span className="">
                    <img alt="" src="assets/img/hy-app-image-2.png" className="" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="our-apporach-bg-top">
        <img alt="" src="assets/img/success-story-top-shade.png" />
      </div>
      <div className="success-stories">
        <div className="success-stories-holder">
          <div className="success-stories-content">
            <h2>Success Stories</h2>
            <p>What our client say about our services</p>
          </div>
        </div>

        <div className="swiper-container success-stories-carousel">
          <SwiperContainer />
        </div>
      </div>

      <RequestADemo />
      <NewsLetter />
    </div>
  );
}
