import React from "react";
import SwiperContainer from "../components/SwiperContainer";
import RequestADemo from "../components/RequestADemo";
import NewsLetter from "../components/NewsLetter";
import HeaderBanner from "../components/HeaderBanner";

export default function InvestmentBusiness() {
  return (
    <div>
      <div className="banner">
        <HeaderBanner
          headerText="Comprehensive solutions for investment holding companies"
          subHeaderText="Everything you need to successfully manage SPVs, SFOs, MFOs, GPs, LPs, and more"
          desc="At kimbocorp, we specialize in building and supporting investment holding companies, including SPVs (Special Purpose Vehicles), SFOs (Single Family Offices), MFOs (Multi-Family Offices), Segregated portfolio companies such as VCCs, GPs (General Partners), LPs (Limited Partners), and other investment structures. Our ready-made company plans encompass all necessary services, from fund administration and accounting to AML/CFT reporting and banking solutions, ensuring you have everything you need to run your investment holding business seamlessly."
          img={"assets/img/investment-business-img.png"}
          isDemoHeader={2}
        />
      </div>
      <div className="our-apporach-bg-top">
        <img alt="" src="assets/img/consequences-top-shade.png" />
      </div>
      <div className="our-approach governance">
        <div className="container">
          <div className="our-approach-inner">
            <div className="row text-center">
              <div className="col-md-12 our-approach-content">
                <h2>Our Approach</h2>
                <p>Our #1 priority is your business</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/boabc-icon6.svg" />
                  </div>
                  <h4>Stakeholder management</h4>
                  <div className="based-accounting-p">
                    <p>
                      We provide targeted stakeholder suggestions and all
                      features, such as invitations, engagements and customer
                      relationship management tools, so that you can continue to
                      engage with stakeholders and recognise them.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/boabc-icon7.svg" />
                  </div>
                  <h4>Contract administration</h4>
                  <div className="based-accounting-p">
                    <p>
                      We offer tailored essential contracts, agreements and fund
                      documentation to ensure your investment holding company is
                      equipped with the necessary legal documents.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/boabc-icon8.svg" />
                  </div>
                  <h4>Escrow services</h4>
                  <div className="based-accounting-p">
                    <p>
                      Our escrow services provide a secure and reliable way to
                      hold funds during transactions, ensuring that all parties
                      meet their obligations before the release of funds.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/boabc-icon9.svg" />
                  </div>
                  <h4>Licensing and regulatory governance</h4>
                  <div className="based-accounting-p">
                    <p>
                      Navigating the complexities of licensing and regulatory
                      compliance is simplified with our resident director and
                      qualified individual support. We ensure your investment
                      holding company meets all necessary legal requirements,
                      including AML/CFT reporting, and maintains ongoing
                      compliance.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="based-accounting-block">
                  <div className="based-accounting-icon">
                    <img alt="" src="assets/img/boabc-icon10.svg" />
                  </div>
                  <h4>Ratification and Formalization</h4>
                  <div className="based-accounting-p">
                    <p>
                      Once all elements are in place, we assist in the
                      ratification and formalization of all agreements and
                      documents, ensuring everything is legally binding and
                      formally recognized.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="our-apporach-bg-top div-img-block">
        <img alt="" src="assets/img/consequences-bottom-shade-only.png" />
      </div>

      <div className="why-choose">
        <div className="container">
          <div className="why-choose-inner">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="benefit-sol-content-img">
                  <img
                    alt=""
                    src="assets/img/investment-business-why-choose.png"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="why-choose-us-block">
                  <h2>Why choose kimbocorp?</h2>
                  <p>
                    In the complex world of investment holding companies, having
                    a comprehensive and integrated solution is crucial. At
                    Kimbocorp, we provide everything you need in one package,
                    eliminating the need for piece-meal services and ensuring
                    your business runs smoothly and efficiently.
                  </p>
                  <div className="banner-button">
                    <button type="button" className="secondary-button">
                      Know more
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="our-apporach-bg-top">
        <img alt="" src="assets/img/our-approach-top-bg-top.png" />
      </div>
      <div className="benefit-solutions accounting">
        <div className="container">
          <div className="benefit-solutions-inner">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="benefit-sol-content">
                  <h2>Consequences of Not Taking Action</h2>
                  <p>Without our comprehensive solutions, you could face:</p>

                  <ul>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/ib-1.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>Increased Complexity</h5>
                        <p>
                          Managing multiple service providers can lead to
                          inefficiencies and increased complexity.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/ib-2.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>Regulatory Risks</h5>
                        <p>
                          Inadequate compliance and licensing can result in
                          legal issues and financial penalties.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/ib-3.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>Operational Delays</h5>
                        <p>
                          Piecemeal services can cause delays in setting up and
                          running your investment holding company.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/ib-4.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>Financial Inaccuracies</h5>
                        <p>
                          Inconsistent accounting and fund administration can
                          lead to financial discrepancies and reporting errors.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div id="desktop" className="col-md-6">
                <div className="benefit-sol-content-img">
                  <span className="">
                    <img alt="" src="assets/img/ib-talking.png" className="" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="our-apporach-bg-top">
        <img alt="" src="assets/img/our-approach-top-bg-bottom.png" />
      </div>

      <div className="benefit-solutions invtment-business--benefit-soutions">
        <div className="container">
          <div className="benefit-solutions-inner">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="benefit-sol-content">
                  <h2>kimbocorp’s integrated approach:</h2>
                  <ul>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/iv-icon-1.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>Seamless Integration</h5>
                        <p>
                          All services are provided under one roof, ensuring
                          seamless integration and efficient management.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/iv-icon-2.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>Comprehensive Support</h5>
                        <p>
                          From company formation to fund administration,
                          accounting, and compliance, we cover all aspects of
                          your investment holding company.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/iv-icon-3.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>Relevant personal</h5>
                        <p>
                          We provide suggested profiles and resident directors
                          who understand your specific industry.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div id="desktop" className="col-md-6">
                <div className="benefit-sol-content-img">
                  <span className="styled-image">
                    <img
                      alt=""
                      src="assets/img/investment-business-benefit-1.png"
                      className="styled-image"
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="row align-items-center flex-row-reverse margin-top-consequences">
              <div className="col-md-6">
                <div className="benefit-sol-content">
                  <h2>Piece-Meal Providers:</h2>
                  <ul>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/iv-icon-4.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>Fragmented Services</h5>
                        <p>
                          Services are often fragmented, leading to coordination
                          challenges and inefficiencies.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/iv-icon-5.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>Inconsistent Quality</h5>
                        <p>
                          Varying quality of services from different providers
                          can result in compliance and operational risks.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="benefit-icons">
                        <img alt="" src="assets/img/iv-icon-6.svg" />
                      </div>
                      <div className="benefit-content">
                        <h5>Operational Delays</h5>
                        <p>
                          Managing multiple providers can cause delays and
                          increase the time required to set up and run your
                          company.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div id="desktop" className="col-md-6">
                <div className="benefit-sol-content-img">
                  <span className="styled-image">
                    <img
                      alt=""
                      src="assets/img/investment-business-benefit-2.png"
                      className="styled-image"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="our-apporach-bg-top">
        <img alt="" src="assets/img/success-story-top-shade.png" />
      </div>

      <div className="success-stories">
        <div className="success-stories-holder">
          <div className="success-stories-content">
            <h2>Success Stories</h2>
            <p>What our client say about our services</p>
          </div>
        </div>

        <div className="swiper-container success-stories-carousel">
          <SwiperContainer />
        </div>
      </div>

      <RequestADemo />
      <NewsLetter />
    </div>
  );
}
