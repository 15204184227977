import React from "react";

export default function NewsLetter() {
  return (
    <div>
      <div className="newsletter-bg-image">
        <img alt="" src="assets/img/newsletter-bg.png" />
      </div>
      <div className="newsletter">
        <div className="container">
          <div className="newsletter-inner">
            <div className="row align-items-center">
              <div className="col-md-5 col-lg-6">
                <div className="newsletter-content">
                  <h3>Subscribe Newsletters</h3>
                  <p>
                    Be the first to know about sales & offers, new arrivals,
                    latest trends, events, brand news, and more.
                  </p>
                </div>
              </div>
              <div className="col-md-7 col-lg-6">
                <form className="newsletter-form">
                  <input type="email" placeholder="Enter your email" />
                  <button type="submit">Subscribe Now</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
