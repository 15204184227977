import React from "react";

export default function TermsOfAdvisory() {
  return (
    <div>
      <div className="our-approach transparent-bg privacyPage">
        <div className="container">
          <div className="our-approach-inner">
            <div className="row text-center">
              <div className="col-md-12 our-approach-content">
                <h2>Terms of Advisory Plan</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <p>
                  This is a contract between us, Kimbocorp Pte Ltd.
                  ("Kimbocorp") and you, (the "primary member"), for the plans
                  and/or services ("services") described below. In addition to
                  the Kimbocorp Pte Ltd. ("Kimbocorp") Terms of Use, that
                  governs your use of the Kimbocorp website, this contract (the
                  "Agreement") constitutes a legal agreement between you and
                  Kimbocorp detailing the terms of your use of paid plans and/or
                  service. This Agreement is effective as of the date you accept
                  its terms by purchasing a company plan or any paid service
                  provided by Kimbocorp. If you paid for a service from
                  Kimbocorp, you accept these terms, conditions, limitations,
                  and requirements. Please read this Agreement carefully.
                </p>
                <ul>
                  <li>
                    1. Orders for company -only services and plans. An order for
                    a company service or planrefers to provision of a plan or
                    service to a primary member or his/her associates would
                    benefit from or own, such as a bullion holding company
                    incorporated in Singapore. The rates for such a plan would
                    have been discussed or communicated with the primary member,
                    via any of Kimbocorp’s website or digital platforms, or
                    through other correspondences and meetings, and these would
                    be set out from time to time and be communicated to the
                    primary member.
                  </li>
                  <ul className="alphaList">
                    <li>
                      1.1. Payment The payment for an order must be sent
                      (initiated) within one banking day from the order
                      placement for all payment methods except for online
                      Credit/Debit card payments where the payment must be made
                      within 24 hours and except for cryptocurrencies where the
                      payment must be sent (initiated) within 20 minutes. If an
                      order is placed during a non-banking day, the payment must
                      be initiated the next banking day. In the case of a bank
                      transfer, the Transaction Date/Time stamp, which is
                      obtained when placing the order, should, if possible, be
                      marked in the message field of the bank transfer form used
                      for transmitting payment to the recipient/beneficiary,
                      Kimbocorp. For e.g, “Transaction initiated DD/MM/YYYY
                      HH:MM SGT”.
                    </li>
                    <li>
                      1.2. Payment methods When purchasing from Kimbocorp’s
                      location in Singapore, only the following payment methods
                      are used: Paynow, FAST, Telegraphic Transfer, Credit and
                      Debit card payment and cryptocurrency. Kimbocorp does not
                      accept letter of credits, banker’s guarantees, e-checks
                      and Billpay for its Singapore location.
                    </li>
                    <li>
                      1.3. Funds The funds must reach Kimbocorp within four (4)
                      banking days of placing the order. Kimbocorp reserves the
                      right to demand payment sooner than the above-mentioned
                      time frame. In such cases, the primary member will be
                      contacted after placing the order. All bank charges are
                      fully borne by the primary member.
                    </li>
                    <li>
                      1.4. Incomplete transfer of funds If the payment is not
                      initiated or completed within the stipulated timeframe,
                      Kimbocorp reserves the right to cancel the order whereupon
                      the primary member warrants to immediately pay Kimbocorp
                      an administrative fee of S$60. In addition, the primary
                      member warrants to pay Kimbocorp any costs incurred when
                      processing the aborted order including but not limited to
                      costs for initial work done, sending out reminders,
                      collection fees, legal fees and court fees. The invoice
                      for an aborted order, if not paid, may be handed over to a
                      collection agency or to a court for collection. The
                      primary member agrees and understands that if the primary
                      member has unsettled invoices, such as outstanding
                      invoices for products or cancellation fees, any funds paid
                      to Kimbocorp will be allocated towards the unsettled
                      outstanding debts first starting with the earliest
                      outstanding debt. As company plans and services are
                      binding without exception, Kimbocorp reserves the right to
                      use all and/or any of the funds paid in by the primary
                      member towards fulfilling previous orders regardless of
                      whether the transaction status has been changed to
                      “aborted” or not or towards the administrative fees
                      charged or towards the charges for any negative difference
                      in price for the products placed under the aborted
                      transactions. If only partial funds for an order have been
                      received, Kimbocorp will remind the primary member, or
                      remind any of the primary member’s associates, to settle
                      the outstanding owed amount. If full payment has not been
                      received within twenty-eight (28) days of the order
                      placement, Kimbocorp reserves the right to abort the
                      transaction, and the primary member warrants to pay
                      Kimbocorp an administrative fee of S$60 and credit any
                      remainder to the primary member’s Kimbocorp cash account.
                    </li>
                    <li>
                      1.5. Full responsibility The primary member bears full
                      responsibility for Crypto transfer. The primary member
                      acknowledges that the primary member bears the full
                      responsibility to send funds, including cryptocurrency
                      funds, to the correct account number or cryptocurrency
                      address as designated by Kimbocorp on the order
                      confirmation. For Bitcoin Cash, the primary member
                      acknowledges that Kimbocorp only accepts and sends coins
                      from the Node chain and does not accept or send coins from
                      the Bitcoin ABC chain or Bitcoin Cash SV chain.
                    </li>
                    <li>
                      1.6. Right to return funds The primary member acknowledges
                      that Kimbocorp reserves the right to return the funds, if
                      possible, and/or terminate the order if there are doubts
                      over the owner, identity or origins of the funds.
                      Kimbocorp reserves the right to conduct Customer Due
                      Diligence (CDD) or Enhanced Customer Due Diligence (ECDD)
                      on the primary member in accordance with the regulatory
                      regime for precious metals dealers in Singapore. The CDD
                      or ECDD may include requests for information verifying the
                      primary member’s identity by obtaining information about
                      NRIC number, passport number, occupation, nationality,
                      address, date of birth and contact number. The primary
                      member may be required to show proof of identifying
                      information which Kimbocorp may copy. The CDD or ECDD may
                      also include requests for information to establish the
                      beneficial owner of funds or metals, questions and
                      verification about income level, source of wealth and
                      source of funds and whether the primary member is indeed
                      the individual, entity or representative of the entity
                      behind the purchase. Where Kimbocorp has not received
                      adequate information for the conduct of CDD or ECDD as the
                      case may be, or deems that it is unable to complete CDD or
                      ECDD satisfactorily with the information made available to
                      Kimbocorp, Kimbocorp reserves the right to cancel the
                      order, place the order on hold, or terminate any and all
                      transactions with the primary whereupon the primary member
                      shall release Kimbocorp from all liabilities flowing from
                      any consequential refusal to, delay in, or cancellation of
                      transaction with the primary member. Kimbocorp will not be
                      responsible for any losses due to insufficient/unclear
                      transaction or identification details provided by the
                      primary member. The primary member acknowledges that any
                      unclaimed funds of unknown origin accrue to Kimbocorp
                      following a period of five years from the funds received
                      by Kimbocorp.
                    </li>
                    <li>
                      1.7. Right to charge for paid-up capital, withholding
                      taxes and corporate income taxes liable to the company.
                      The primary member acknowledges that Kimbocorp reserves
                      the right to invoice the company, primary member and/or
                      primary member’s associates for paid-up capital,
                      withholding taxes and/or corporate income taxes or other
                      eligible taxes and fees. The primary member may discuss
                      each transaction with Kimbocorp to select the payment
                      method and confirm the actual payer, the amount and the
                      reasons for the amount.
                    </li>
                    <li>
                      1.8. Default of a company service or plan A default
                      situation to a company service or plan results in undue
                      liabilities to Kimbocorp’s employees and authorised
                      representatives. If full payment has not been received
                      within twenty-eight (28) days of the invoice issued to the
                      primary member for continuation of the service or the
                      plan, Kimbocorp reserves the right to:
                    </li>
                    <ul className="alphaList">
                      <li>
                        TO APPLY FOR A STRIKING OFF OR WINDING DOWN OF THE
                        COMPANY MANAGED BY KIMBOCORP;
                      </li>
                      <li>
                        THE PRIMARY MEMBER MAY HAVE HIS/HER DIRECTORSHIP REMOVED
                        BY RESIGNATION AND HIS/HER COMPANY STRUCK OFF THE
                        CORPORATE REGISTRAR
                      </li>
                      <li>
                        SUCH ACTIONS ARE CARRIED OUT UNDER THE CAPACITY OF THE
                        REGISTERED FILING AGENT WHO KIMBOCORP ENGAGES WITH
                      </li>
                      <li>
                        KIMBOCORP, ITS EMPLOYEES, AGENTS, ASSOCIATES AND
                        STAKEHOLDERS, WOULD BE FULLY INDEMNIFIED AGAINST ALL
                        FINANCIAL LOSSES INCURRED BY THE PRIMARY MEMBER, ITS
                        EMPLOYEES, AGENTS, ASSOCIATES AND STAKEHOLDERS IN SUCH A
                        SITUATION.
                      </li>
                    </ul>
                    <li>
                      1.9. Cash/NETS/Card Payment For orders placed with
                      Kimbocorp Singapore, the primary member may choose to meet
                      Kimbocorp’s authorized representatives to place an order
                      for cash, NETS or card payment or may choose to place an
                      order online with cash, NETS or card payment as payment
                      method. In the case where the primary wishes to meet
                      Kimbocorp’s authorized representative, the primary member
                      must make an appointment via Kimbocorp contact us page,
                      appointment is mandatory.
                    </li>
                    <ul className="alphaList">
                      <li>
                        Kimbocorp reserves the right to conduct CDD or ECDD on a
                        primary member paying cash generally and specifically in
                        a case where the amount is above SGD 20,000 or
                        equivalent in foreign currency. The CDD or ECDD may
                        include requests for information verifying the primary
                        member’s identity by obtaining information about NRIC
                        number, passport number, occupation, nationality,
                        address, date of birth and contact number. The primary
                        member may be required to show proof of identifying
                        information which Kimbocorp may copy. The CDD or ECDD
                        may also include requests for information to establish
                        the beneficial owner of funds or metals, questions and
                        verification about income level, source of wealth and
                        source of funds and whether the primary member is indeed
                        the individual, entity or representative of the entity
                        behind the purchase. If no supporting information is
                        provided, Kimbocorp reserves the right to cancel the
                        order or place the order on hold. If Kimbocorp is unable
                        to obtain the primary member’s identifying information,
                        after requesting for it, the primary member understands
                        that regulations may prohibit Kimbocorp from completing
                        the transaction and that Kimbocorp may have to terminate
                        any other transaction entered into with the primary
                        member. Kimbocorp reserves the right to change the
                        payment method, from cash or card payment to payment via
                        bank account. Kimbocorp will in such a case notify the
                        primary member and may give the primary member a grace
                        period to be able to transfer or deposit the funds to
                        any of Kimbocorp’s bank accounts.
                      </li>
                    </ul>
                    <li>
                      1.10. Cheque Payment For orders placed with Kimbocorp
                      Singapore, a primary member may choose to place an order
                      in the shop or online using cheque as payment option. In
                      the case where cheque is requested as a payment method,
                      the primary member is required to send or hand over the
                      cheque to Kimbocorp within one banking day from the order
                      placement day.
                    </li>
                    <ul className="alphaList">
                      <li>
                        All cheques must clear with the funds irrevocably
                        available in Kimbocorp’s account before any delivery is
                        initiated.
                      </li>
                    </ul>
                    <li>
                      1.11. Cryptocurrency Payments Where a primary member makes
                      a payment to Kimbocorp in cryptocurrency for an order or
                      commits to receiving payment from Kimbocorp in
                      cryptocurrency, the primary member hereby gives consent to
                      Kimbocorp to hedge the transaction on a best-effort basis
                      (referred to as “Leg 1”). In the event that any part of
                      the cryptocurrency or fiat amount needs to be returned due
                      to the termination, cancellation, or modification of an
                      order, or for any other reason, Kimbocorp retains the
                      right to unwind the hedged cryptocurrency or fiat amount
                      from Leg 1 on a best-effort basis (referred to as “Leg
                      2”). The primary acknowledges and agrees to bear any loss
                      that may result from a price fluctuation between Leg 1 and
                      Leg 2.
                    </li>
                  </ul>
                  <li>
                    2. Orders for products or services An order for a product or
                    service refers to provision of a product or service to a
                    primary member or his/her associates would own, such as a
                    1kg gold bullion bar. The rates for such a product would
                    have been discussed or communicated with the primary member,
                    via any of Kimbocorp’s website or digital platforms, or
                    through other correspondences and meetings, and these would
                    be set out from time to time and be communicated to the
                    primary member.
                  </li>
                  <ul className="alphaList">
                    <li>
                      2.1. Payment The payment for an order must be sent
                      (initiated) within one banking day from the order
                      placement for all payment methods except for online
                      Credit/Debit card payments where the payment must be made
                      within 24 hours and except for cryptocurrencies where the
                      payment must be sent (initiated) within 20 minutes. If an
                      order is placed during a non-banking day, the payment must
                      be initiated the next banking day. In the case of a bank
                      transfer, the Transaction Date/Time stamp, which is
                      obtained when placing the order, should, if possible, be
                      marked in the message field of the bank transfer form used
                      for transmitting payment to the recipient/beneficiary,
                      Kimbocorp. For e.g, “Transaction initiated DD/MM/YYYY
                      HH:MM SGT”.
                    </li>
                    <li>
                      2.2. Payment When purchasing from Kimbocorp’s location in
                      Singapore, only the following payment methods are used:
                      Paynow, FAST, Telegraphic Transfer, Credit and Debit card
                      payment and cryptocurrency. Kimbocorp does not accept
                      letter of credits, banker’s guarantees, e-checks and
                      Billpay for its Singapore location.
                    </li>
                    <li>
                      2.3. Funds The funds must reach Kimbocorp within four (4)
                      banking days of placing the order. Kimbocorp reserves the
                      right to demand payment sooner than the above-mentioned
                      time frame. In such cases, the primary member will be
                      contacted after placing the order. All bank charges are
                      fully borne by the primary member.
                    </li>
                    <li>
                      2.4. Incomplete transfer of funds If the payment is not
                      initiated or completed within the stipulated timeframe,
                      Kimbocorp reserves the right to cancel the order whereupon
                      the primary member warrants to immediately pay Kimbocorp
                      an administrative fee of S$60. In addition, the primary
                      member warrants to pay Kimbocorp any negative difference
                      in price for the products ordered from the time the order
                      was placed to the time when the order was canceled, as
                      well as any other costs incurred when processing the
                      aborted order including but not limited to costs for
                      sending out reminders, collection fees, legal fees and
                      court fees. The invoice for an aborted order, if not paid,
                      may be handed over to a collection agency or to a court
                      for collection. The primary member agrees and understands
                      that if the primary member has unsettled invoices, such as
                      outstanding invoices for products or cancellation fees,
                      any funds paid to Kimbocorp will be allocated towards the
                      unsettled outstanding debts first starting with the
                      earliest outstanding debt. As precious metals orders are
                      binding without exception, Kimbocorp reserves the right to
                      use all and/or any of the funds paid in by the primary
                      member towards fulfilling previous orders regardless of
                      whether the transaction status has been changed to
                      “aborted” or not or towards the administrative fees
                      charged or towards the charges for any negative difference
                      in price for the products placed under the aborted
                      transactions. If only partial funds for an order have been
                      received, Kimbocorp will remind the primary member to
                      settle the outstanding owed amount. If full payment has
                      not been received within twenty-eight (28) days of the
                      order placement, Kimbocorp reserves the right to abort the
                      transaction, and the primary member warrants to pay
                      Kimbocorp an administrative fee of S$60 and credit any
                      remainder to the primary member’s Kimbocorp cash account.
                    </li>
                    <li>
                      2.5. Full responsibility The primary member bears full
                      responsibility for Crypto transfer. The primary member
                      acknowledges that the primary member bears the full
                      responsibility to send funds, including cryptocurrency
                      funds, to the correct account number or cryptocurrency
                      address as designated by Kimbocorp on the order
                      confirmation. For Bitcoin Cash, the primary member
                      acknowledges that Kimbocorp only accepts and sends coins
                      from the Node chain and does not accept or send coins from
                      the Bitcoin ABC chain or Bitcoin Cash SV chain.
                    </li>
                    <li>
                      2.6. Right to return funds The primary member acknowledges
                      that Kimbocorp reserves the right to return the funds, if
                      possible, and/or terminate the order if there are doubts
                      over the owner, identity or origins of the funds.
                      Kimbocorp reserves the right to conduct Customer Due
                      Diligence (CDD) or Enhanced Customer Due Diligence (ECDD)
                      on the primary member in accordance with the regulatory
                      regime for precious metals dealers in Singapore. The CDD
                      or ECDD may include requests for information verifying the
                      primary member’s identity by obtaining information about
                      NRIC number, passport number, occupation, nationality,
                      address, date of birth and contact number. The primary
                      member may be required to show proof of identifying
                      information which Kimbocorp may copy. The CDD or ECDD may
                      also include requests for information to establish the
                      beneficial owner of funds or metals, questions and
                      verification about income level, source of wealth and
                      source of funds and whether the primary member is indeed
                      the individual, entity or representative of the entity
                      behind the purchase. Where Kimbocorp has not received
                      adequate information for the conduct of CDD or ECDD as the
                      case may be, or deems that it is unable to complete CDD or
                      ECDD satisfactorily with the information made available to
                      Kimbocorp, Kimbocorp reserves the right to cancel the
                      order, place the order on hold, or terminate any and all
                      transactions with the primary whereupon the primary member
                      shall release Kimbocorp from all liabilities flowing from
                      any consequential refusal to, delay in, or cancellation of
                      transaction with the primary member. Kimbocorp will not be
                      responsible for any losses due to insufficient/unclear
                      transaction or identification details provided by the
                      primary member.
                    </li>
                  </ul>
                  <li>
                    The primary member acknowledges that any unclaimed funds of
                    unknown origin accrue to Kimbocorp following a period of
                    five years from the funds received by Kimbocorp.
                  </li>
                  <ul className="alphaList">
                    <li>
                      2.7. Cash/NETS/Card Payment For orders placed with
                      Kimbocorp Singapore, the primary member may choose to meet
                      Kimbocorp’s authorized representatives to place an order
                      for cash, NETS or card payment or may choose to place an
                      order online with cash, NETS or card payment as payment
                      method. In the case where the primary wishes to meet
                      Kimbocorp’s authorized representative, the primary member
                      must make an appointment via Kimbocorp contact us page,
                      appointment is mandatory.
                    </li>
                  </ul>
                  <p>
                    Kimbocorp reserves the right to conduct CDD or ECDD on a
                    primary member paying cash generally and specifically in a
                    case where the amount is above SGD 20,000 or equivalent in
                    foreign currency. The CDD or ECDD may include requests for
                    information verifying the primary member’s identity by
                    obtaining information about NRIC number, passport number,
                    occupation, nationality, address, date of birth and contact
                    number. The primary member may be required to show proof of
                    identifying information which Kimbocorp may copy. The CDD or
                    ECDD may also include requests for information to establish
                    the beneficial owner of funds or metals, questions and
                    verification about income level, source of wealth and source
                    of funds and whether the primary member is indeed the
                    individual, entity or representative of the entity behind
                    the purchase. If no supporting information is provided,
                    Kimbocorp reserves the right to cancel the order or place
                    the order on hold. If Kimbocorp is unable to obtain the
                    primary member’s identifying information, after requesting
                    for it, the primary member understands that regulations may
                    prohibit Kimbocorp from completing the transaction and that
                    Kimbocorp may have to terminate any other transaction
                    entered into with the primary member.
                  </p>
                  <p>
                    Kimbocorp reserves the right to change the payment method,
                    from cash or card payment to payment via bank account.
                    Kimbocorp will in such a case notify the primary member and
                    may give the primary member a grace period to be able to
                    transfer or deposit the funds to any of Kimbocorp’s bank
                    accounts.
                  </p>
                  <ul className="alphaList">
                    <li>
                      2.8. Cheque Payment For orders placed with Kimbocorp
                      Singapore, a primary member may choose to place an order
                      in the shop or online using cheque as payment option. In
                      the case where cheque is requested as a payment method,
                      the primary member is required to send or hand over the
                      cheque to Kimbocorp within one banking day from the order
                      placement day. All cheques must clear with the funds
                      irrevocably available in Kimbocorp’s account before any
                      delivery is initiated.
                    </li>
                    <li>
                      2.9. Cryptocurrency Payment Where a primary member makes a
                      payment to Kimbocorp in cryptocurrency for an order or
                      commits to receiving payment from Kimbocorp in
                      cryptocurrency, the primary member hereby gives consent to
                      Kimbocorp to hedge the transaction on a best-effort basis
                      (referred to as “Leg 1”). In the event that any part of
                      the cryptocurrency or fiat amount needs to be returned due
                      to the termination, cancellation, or modification of an
                      order, or for any other reason, Kimbocorp retains the
                      right to unwind the hedged cryptocurrency or fiat amount
                      from Leg 1 on a best-effort basis (referred to as “Leg
                      2”). The primary acknowledges and agrees to bear any loss
                      that may result from a price fluctuation between Leg 1 and
                      Leg 2.
                    </li>
                  </ul>
                  <li>
                    3. By agreeing to this terms of advisory, the primary member
                    further acknowledge and agree that: – .
                  </li>
                  <ul className="alphaList">
                    <li>
                      3.1. The provision of any product, plan or service
                      provided by Kimbocorp, may be terminated upon service of 3
                      days’ notice for any reasons or for professional conduct
                      reasons set out in the company act, corporate secretary
                      bill, government statutes and/or constitution of a
                      company, and this includes where the primary member have
                      been in breach of our agreement on the payment of fees
                    </li>
                    <li>
                      3.2. If the Bill of Costs and disbursements of this firm
                      are not paid within 28 days, Kimbocorp reserves the right
                      to withdraw representing the primary member. Upon
                      Kimbocorp discharging themselves from representation,
                      Kimbocorp shall have the right of lien over the monies
                      held and the documents that are in the possession of the
                      primary member and/or company managed by Kimbocorp until
                      such time when the professional costs and disbursements of
                      the firm are fully paid.
                    </li>
                    <li>
                      3.3. Kimbocorp shall be entitled to charge interest of 1%
                      per month on any outstanding invoices from the date of
                      invoice.
                    </li>
                    <li>
                      3.4. That any monies paid to any company account managed
                      by Kimbocorp shall be used by Kimbocorp to setoff against
                      any outstanding invoiced amount owing to Kimbocorp without
                      prior notice.
                    </li>
                    <li>
                      3.5. For services or plans of a recurring nature,
                      Kimbocorp reserves the right to automatically invoice or
                      automatically debit the amount. Kimbocorp will send a
                      reminder email to the email address of record to the
                      primary member approximately one month before the service
                      or plan expiry date. The primary member acknowledges and
                      agrees that this notice is provided as a courtesy only,
                      and Kimbocorp is not obligated or required to provide such
                      notice. The primary member acknowledges and agrees that
                      (i) failure to read, (ii) inability to receive, or (iii)
                      the failure of Kimbocorp to send the email does not create
                      any liability on the part of Kimbocorp or any third-party
                      service provider.
                    </li>
                    <li>
                      3.6. In the event of any ambiguity or conflict between
                      other languages or translations, the English version of
                      these terms is authoritative and controls.
                    </li>
                    <li>
                      3.7. That Kimbocorp reserves the right to change these
                      general practices and limits at any time, in its sole
                      discretion, with or without notice.
                    </li>
                  </ul>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
