import React from "react";

export default function PrivacyPolicy() {
  return (
    <div>
      <div className="our-approach transparent-bg privacyPage">
        <div className="container">
          <div className="our-approach-inner">
            <div className="row text-center">
              <div className="col-md-12 our-approach-content">
                <h2>Privacy Policy</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <p>
                  Kimbocorp Pte. Ltd. (collectively “Kimbocorp”, “us”, “we” or
                  “our”) are committed to protecting the rights and privacy of
                  individuals in accordance with the Singapore Personal Data
                  Protection Act 2012 (the “PDPA”).
                </p>
                <p>
                  This policy (“Privacy Policy”) describes how we may collect,
                  use, disclose, process, and manage your Personal Data during
                  our business, including our websites, digital services, and
                  products such as mobile applications, technical platforms, and
                  other online or offline offerings otherwise indicated. This
                  Privacy Policy applies to any individual’s Personal Data which
                  is in our possession or under our control.
                </p>
                <p>
                  By interacting with us and submitting your data to us, you
                  agree and consent to Kimbocorp as well as our respective
                  agents, authorised service providers and relevant third
                  parties to processing your Personal Data in the manner set
                  forth in this Privacy Policy. If you have any queries related
                  to this Privacy Policy, please contact the persons identified
                  at the end of this Privacy Policy.
                </p>
                <p>
                  This Privacy Policy supplements but does not supersede nor
                  replace any other consents which you may have previously
                  provided to us nor does it affect any rights that we may have
                  at law in connection with the collection, use and/or
                  disclosure of your Personal Data. We may from time to time
                  update this Privacy Policy to ensure that this Privacy Policy
                  is consistent with our future developments, industry trends
                  and/or any changes in legal or regulatory requirements. As and
                  when the contents of this Privacy Policy are updated or
                  amended, the changes will be uploaded on to our website at{" "}
                  <a
                    href="https://kimbocorp.io/"
                    target="_blank"
                    rel="noopener noreferrer">
                    https://kimbocorp.io/
                  </a>{" "}
                  Each time you use our websites and/or mobile and web-based
                  applications, and any updates, upgrades, new versions,
                  documentation and content and services provided by or through
                  the websites and/or mobile and web-based applications
                  (collectively referred to as “Kimbocorp”), or contact, interact
                  or transact with us, you acknowledge and agree that the latest
                  version of this Privacy Policy shall apply. It is your duty to
                  keep yourself updated as to the latest version of this Privacy
                  Policy.
                </p>
                <p>This Privacy Policy was last updated on 16 February 2024.</p>
                <h5>1. Your / Another Person’s Personal Data</h5>
                <ul>
                  <li className="numberPoint">
                    1.1. Personal Data” refers to any data, whether true or not,
                    about an individual who can be identified (a) from that
                    data; or (b) from that data and other information to which
                    an individual or organisation has submitted on our platform.
                    For example, “Mr XXX YYY, Director of Company ABC from
                    France, https://www.linkedin.com/in/xxxyyy/” could
                    constitute data that falls under (b).
                  </li>
                  <li>
                    1.2. The definition does not extend to (a) an individual’s
                    first name and last name; (b) LinkedIn URL; (c) email
                    addresses and mobile numbers; or (d) anonymised data.
                    Anonymisation is the process of removing identifying
                    information such that the remaining data does not identify
                    any particular individual.
                  </li>
                  <li>
                    1.3. The exact type of personal Data that may apply in your
                    case will vary depending on how you have interacted with us.
                    Some examples of such Personal Data you may provide to us
                    include:
                  </li>
                  <ul className="alphaList">
                    <li>
                      (a) Personal details (e.g., full name, personal email
                      address, personal mobile or contact number, passport
                      number, residential address, source of funds, job position
                      or seniority, employer, country of residency and
                      background, and/or income levels);
                    </li>
                    <li>
                      (b) images and biometrics (e.g., photographs, voice, and
                      video recordings of you, including our conversations with
                      you, using fingerprint mapping and facial recognition for
                      verification or other purposes);
                    </li>
                    <li>
                      (c) your personal opinions made known to us (e.g., through
                      messages, video and voice calls, feedback or surveys); and
                    </li>
                    <li>
                      (d) other electronic data or information relating to you
                      through your usage of our products and services or as part
                      of their delivery to you (e.g., location data, IP address,
                      activity logs, cookies, device carrier/operating system
                      and connection type).
                    </li>
                  </ul>
                  <li>
                    1.4. Any reference to “Personal Data” also includes such
                    data that may be provided by you on behalf of another person
                    pursuant to Clause 3 below.
                  </li>
                  <li>
                    1.5. References to “includes,” “including”, “including but
                    not limited to”, “including without limitation” and words or
                    phrases of similar import shall be deemed to have the same
                    meaning and the words “includes(s)” and “including” shall
                    not be deemed to be terms of limitation but rather be deemed
                    to be followed by the words “without limitation”.
                  </li>
                  <li>
                    1.6. In relation to Personal Data, “processing” refers to
                    the carrying out of any operations or set of operations on
                    the Personal Data and including any collecting, recording,
                    holding, storing, adaptation or alteration, retrieval,
                    combination, transmission, erasure or destruction of
                    Personal Data.
                  </li>
                </ul>
                <h5>2. Use and Collection of Personal Data</h5>
                <ul>
                  <li>
                    2.1. Kimbocorp may use your Personal Data (a) for the
                    purposes identified below or separately communicated to you;
                    (b) to fulfil legitimate business interests and/or
                    contractual and legal obligations; and (c) to comply with
                    applicable laws, regulations and/or requirements from
                    government agencies, regulatory bodies, statutory boards or
                    other relevant bodies in Singapore.
                  </li>
                  <li>
                    2.2. Generally, Kimbocorp may collect your Personal Data in
                    the following ways:
                  </li>
                  <ul className="alphaList">
                    <li>
                      (a) when you submit forms, applications, requests or
                      feedback to us, for example, add a profile to a book of
                      business;
                    </li>
                    <li>
                      (b) when you enter into any agreement or provide other
                      documentation or information in respect of your
                      interactions with us, or when you use our services;
                    </li>
                    <li>
                      (c) when you interact with our commercial advisors or
                      company promoters, for example, via telephone calls,
                      letters, face-to-face meetings, social media platforms
                      and/or emails;
                    </li>
                    <li>
                      (d) when you use our electronic services, mobile and
                      web-based applications or interact with us via any of our
                      websites or mobile or web-based applications or use the
                      services on any of our websites or mobile or web-based
                      applications which may utilise various technologies to
                      collect data (which may include Personal Data)
                      automatically either by us or by third parties on behalf
                      of us;
                    </li>
                    <li>
                      (e) when you respond to our request(s) for additional
                      Personal Data, for example, upload documents to complete
                      user verification;
                    </li>
                    <li>
                      (f) when we receive your Personal Data from referral
                      parties, government ministries or agencies, regulators,
                      statutory boards, public agencies, your employer and/or
                      other third parties;
                    </li>
                    <li>
                      (g) when you attend or participate in our events or
                      activities (e.g., public forums or events) and your voice
                      and/or image data is captured on cameras, audio and/or
                      video recordings;
                    </li>
                    <li>(h) when you respond to our initiatives;</li>
                    <li>
                      (i) from third parties, including social networks (such as
                      Facebook, LinkedIn or YouTube), when you consent to such
                      third parties disclosing information about you to us that
                      those third parties have collected, whether by logging
                      into or through the social plug-ins on our websites or
                      mobile or web-based applications or otherwise; and
                    </li>
                    <li>
                      (j) when you submit your Personal Data to us for any other
                      reason.
                    </li>
                  </ul>
                  <li>
                    2.3. As a website or app, Kimbocorp may also use some of the
                    following technologies in the services and functionalities
                    in Kimbocorp. Examples of some of the technologies which may
                    be used (either by us or by third parties on behalf of us)
                    by or in our electronic services, websites and mobile and
                    web-based applications to collect, use and/or disclose
                    Personal Data include the following:
                  </li>
                  <ul className="alphaList">
                    <li>
                      (a) Authentication confirmations from biometric data
                      services. We do not store biometric data but may interface
                      with third party biometric data services (such as SingPass
                      and Corppass which uses biometric-enabled authentication
                      processes for user login) to authenticate your identity
                      when you seek to access Kimbocorp services.
                    </li>
                    <li>
                      (b) Click-stream data. A visit to one of our websites or
                      use of our mobile or web-based applications result in data
                      that is transmitted from your browser to our server being
                      automatically collected and stored by us or by third
                      parties on behalf of us. This data can include the
                      following:
                    </li>
                    <ul className="alphaList">
                      <li>(i) the visitor’s IP address;</li>
                      <li>(ii) the date and time of the visit;</li>
                      <li>
                        (iii) the referral URL (the site from which
                        thevisitorhas come);
                      </li>
                      <li>
                        (iv) the pages visited, and action taken on our website
                        or mobile or web-based applications; and
                      </li>
                      <li>
                        (v) information about the device, mobile carrier and
                        browser used (browser type and version, operating
                        system, etc).
                      </li>
                    </ul>
                    <li>
                      (c) Cookies. Several places on our website and our mobile
                      and web-based applications make use of cookies. Should you
                      wish to disable the cookies associated with these
                      technologies, you may do so by changing the setting on
                      your browser. However, you may not be able to enter
                      certain part(s) of our website or enjoy full access to all
                      our applications.
                    </li>
                    <li>
                      (d) Web beacons and tracking links. Web beacons (also
                      known as pixel tags and clear GIFs) involve graphics that
                      are not apparent to the user. Tracking links and/or
                      similar technologies consist of a few lines of programming
                      code and can be embedded in our emails, website or mobile
                      or web-based applications. In conjunction with cookies,
                      these are primarily used for statistical analysis
                      purposes. This technology can also be used for tracking
                      traffic patterns on websites, as well as finding out if an
                      e-mail has been received and opened and to see if there
                      has been any response.
                    </li>
                    <li>
                      (e) Web analytics. Web analytics is the term given to a
                      method for collecting and assessing the behaviour of
                      visitors to websites and mobile or web-based applications.
                      This includes the analysis of traffic patterns in order,
                      for example, to determine the frequency of visits to
                      certain parts of a website or mobile or web-based
                      application, or to find out what information and services
                      our visitors are most interested in. For these purposes,
                      we primarily make use of click-stream data and the other
                      techniques listed above. Web analytics are carried out by
                      the Content Management Systems, Google Analytics and/or
                      other selected parties (as we may inform you from time to
                      time) (“Web Analytics Providers”). When you visit our
                      website or use any mobile or web-based applications,
                      Personal Data may (unless you have refused the processing
                      of your Personal Data via such cookies and technologies)
                      be sent to the Web Analytics Providers for analysis for
                      and on behalf of us for the purposes described in this
                      Privacy Policy. Please note that if you have created an
                      online profile at our website or mobile or web-based
                      application and if you are logged in on this profile, a
                      unique number identifying this profile may also be sent to
                      the Web Analytics Providers to be able to match the web
                      analytics data to this profile. If you do not wish
                      information about your behaviour at our website or any
                      mobile or web-based applications to be collected and
                      assessed by Google Analytics, you can install the Google
                      Analytics opt-out browser add-on. Please refer to Google
                      Analytics Opt-out Browser Add-on page for more information
                      on downloading the add-on and opting out.
                    </li>
                    <li>
                      (f) Unique application numbers. Certain services include a
                      unique application number. This number and information
                      about your installation of the application (for example,
                      the operating system type and application version number)
                      may be sent to us when you install or uninstall that
                      service or when that service periodically contacts our
                      servers, such as for automatic updates.
                    </li>
                    <li>
                      (g) Local Storage. We may collect and store information
                      (including Personal Data) locally on your device using
                      mechanisms such as browser web storage (including HTML 5)
                      and application data caches. Such information may include
                      your self-added medication list and medication reminders.
                    </li>
                    <li>
                      (h) Retargeting Technologies. Our website and mobile or
                      web-based applications may use retargeting technologies
                      within the Internet. Retargeting technologies analyse your
                      cookies and past surfing behaviour, including clicking of
                      advertisements, so that where you have visited our
                      website, used our mobile or web-based applications or
                      clicked on our advertisements before, we can serve you our
                      advertisements on our partner websites. We may also work
                      with Web Analytics Providers, who use tracking
                      technologies to serve our advertisements to you across the
                      Internet. To serve such advertisements, these companies
                      may collect information about your visits to our websites
                      or mobile or web-based applications and your interactions
                      with us and our advertisements. Please refer to our Web
                      Analytics Providers’ privacy policy(ies) for more
                      information about their data protection practices. If you
                      do not wish to receive such advertisements, you may adjust
                      your browser settings for the cookies which your browser
                      accepts.
                    </li>
                  </ul>
                </ul>
                <h5>3. Accessing the Personal Data of Others on Kimbocorp</h5>
                <ul>
                  <li>
                    3.1. You may give authorisation another person to use
                    Kimbocorp to access your Personal Data. To do so, you will
                    need to provide your Email address, Mobile number, Passport
                    copy or Singapore NRIC or FIN and proof of address, as well
                    as the other person’s Email address, Mobile number, Passport
                    copy or Singapore NRIC or FIN and proof of address. Where
                    so, by applying to obtain or to grant such access, you
                    represent to us that:
                  </li>
                  <ul className="alphaList">
                    <li>(a) you are authorised to do so;</li>
                    <li>
                      (b)you have obtained the consent of the third party to
                      provide us with his/her Personal Data for the respective
                      purposes;
                    </li>
                    <li>
                      (c) he/she accepts that his/her Personal Data will be
                      subject to this Privacy Policy (as amended from time to
                      time); and
                    </li>
                    <li>
                      (d) you agree to fully indemnify us in respect of any
                      regulatory penalties, claims or proceedings by any third
                      party(ies) and any proceedings, investigations, orders,
                      directions, judgments issued by a court, statutory body or
                      regulatory authority, in connection with the provision of
                      his/her/their Personal Data.
                    </li>
                  </ul>
                  <li>
                    3.2. You should ensure that all Personal Data submitted to
                    us is complete, accurate, true and correct. Failure on your
                    part to do so may result in our inability to provide you
                    with the products and services you have requested or process
                    your application.
                  </li>
                  <h5>
                    Purposes Related to Your Use of Personal Data on Kimbocorp
                  </h5>
                  <li>
                    3.3. Generally, Kimbocorp may collect, use and/or disclose
                    your Personal Data for the following purposes:
                  </li>
                  <ul className="alphaList">
                    <li>
                      (a) setting up your account with us and managing your use
                      and access of Kimbocorp;
                    </li>
                    <li>
                      (b) facilitating your use of our online and mobile
                      services such as the Holding company management platforms
                      and/or the transactional e-services like e-appointment,
                      e-sign and e-payments;
                    </li>
                    <li>
                      (c) (subject to and in accordance with agreements executed
                      with the relevant Corporate Regulator specifying security
                      and Personal Data safeguards) integrating onto corporate
                      registry computer systems and applications operated or
                      managed by corporate regulators like ACRA Singapore, in
                      order to provide you with the compliance and governance
                      services (including filing, stakeholder recommendation,
                      tax reporting, accounting services) and any programmes
                      related to promotion of good corporate governance and
                      compliance.
                    </li>
                    <li>
                      (d) managing your relationship with us by personalising
                      our services and recommending content related to your
                      ambitions, needs and issues and our services and informing
                      you about service upgrades and updates;
                    </li>
                    <li>
                      (e) carrying out security and safety measures and services
                      such as performing network or service enhancement and
                      protecting our platforms from unauthorised access or use;
                    </li>
                    <li>
                      (f) carrying out market-related, evaluation or similar
                      research and analysis for our operational strategy and
                      policy planning purposes, including providing data to
                      authorised external parties for any purposes to review,
                      develop and improve the quality of our products and
                      services;
                    </li>
                    <li>
                      (g) verifying your identity and such other information
                      provided by you, including but not limited to the
                      relationship between yourself and a third-party
                      stakeholder;
                    </li>
                    <li>
                      (h) managing the administrative and business operations
                      more effectively such as attending to your queries,
                      feedback and/or complaints and complying with our internal
                      record keeping for meeting any applicable laws and
                      regulations;
                    </li>
                    <li>
                      (i) facilitating business asset transactions (which may
                      extend to any procurement, mergers, acquisitions or asset
                      sales);
                    </li>
                    <li>
                      (j) carrying out due diligence in accordance with legal
                      and regulatory obligations or our risk management
                      procedures and policies such as conducting audits to
                      prevent, detect and investigate crime or offences or
                      uncover non-conforming processes;
                    </li>
                    <li>
                      (k) monitoring or recording phone calls and
                      customer-facing interactions for quality assurance, and
                      identity verification purposes;
                    </li>
                    <li>
                      (l) in connection with any claims, actions or proceedings
                      (including but not limited to the drafting and reviewing
                      of documents, transaction documentation, obtaining legal
                      advice, and facilitating dispute resolution), and/or
                      protecting and enforcing our contractual and legal rights
                      and obligations;
                    </li>
                    <li>
                      (m) with consents specifically obtained through (or in
                      connection with) national systems such as MyInfo,
                      collecting your profile to compile demographics, user
                      preferences (e.g., likes, topics of interests) and other
                      Personal Data to facilitate your future / further requests
                      in Kimbocorp and increase your ease of use of Kimbocorp;
                    </li>
                    <li>
                      (n) (where necessary) sending a personalised notification
                      to you in relation to your login and use of Kimbocorp
                      whether this is in-app or (where you consent) for your
                      usage of other apps connected with Kimbocorp;
                    </li>
                    <li>
                      (o) complying with any request or direction of any
                      government authority or public agencies, ministries,
                      statutory boards or similar authorities or
                      non-governmental agencies authorised to carry out specific
                      Government services and duties; and
                    </li>
                    <li>
                      (p) any other purpose reasonably related to the aforesaid.
                    </li>
                  </ul>
                  <li>
                    3.4. To conduct our operations more smoothly, we may
                    disclose the Personal Data you have provided to us to our
                    third-party services providers, agents and/or affiliates or
                    related corporations (“External Parties”) for such External
                    Parties to process your Personal Data based on your
                    requested services for one or more of the above stated
                    reasons or purposes. An External Party may be sited locally
                    or outside Singapore.
                  </li>
                  <li>
                    3.5. If we need to use your Personal Data for any other
                    purposes, we will notify you and obtain your consent
                    beforehand in accordance with the requirements of applicable
                    data protection laws.
                  </li>
                </ul>
                <h5>4. Accuracy of Personal Data</h5>
                <ul>
                  <li>
                    4.1. We will take appropriate and reasonable steps to ensure
                    the accuracy and correctness of the Personal Data that we
                    collect, use and/or disclose. To enable us to ensure the
                    quality and accuracy of Personal Data, you have an
                    obligation to provide accurate and up-to-date information to
                    us.
                  </li>
                </ul>
                <h5>5. How We Protect Your Personal Data</h5>
                <ul>
                  <li>
                    5.1. We will take reasonable efforts to protect the Personal
                    Data in our possession or under our control by making
                    reasonable security arrangements to prevent unauthorised
                    access, collection, use, disclosure, copying, modification,
                    disposal or similar risks. However, we cannot completely
                    guarantee the security of any Personal Data we may have
                    collected from or about you, e.g., that no harmful code
                    (such as viruses, bugs, Trojan horses, spyware or adware)
                    will enter our website.
                  </li>
                </ul>
                <h5>6. How Long Do We Retain Your Personal Data</h5>
                <ul>
                  <li>
                    6.1. We retain Personal Data as may be required for
                    business, legal, regulatory or compliance purposes, and such
                    purposes do vary according to the circumstances. We will
                    take reasonable steps to dispose or anonymise Personal Data
                    that is no longer needed. Regarding personal data that we
                    process, we will retain personal records in accordance
                  </li>
                </ul>
                <h5>7. Transfer of Personal Data Outside Singapore</h5>
                <ul>
                  <li>
                    7.1. Generally, we do not transfer Personal Data out of
                    Singapore, except to our approved third-party services
                    providers for applicable services. Should we do so, we will
                    ensure there is compliance with the requirements under the
                    PDPA.
                  </li>
                </ul>
                <h5>8. Third Party Sites</h5>
                <ul>
                  <li>
                    8.1. This site may contain links to sites whose data
                    protection and privacy practices may differ from ours. We
                    are not responsible for the content and privacy practices of
                    these other websites and encourage you to check the privacy
                    notices of these other websites to determine how they will
                    handle any information they collect from you.
                  </li>
                </ul>
                <h5>
                  9. Contacting Us – Feedback, Withdrawal of Consent, Access and
                  Correction of your Personal Data
                </h5>
                <ul>
                  <li>
                    9.1. You are entitled to withdraw your consent for the
                    collection, use and disclosure of your Personal Data at any
                    time. However, depending on the nature of the withdrawal, it
                    may become impossible for Kimbocorp to provide services such
                    as processing or providing services offered by Kimbocorp.
                    Kimbocorp may also not be able to administer any contractual
                    relationship in place, which in turn may also result in the
                    termination of any agreements with Kimbocorp, and your being
                    in breach of your contractual obligations or undertakings.
                    Kimbocorp’ legal rights and remedies in such event are
                    expressly reserved.
                  </li>
                  <li>9.2. If you:</li>
                  <ul className="alphaList">
                    <li>
                      (a) have any questions or feedback relating to your
                      Personal Data or our Privacy Policy;
                    </li>
                    <li>
                      (b) would like to withdraw your consent to any use of your
                      Personal Data as set out in this Privacy Policy; or
                    </li>
                    <li>
                      (c) would like to obtain access and make corrections to
                      your Personal Data records, you can contact our Data
                      Protection Officer by emailing us at{" "}
                      <a href="mailto:support@kimbocorp.com">support@kimbocorp.com.</a>
                    </li>
                  </ul>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
