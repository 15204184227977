export const RoutesPaths = {
  HOME: `/`,
  MESSAGESCREEN: `/chat`,
  ACCOUNTING: `/accounting`,
  READYMADECOMPANIES: `/readymade-companies`,
  ACTIVEBUSINESS: "/active-businesses",
  ALLADVISORY: "/all-advisory",
  ENTERPRISE: "/enterprise",
  GOVERNANCE: "/governance",
  HOLISTICSOLUTIONS: "/holistic-solutions",
  HYBRIDBUSINESS: "/hybrid-business",
  INVESTMENTBUSINESS: "/investment-business",
  SINGAPORESOLUTIONS: "/singapore-solutions",
  STARTBUSINESSFREE: "/start-business-free",
  PRIVACYPOLICY: "/privacy-policy",
  ACCOUNTCLOSEORM: "/account-close-form",
  TERMOFSERVICE: "/terms-of-service",
  TERMSOFADVISORY: "terms-of-advisory-plan",
  TERMSANDCONDITIONS: "terms-and-conditions"
};
