import React from "react";
// import { backendUrl } from "../constants";

export default function TermsAndConditions() {
  // const [termsData, setTermsData] = useState("");
  // useEffect(() => {
  //   const apiUrl = `${backendUrl}api/auth/pagecontent?type=2`;

  //   // Fetch data from the API
  //   fetch(apiUrl)
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error(`HTTP error! status: ${response.status}`);
  //       }
  //       return response.json(); // Convert the response to JSON
  //     })
  //     .then((data) => {
  //       setTermsData(data.data.content); // Process the JSON data
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data:", error);
  //     });
  // }, []);

  return (
    <div>
      <div className="our-approach transparent-bg privacyPage">
        <div className="container">
          <div className="our-approach-inner">
            <div className="row text-center">
              <div className="col-md-12 our-approach-content">
                <h2>Terms and conditions</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                {/* <div dangerouslySetInnerHTML={{ __html: termsData }}></div> */}
                <h5>1. Legal Entity</h5>
                <p>
                  Kimbo Corporate Pte. Ltd. uses the name of “kimbocorp" on the
                  web site{" "}
                  <a
                    href="https://kimbocorp.com"
                    target="_blank"
                    rel="noreferrer">
                    https://kimbocorp.com
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://kimbocorp.io"
                    target="_blank"
                    rel="noreferrer">
                    https://kimbocorp.io
                  </a>{" "}
                  These names refer to the company kimbocorp Pte. Ltd.,
                  incorporated in Singapore with company registration number
                  201316594C. These names refer to the company kimbocorp Pte.
                  Ltd., incorporated in Singapore with company registration
                  number 201602269C..
                </p>
                <h5>2. Acceptance of Terms and Conditions</h5>
                <p>
                  By placing an order with Kimbo Corporate Pte. Ltd.
                  (kimbocorp), the User confirms that he/she has read and
                  accepted the Terms and Conditions as stipulated in this text.
                  Unless explicitly specified, these Terms and Conditions apply
                  to all orders regardless of whether these are buy orders, sell
                  orders, scrap orders, storage orders and regardless of whether
                  the orders are placed through the website, in person, by
                  telephone, by e-mail or otherwise. Unless explicitly
                  specified, these Terms and Conditions also apply to all orders
                  regardless of which location or jurisdiction products or
                  services are offered. By placing an order with kimbocorp, the
                  User agrees to the Terms and Conditions stipulated in this
                  text.
                </p>
                <h5>3. Variation</h5>
                <p>
                  kimbocorp may amend or vary these Terms and Conditions at any
                  time by posting a revised version on its website. The revised
                  version will be effective upon posting. If the revised version
                  includes a substantial change, the User will be notified with
                  7 days prior notice using the e-mail on the User's kimbocorp
                  account.
                </p>
                <h5>4. Interpretations</h5>
                <p>
                  <span>In these Terms and Conditions:</span>
                </p>
                <p>
                  “Agreement” means the binding agreement entered into when a
                  User places an Order with kimbocorp that a User enters into.
                </p>
                <p>
                  “Primary member“ refers to the person who is the main
                  shareholder or director of a company created with kimbocorp
                  and/or for which will be liable to pay corporate taxes,
                  service fees, maintenance fees or any such fees to keep a
                  company managed by kimbocorp.
                </p>
                <p>
                  “Paid service” refers to any paid plan or service which the
                  primary member has paid for, including procurement of a
                  company plan and one-off services. We would have discussed or
                  communicated with you on any of our websites and digital
                  platforms, or through meetings and/or through our other
                  correspondences with you how our professional fees will be
                  charged, and this will be set out from time to time to be
                  communicated via means of communication to you.
                </p>
                <p>
                  “Advisory service” refers to provision of a company which the
                  primary member or his/her associates would own, incorporated
                  in an advanced economy like Singapore, generally including
                  compliance and governance functions, such as appointment of
                  corporate secretary and resident directors. The overall
                  compliance management of this company would typically be
                  covered under such a paid company plan. The rates for such a
                  company plan would have been discussed or communicated with
                  you, via any of kimbocorp's website or digital platforms, or
                  through other correspondences and meetings, and these would be
                  set out from time to time and be communicated to you.
                </p>
                <p>“kimbocorp” means Kimbo Corporate Pte Ltd.</p>
                <p>
                  “kimbocorp Account” or “kimbocorp account” means an account
                  maintained by kimbocorp on behalf of a User for transacting,
                  holding and storing Products, Services and kimbocorp Account
                  Balances.
                </p>
                <p>
                  “kimbocorp Account Balance” is a User’s pre-payment of money
                  to kimbocorp for their purchase of Products and Services.
                </p>
                <p>
                  “Cryptocurrencies” mean digital currencies in which encryption
                  techniques are used to regulate the generation of units of
                  currency and verify the transfer of funds, operating
                  independently of a central bank such as e.g. but not limited
                  to Bitcoin, Bitcoin Cash, Ethereum and Litecoin.
                </p>
                <p>
                  “User” means a person that registers any account on any of
                  kimbocorp’s Website, or transacts with kimbocorp on any
                  Product or Service.
                </p>
                <p>
                  “Customer Due Diligence” or “CDD” both mean a due diligence
                  enquiry on a User to obtain Know-Your-Customer information for
                  the purpose of compliance with any regulatory regime or policy
                  of kimbocorp which will include requests for information and
                  documents relating to the User’’s identity and finances such
                  as the User’s identification documents, income, source of
                  wealth, and source of funds.
                </p>
                <p>
                  “GST” means the tax which is charged on the supply of goods
                  and services and on the importation of goods in accordance
                  with the GST Act.
                </p>
                <p>"GST Act” means the Goods and Services Tax Act 1993.</p>
                <p>
                  “Item” or “items” means any an offer of a deposit of capital
                  or paid-up capital, purchase of a service or product, or
                  subscription to a plan, communicated to a User, either online,
                  in person, via phone, via email or via any other method of
                  communication that has been initiated by kimbocorp.
                </p>
                <p>
                  “Order” or “orders” means a deposit of capital or paid-up
                  capital, purchase of a service or product, or subscription to
                  a plan, placed by a User, either online, in person, via phone,
                  via email or via any other method of communication that has
                  been accepted by kimbocorp.
                </p>
                <p>
                  “Paid-up capital” refers to the amount of capital that will be
                  deposited into a company account, in return for the company
                  providing ownership to the person paying the capital.
                  KIMBOCORP RESERVES THE RIGHT TO COLLECT PAID-UP CAPITAL FOR
                  ANY COMPANY MANAGED BY KIMBOCORP
                </p>
                <p>
                  “Corporate income tax” refers to taxes payable to the Income
                  tax department of Singapore or any country for which the
                  company is a tax resident of. KIMBOCORP RESERVES THE RIGHT TO
                  COLLECT CORPORATE TAXES PAYABLE FOR ANY COMPANY MANAGED BY
                  KIMBOCORP
                </p>
                <p>
                  “NETS payment” means a payment made using the Singapore based
                  Network for Electronic Transfers (NETS).
                </p>
                <p>
                  “Service”, “Product” or “Plan” means any item provided, sold,
                  offered or carried by kimbocorp, whether on a Website or in a
                  physical meeting, in or at whatever location or jurisdiction.
                </p>
                <p>
                  “Terms and Conditions” means the terms and conditions
                  stipulated in this text as updated from time to time.
                </p>
                <p>
                  “Website”, “website” or ”web site” means any website or mobile
                  app owned and registered by Kimbo Corporate Pte Ltd,
                  including, but not limited to,{" "}
                  <a
                    href="https://kimbocorp.com"
                    target="_blank"
                    rel="noreferrer">
                    www.kimbocorp.com
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://kimbocorp.io"
                    target="_blank"
                    rel="noreferrer">
                    https://kimbocorp.io
                  </a>
                  , and mobile apps on google playstore and apple appstore.
                </p>
                <p>
                  References to “includes,” “including,” “including but not
                  limited to,” “including without limitation” and words or
                  phrases of similar import shall be deemed to have the same
                  meaning as the words “include(s)” and “including” and shall
                  not be deemed to be terms of limitation but rather be deemed
                  to be followed by the words “without limitation.” References
                  to “such as” shall also not be deemed to be a term of
                  limitation but rather also be deemed to be followed by the
                  words “without limitation.” Where the context so admits, words
                  importing the singular number include the plural and vice
                  versa and words importing gender include the masculine,
                  feminine and neuter genders.
                </p>
                <p>
                  Save where the context otherwise requires, a reference to a
                  person includes a reference to an individual, a firm, a body
                  corporate and an unincorporated association. A reference to a
                  person also includes a reference to the person’s permitted
                  heirs, trustees, personal representatives, executors,
                  administrators, directors, officers, employees, agents,
                  partners, members, successors and assigns.
                </p>
                <h5>
                  5. Terms and Conditions when a Customer is buying from
                  kimbocorp
                </h5>
                <p>
                  kimbocorp reserves the right to cancel, refuse or reject any
                  Order for any reason whatsoever, including cases where:
                </p>
                <ul className="alphaList">
                  <li>
                    (a) Payment for any current or previous Order of the User
                    has not been made, or received by kimbocorp, in accordance
                    with these Terms and Conditions.
                  </li>
                  <li>(b) Extraordinary situations arise.</li>
                  <li>
                    (c) Price, stock, delivery time or availability has been
                    published erroneously due to computer-related problems or
                    due to human factors.
                  </li>
                  <li>
                    (d) Deliveries and/or Approval from kimbocorp's
                    stakeholders, including government agencies, have been
                    delayed or canceled.
                  </li>
                  <li>
                    (e) The price or other information has been published
                    erroneously.
                  </li>
                  <li>
                    (f) The order is deemed questionable, suspicious, abusive or
                    of significant risk to kimbocorp.
                  </li>
                </ul>
                <p>
                  Where kimbocorp deems it necessary to conduct a Customer Due
                  Diligence (CDD) for a transaction: (a) the transaction is
                  conditional upon kimbocorp completing the CDD to its
                  satisfaction; (b) the User must provide all relevant
                  information as requested by kimbocorp; (c) where the User
                  fails to provide the requested information in a timely manner,
                  kimbocorp shall have the right to discontinue the transaction
                  whereupon the User warrants to immediately pay kimbocorp an
                  administrative fee of SGD 200; and (d) where kimbocorp finds
                  itself unable or unwilling to complete the transaction upon
                  the completion of a CDD, the transaction will be deemed as
                  never entered into and any payment made by the User will be
                  returned to the User in accordance with this Agreement.
                </p>
                <p>
                  When purchasing from kimbocorp’s location in Singapore,
                  <a
                    href="https://kimbocorp.com"
                    target="_blank"
                    rel="noreferrer">
                    www.kimbocorp.com
                  </a>{" "}
                  or{" "}
                  <a
                    href="https://kimbocorp.io"
                    target="_blank"
                    rel="noreferrer">
                    https://kimbocorp.io
                  </a>
                  , some plans and services are exempted from GST in Singapore
                  while others may include GST. In the event that kimbocorp
                  offers items that are not exempted from GST, the price listed
                  on the website will include GST.
                </p>
                <h5>6. Strictly No Refunds</h5>
                <p>
                  kimbocorp operates under a strict No Refunds policy for any
                  plan, service, or product that Kimbocorp has agreed to
                  fulfill. Once kimbocorp has commenced the process of
                  delivering the agreed service, refunds will not be provided
                  under any circumstances, including but not limited to the
                  following situations:
                </p>
                <ul className="alphaList">
                  <li>
                    6.1 User Non-Conformance or Unwillingness: If the user fails
                    to comply with requirements, refuses to provide necessary
                    information, or is otherwise unwilling to proceed with the
                    process.
                  </li>
                  <li>
                    6.2 Third-Party Rejections: If banks, financial
                    institutions, or other third-party service providers decline
                    to support the user’s business or associated activities for
                    reasons beyond kimbocorp's control.
                  </li>
                  <li>
                    6.3 External Regulatory or Operational Barriers: If
                    regulatory authorities, local jurisdictions, or unforeseen
                    operational issues impede the service or product delivery.
                  </li>
                </ul>
                <p>
                  kimbocorp is not responsible or liable for delays, rejections,
                  or incomplete processes arising from circumstances beyond its
                  reasonable control. Users are advised to ensure compliance
                  with all requirements and conditions to facilitate smooth
                  execution of services.
                </p>
                <h5>7. Location</h5>
                <p>
                  kimbocorp may offer products, plans or services for sale in
                  multiple countries. These Terms and Conditions apply to all
                  orders regardless of which location or jurisdiction products
                  or services are offered. kimbocorp does not have any permanent
                  establishment in any country other than Singapore. The User
                  agrees that kimbocorp may use an agent to fulfill order
                  handling and administrative services as required in
                  jurisdictions other than Singapore. The User's contractual
                  counterparty is however always Kimbo Corporate Pte. Ltd.
                </p>
                <p>
                  The User is free to select the desired Website for viewing and
                  ordering Items by clicking on the dropdown box to the right of
                  'Jurisdiction' (mobile: 'change Jurisdiction'). The User
                  acknowledges that Products, Plans or Services ordered from any
                  particular Website will be delivered from the jurisdiction
                  that User has chosen.
                </p>
                <h5>8. Price for User Orders</h5>
                <p>
                  Unless otherwise stipulated, the price for an item/items
                  ordered by a User is locked in at the time an order is placed,
                  specifically, at the time the User confirms the purchase by
                  clicking "Confirm", “Place Your Order”, “Pay with card”, “Get
                  transfer instructions”, “Pay with crypto” or similarly in the
                  online checkout or when the User instructs an kimbocorp
                  representative to place an order in the shop, via phone,
                  e-mail or any other means of communication. When an order has
                  been confirmed by the User in the checkout on the website, or
                  via any other means of communication, an order confirmation is
                  sent by e-mail. The formal invoice is sent to the User via
                  e-mail when the Userr’s full payment has been registered by
                  kimbocorp.
                </p>
                <p>
                  kimbocorp reserves the full unconditional right to determine
                  the price to which kimbocorp offers to buy and sell products.
                  kimbocorp reserves the right to charge different prices for
                  products, plans and services depending on what payment method
                  the User chooses. User orders for products, plans and services
                  are binding. By placing an order, the User enters into a
                  binding agreement as stipulated in these Terms and Conditions.
                  All orders regardless of availability status, i.e. whether the
                  items ordered are available or not, are binding and final.
                </p>
                <h5>9. Order Payment</h5>
                <p>
                  <span>In this section:</span>
                </p>
                <p>
                  “Cost” means any cost, fee, expense, penalty, duty or charge
                  including those for any reminder, collection, legal service,
                  court application or court proceeding.
                </p>
                <p>
                  “Remaining balance” means the remaining balance of a product,
                  service or plan, from the time of the order to the time as
                  mentioned in the item description
                </p>
                <p>
                  “Price Loss” means the decrease in the price of an item in an
                  order from the time of the order to the time when the order is
                  aborted.
                </p>
                <p>
                  The payment for an order must be sent (initiated) within one
                  banking day from the order placement for all payment methods
                  except for online Credit/Debit card payments where the payment
                  must be made within 24 hours and except for cryptocurrencies
                  where the payment must be sent (initiated) within 20 minutes.
                  If an order is placed during a non-banking day, the payment
                  must be initiated the next banking day. In the case of a bank
                  transfer, the order number, which is obtained when placing the
                  order, should if possible be marked in the message field of
                  the bank transfer form used for transmitting payment to the
                  recipient/beneficiary, Kimbo Corporate Pte Ltd.
                </p>
                <p>
                  The funds must reach kimbocorp within four (4) banking days of
                  placing the order. kimbocorp reserves the right to demand
                  payment sooner than the above-mentioned time frame. In such a
                  case, the User will be contacted after placing the order. The
                  User bears any fee, charge or cost which the User’s bank or
                  payment service provider may impose.
                </p>
                <p>
                  If the payment for an order is not initiated or completed
                  within the stipulated timeframe, kimbocorp will have the right
                  to abort the order on behalf of the User whereupon the User
                  must immediately pay kimbocorp the Administrative Fee, any
                  Costs incurred by kimbocorp for processing the aborted order,
                  and, any Price Loss for the products ordered. The invoice for
                  an aborted order, if not fully paid, may be handed over to a
                  collection agency or to a court for collection. If the User
                  has any unsettled invoice such as for items or Administrative
                  Fees or Costs or Price Losses, any fund held by kimbocorp in
                  the User’s favour will be allocated towards the settlement of
                  such an invoice first starting with the earliest outstanding
                  debt. As all orders are binding without exception, kimbocorp
                  reserves the right to apply any and all funds held in favour
                  of the User towards the payment for any of the User’s orders
                  regardless of whether such orders have been aborted, or
                  towards any outstanding Administrative Fee, Cost or Price Loss
                  owing to the User’s order. If an order has been partially
                  paid, kimbocorp will remind the User to settle the outstanding
                  amount and when full payment has not been received within
                  twenty-eight (28) days of the order placement, or unless
                  otherwise agreed with the User, the terms and conditions of
                  this paragraph, as well as the terms under Clause 11, applies
                  equally as if payment has not been initiated or completed
                  within the stipulated timeframe.
                </p>
                <p>
                  The User acknowledges that the User bears the full
                  responsibility to send funds, including cryptocurrency funds,
                  to the correct account number or cryptocurrency address as
                  designated by kimbocorp on the order confirmation. For Bitcoin
                  Cash, the User acknowledges that kimbocorp only accepts and
                  sends coins from the Node chain and does not accept or send
                  coins from the Bitcoin ABC chain or Bitcoin Cash SV chain.
                </p>
                <p>
                  The User acknowledges that kimbocorp reserves the right to
                  return the funds, if possible, and/or terminate the order if
                  there are doubts over the owner, identity or origins of the
                  funds. kimbocorp reserves the right to conduct User Due
                  Diligence (CDD) or Enhanced User Due Diligence (ECDD) on the
                  User in accordance with the regulatory regime of Singapore.
                  The CDD or ECDD may include requests for information verifying
                  the User's identity by obtaining information about NRIC
                  number, passport number, occupation, nationality, address,
                  date of birth and contact number. The User may be required to
                  show proof of identifying information which kimbocorp may
                  copy. The CDD or ECDD may also include requests for
                  information to establish the beneficial owner of funds or
                  holding companies, questions and verification about income
                  level, source of wealth and source of funds and whether the
                  User is indeed the entity or representative of the entity
                  behind the purchase. Where kimbocorp has not received adequate
                  information for the conduct of CDD or ECDD as the case may be,
                  or deems that it is unable to complete CDD or ECDD
                  satisfactorily with the information made available to
                  kimbocorp, kimbocorp reserves the right to cancel the order,
                  place the order on hold, or terminate any and all transactions
                  with the User whereupon the User shall release kimbocorp from
                  all liabilities flowing from any consequential refusal to,
                  delay in, or cancellation of transaction with the User.
                  kimbocorp will not be responsible for any losses due to
                  insufficient/unclear transaction or identification details
                  provided by the User.
                </p>
                <p>
                  The User acknowledges that any unclaimed funds of unknown
                  origin accrue to kimbocorp following a period of six years
                  from the funds received by kimbocorp.
                </p>
                <h5>9.1 Bank Transfer/Crypto/Card Payment</h5>
                <p>
                  For orders placed with kimbocorp, Singapore is the default
                  location, the User may place an order online for cash, crypto
                  or card payment as payment method. In the case where Users
                  want to settle in-person, the User must strictly make an
                  appointment with kimbocorp to meet at 151 Chin Swee Road,
                  Manhattan house 02-24, Singapore 169876. No walk-ins are
                  accepted.
                </p>
                <p>
                  kimbocorp reserves the right to conduct CDD or ECDD on a User
                  paying cash generally and specifically in a case where the
                  amount is above SGD 20,000 or equivalent in foreign currency.
                  The CDD or ECDD may include requests for information verifying
                  the User's identity by obtaining information about NRIC
                  number, passport number, occupation, nationality, address,
                  date of birth and contact number. The User may be required to
                  show proof of identifying information which kimbocorp may
                  copy. The CDD or ECDD may also include requests for
                  information to establish the beneficial owner of funds or
                  holding companies, questions and verification about income
                  level, source of wealth and source of funds and whether the
                  User is indeed the entity or representative of the entity
                  behind the purchase. If no supporting information is provided,
                  kimbocorp reserves the right to cancel the order or place the
                  order on hold. If kimbocorp is unable to obtain the User's
                  identifying information, after requesting for it, the User
                  understands that regulations may prohibit kimbocorp from
                  completing the transaction and that kimbocorp may have to
                  terminate any other transaction entered into with the User.
                </p>
                <p>
                  kimbocorp reserves the right to change the payment method.
                  kimbocorp will in such a case notify the Customer and may give
                  the Customer a grace period to be able to transfer or deposit
                  the funds to any of kimbocorp’s bank accounts.
                </p>
                <p>
                  If any fund will be returned to the User’s credit card for any
                  reason including the termination, cancellation, or
                  modification of an order, kimbocorp will return such funds to
                  the credit card in the same currency in which the fund was
                  originally paid. The User acknowledges that the User shall be
                  responsible for and agrees to bear all loss that may result
                  from currency conversion or any fee, charge or cost which the
                  User’s bank or payment service provider may impose.
                </p>
                <h5>9.2 Cryptocurrency Payments</h5>
                <p>
                  Where a User makes a payment to kimbocorp in cryptocurrency
                  for an order or commits to receiving payment from kimbocorp in
                  cryptocurrency, the User hereby gives consent to kimbocorp to
                  hedge the transaction on a best-effort basis (referred to as
                  "Leg 1”). In the event that any part of the cryptocurrency or
                  fiat amount needs to be returned due to the termination,
                  cancellation, or modification of an order, or for any other
                  reason, kimbocorp retains the right to unwind the hedged
                  cryptocurrency or fiat amount from Leg 1 on a best-effort
                  basis (referred to as “Leg 2”). The User acknowledges and
                  agrees to bear any loss that may result from a price
                  fluctuation between Leg 1 and Leg 2.
                </p>
                <h5>10. kimbocorp Account Balances</h5>
                <p>
                  A User may place prepayments in various currency denominations
                  on their kimbocorp account. These are reflected as kimbocorp
                  Account Balances on the User’s kimbocorp account. The User can
                  fund/top up their account to subsequently purchase Products
                  and Services or withdraw such funds at any time. The User can
                  keep their kimbocorp Account Balances indefinitely on their
                  kimbocorp account but can only use them with kimbocorp to pay
                  for kimbocorp’s Products and Services. kimbocorp does not
                  charge any account maintenance fee. A User can request a
                  partial or full withdrawal of funds from his/her kimbocorp
                  Account Balance at any time via the kimbocorp website.
                  kimbocorp does not charge any fee for receiving or sending
                  funds, but the User will bear all bank charges for receiving
                  and sending funds. kimbocorp Account Balances do not earn
                  interest. kimbocorp Account Balances cannot be used to pay any
                  third party and cannot be transferred between kimbocorp
                  accounts.
                </p>
                <h5>10.1 Funding an kimbocorp Account</h5>
                <p>
                  To fund a kimbocorp account, a User should first notify
                  kimbocorp of the User’s intention to fund the account. This
                  can be done by sending a notification through kimbocorp’s
                  webapp or mobile app and sending a notification to kimbocorp
                  admin via livechat, or by specifying the funding amount in the
                  “Fund account” field in a user account. Fund notifications are
                  not binding and will expire if the funds are not received by
                  kimbocorp within 7 days of the notification.
                </p>
                <p>
                  Funds on a kimbocorp account can be held in the following
                  currencies: Singapore Dollars and US Dollars. Funds in one
                  currency on a User’s kimbocorp account cannot be transferred
                  to another currency on that User’s kimbocorp account and
                  cannot be transferred to another User or third party.
                </p>
                <p>
                  <span>
                    A User can fund an account using the following methods:
                  </span>
                </p>
                <ul className="alphaList">
                  <li>
                    &bull; Singapore Dollar: PayNow, domestic or international
                    bank transfer
                  </li>
                  <li>&bull; US Dollar: International bank transfer</li>
                </ul>
                <p>
                  Funds will be kept on a User’s kimbocorp account until the
                  User places an order settled by using the funds on account or
                  until the User withdraws the funds. The minimum funding amount
                  for a kimbocorp account is SGD/USD 100 per transaction. At the
                  time of purchasing items from kimbocorp, Users can choose
                  whether to use funds on their kimbocorp account or settle the
                  order via any of the other payment methods available.
                </p>
                <p>
                  Should a User wants to use balances “kimbocorp account” as the
                  payment method, the Usercan notify kimbocorp admin under the
                  “messages” section of kimbocorp’s webapp or mobile app, and
                  the purchase costs will immediately be withdrawn from the
                  User’s kimbocorp account provided that there is sufficient
                  credit balance in the account to fund that purchase. A User
                  selling products to kimbocorp may elect to have the proceeds
                  of a sell order credited to the User’s kimbocorp account for
                  use towards future purchases of products, plans and services
                  from kimbocorp, or for later withdrawal. Sales proceeds for
                  items sold to kimbocorp will normally be credited to a User’s
                  kimbocorp account within one banking day from the later of a)
                  kimbocorp taking possession of the said items sold, or b) the
                  User placing the sell order. In all cases, Users are required
                  to provide the requisite User and bank information to
                  kimbocorp in advance of any sales. In rare cases, the
                  crediting of funds may take up to six banking days.
                </p>
                <h5>10.2 Withdrawing Funds from an kimbocorp Account</h5>
                <p>
                  A User may withdraw funds from the User’s kimbocorp account at
                  any time. A User withdrawal request is typically processed
                  within one banking day provided the User has supplied
                  kimbocorp with the necessary requested User and bank
                  information. In rare cases, withdrawals may take up to six
                  banking days or be further delayed if placed on hold for
                  verification purposes or due to suspicions or abnormal account
                  activity.
                </p>
                <p>
                  kimbocorp does not charge any fee for withdrawals although the
                  Userbears any loss that may result from currency conversion or
                  any fee, charge or cost which the User’s bank or payment
                  service provider may impose. There is no maximum or minimum
                  withdrawal limit.
                </p>
                <p>
                  <span>
                    A User can withdraw funds from the User’s kimbocorp account
                    in the following ways:
                  </span>
                </p>
                <ul className="alphaList">
                  <li>
                    &bull; Singapore Dollar: PayNow, domestic or international
                    bank transfer
                  </li>
                  <li>&bull; US Dollar: International bank transfer</li>
                </ul>
                <h5>
                  10.3 Miscellaneous Provisions Relating to the kimbocorp
                  Account
                </h5>
                <p>
                  If a User has an outstanding order, obligation or liability to
                  kimbocorp, kimbocorp may use the funds on that User’s
                  kimbocorp account, regardless of currency, to settle the
                  outstanding order or invoice when the amount is overdue.
                  Holding a negative balance on a kimbocorp account is
                  prohibited. Where a User has multiple currency balances and a
                  negative balance arises on one currency balance as a
                  consequence of an order being amended, the User agrees that
                  kimbocorp shall have the right to offset a negative balance in
                  one currency by using funds maintained by the User in another
                  currency. kimbocorp may also offset the negative balance with
                  any funds subsequently added to that User’s account.
                </p>
                <p>
                  A User may only open one kimbocorp user account. In providing
                  User information online or via any other means of
                  communications, a User must provide true and correct User
                  information to kimbocorp. For security reasons, transactions
                  involving kimbocorp Account Balances can only be carried out
                  by the User being logged in to the User account and not via
                  telephone, e-mail or any other way of communication. The User
                  acknowledges that any such transaction must be initiated from
                  the User’s account.
                </p>
                <p>
                  A User who maintains a kimbocorp Account Balance must keep his
                  information on the account updated. A User may be requested to
                  further enter a PIN code, in addition to a password, both of
                  which are required on the kimbocorp webapp and mobile app,
                  every time the User initiates a transaction related to that
                  kimbocorp account. A User can update the User’s account
                  information anytime by logging into the kimbocorp webapp or
                  mobile app. When placing orders with kimbocorp and/or
                  utilizing an kimbocorp Account Balance, the User authorizes
                  kimbocorp to make inquiries in regards to establishing User
                  knowledge and/or to validate that User’s identity. This may
                  include but not be limited to asking a User for a copy of the
                  User’s identification, a copy of a utility bill, asking about
                  the User’s occupation, background or purpose of transaction
                  and source of funds. In cases where kimbocorp has requested
                  such due diligence information but is unable to complete the
                  verification process of the due diligence measures, kimbocorp
                  shall not commence or continue the business relationship with
                  the User. In case kimbocorp is unable to complete the
                  verification process due to the User being unresponsive or the
                  User refusing to provide any document or information requested
                  by kimbocorp and kimbocorp will debit an administration fee of
                  SGD 200. In the case of kimbocorp closing an kimbocorp account
                  due to, for example, but not limited to, the User being
                  unresponsive or refusing to send in requested documents and/or
                  reply to the questions asked by kimbocorp or the User does not
                  settle outstanding invoices despite repeated e-mail and mail
                  reminders or the User abusing kimbocorp's system and kimbocorp
                  cannot via phone, e-mail or mail reach the User, any assets or
                  funds on the User's kimbocorp account will accrue to kimbocorp
                  following a period of five years.
                </p>
                <p>
                  kimbocorp reserves the right to debit a fee of SGD 500 or SGD
                  100 per hour, whichever is higher, to reply to duly authorised
                  requests for information, such as, but not limited to, court
                  proceedings, divorce proceedings and bankruptcy proceedings.
                </p>
                <h5>
                  11. Default of an advisory service specific to provision of a
                  paid company plan
                </h5>
                <p>
                  A default situation in relation to your advisory service and
                  the provision of a paid company plan occurs if any of the
                  following conditions are met, whichever occurs first:
                </p>
                <p>
                  Non-Payment: The primary member fails to send payment via the
                  agreed payment method within one (1) month of the Billing Date
                  of the paid company plan.
                </p>
                <p>
                  Non-Responsiveness: The primary member does not respond to
                  three (3) payment requests sent via email, private message,
                  mobile communication, live chat, or any other communication
                  method on file specific to the paid company plan.
                </p>
                <h5>11.1 Consequences of Default</h5>
                <p>
                  If any of the default conditions above are activated, the
                  primary member is deemed to be in a default situation, under
                  which the following actions may be undertaken by kimbocorp:
                </p>
                <p>
                  Company Striking Off or Winding Down: kimbocorp reserves the
                  right to apply for the striking off or winding down of the
                  company managed by kimbocorp.
                </p>
                <p>
                  Director Removal and Corporate Registrar Action: The primary
                  member’s directorship may be removed via resignation, and the
                  company may be struck off the corporate registrar.
                </p>
                <p>
                  Legal Capacity as Filing Agent: All such actions are executed
                  under kimbocorp’s legal capacity as a registered filing agent
                  of the company.
                </p>
                <h5>11.2 Revival of Company After Default Notice</h5>
                <p>
                  The primary member may contact Kimbocorp within three (3)
                  months after a default notice is sent to request the revival
                  of the company. However, kimbocorp reserves the right to
                  charge administration fees for processing and managing the
                  revival request. The fees will depend on the complexity and
                  scope of the work required to reinstate the company.
                </p>
                <h5>11.3 Indemnification Clause</h5>
                <p>
                  kimbocorp, including its employees, agents, associates, and
                  stakeholders, shall be fully indemnified against any and all
                  financial losses, damages, claims, or liabilities incurred by
                  the primary member, their employees, agents, associates, or
                  stakeholders arising from actions taken under this default
                  situation.
                </p>
                <p>
                  This clause ensures that kimbocorp is protected against any
                  repercussions resulting from the default actions, as outlined
                  above.
                </p>
                <h5>12. Authorization for kimbocorp to Act as Agent</h5>
                <p>
                  The User authorizes kimbocorp’s employees, contractors,
                  associates and consultants as a fiduciary, limited in scope,
                  to arrange for the incorporation and statutory compliance of
                  User’s holding companies, as deemed necessary in the
                  situation. kimbocorp may sub-contract the incorporation and
                  compliance of holding companies to other corporate service
                  providers.
                </p>
                <p>
                  The User acknowledges and agrees that kimbocorp may act on
                  behalf of the User’s holding companies for the creation of
                  financial accounts, including banking, payment gateways,
                  crypto accounts, as well as liaising with government
                  authorities such as Income tax authority of Singapore (IRAS)
                  and Monetary authority of Singapore (MAS).
                </p>
                <p>
                  kimbocorp reserves the right to publish names of holding
                  companies created with kimbocorp on its websites. kimbocorp
                  furthermore reserves the right to supply independent third
                  party auditors with User particulars, for the purpose of
                  creating financial accounts or liaising with government
                  authorities.
                </p>
                <h5>13. Opening an Account</h5>
                <p>
                  The User must open an account online to create, consult or
                  invest in holding companies, or to maintain an account balance
                  with kimbocorp. To open an account, the User must enter the
                  User’s correct User information including an address and
                  choose a password. At the time of placing the first order or
                  transaction, the User must also select a PIN code consisting
                  of a minimum of 4 digits. The User will have to re-enter the
                  PIN code for certain subsequent transactions impacting a
                  User’s kimbocorp Account Balance.
                </p>
                <p>
                  When the User has opened an account, the User will receive a
                  confirmation sent to the User’s registered e-mail address. The
                  User will also receive e-mail confirmations of User-initiated
                  changes to the accounts, such as adding share capital, as well
                  as e-mail notifications about funding and withdrawal
                  activities on their kimbocorp Account Balances. The User shall
                  notify kimbocorp immediately in the event the User finds any
                  discrepancies on the User’s account or on any e-mail
                  notifications, e-mail updates, e-mail statements, in any SMS
                  text messages or in any other communication sent to the User’s
                  registered e-mail address, registered mobile phone number or
                  registered address.
                </p>
                <p>
                  To prevent abuse, each User is only allowed to create one user
                  account with kimbocorp. Notwithstanding, each User is allowed
                  to create multiple businesses with kimbocorp and advise any
                  associated business. Any user found to have abused the system
                  or holding multiple user accounts may be blacklisted and
                  barred from future transactions with kimbocorp. kimbocorp
                  reserves the right to retract the free Balance credited to the
                  User account if the User is found to have abused the system.
                  kimbocorp furthermore reserves the right to merge accounts if
                  the User has opened multiple user accounts.
                </p>
                <h5>14. Account Suspension and Termination</h5>
                <p>
                  kimbocorp reserves the right to suspend or terminate an
                  kimbocorp account for any reason, including but not limited to
                  events where:
                </p>
                <ul className="alphaList">
                  <li>
                    (a) Orders have been placed from the account but where
                    payment has not been initiated at all or has not reached
                    kimbocorp within the time frames given by these Terms and
                    Conditions
                  </li>
                  <li>
                    (b) The User has abused kimbocorp's system by e.g. but not
                    limited to: opened several accounts and placed an excessive
                    amount of small orders within a short time period leading to
                    unreasonable administrative burden.
                  </li>
                  <li>
                    (c) The User has not responded to, been unwilling to
                    respond, acted suspiciously or responded unsatisfactorily to
                    a Know-Your-Customer, Due Diligence or Periodic Review
                    request.
                  </li>
                  <li>
                    (d) kimbocorp suspects that the User is using his/her
                    kimbocorp account for illegal or disallowed purposes.
                  </li>
                  <li>
                    (e) kimbocorp suspects that the User has attempted or
                    conducted fraudulent activities.
                  </li>
                  <li>
                    (f) The User has outstanding debts with kimbocorp remaining
                    unpaid following more than six (6) months of the last
                    payment date.
                  </li>
                  <li>
                    (g) There is an ongoing dispute between kimbocorp and the
                    User or an ongoing dispute between joint account holders or
                    signatories of the User.
                  </li>
                  <li>
                    (h) The User has requested for the account to be locked or
                    closed.
                  </li>
                  <li>
                    (i) The User has threatened, abused, insulted, harassed or
                    acted unlawfully against kimbocorp or any of its personnel.
                  </li>
                </ul>
                <p>
                  Where a User's account will be terminated and there is no
                  other agreement with the User on asset liquidation and fund
                  withdrawal upon or after termination, balance held by the User
                  will be liquidated within 1 business day of account
                  termination at the prevailing rate; liquidation will be in the
                  currency which kimbocorp deems most appropriate; and funds
                  will be remitted to the User’s bank account as notified by the
                  Useror held by kimbocorp without interest in the absence of
                  such notice.
                </p>
                <p>
                  Ownership in incorporated holding companies held by the User
                  will continue to exist despite an account being terminated.
                  Ownership in un-incorporated holding companies held by the
                  User may be transferred back to the Creator or other
                  Shareholders.
                </p>
                <h5>15. Death</h5>
                <p>
                  A family member of a deceased User should notify kimbocorp
                  promptly of the User's demise and provide a copy of the death
                  certificate as soon as practicable.
                </p>
                <p>
                  The family member(s) of the deceased User should obtain from a
                  Singapore court either (1) a Grant of Probate (if the deceased
                  User left a will), or (2) a Grant of Letters of Administration
                  (if the deceased User did not leave a will) (“Grant”). If a
                  Grant has been obtained overseas, the family member(s) may
                  obtain a Resealing of Foreign Grant from a Singapore court.
                  These are usually done with the help of a lawyer in Singapore.
                  The personal representative appointed by the Grant must then
                  provide us with a certified true copy of the (1) Grant (or
                  Reseal of Foreign Grant) and (2) identification document of
                  every appointed personal representative.
                </p>
                <p>
                  It is important that the User’s name and address on the
                  kimbocorp account match the same information on the Grant.
                  Therefore, the User should ensure that his/her kimbocorp
                  account bears his/her full name and address according to
                  his/her primary identification document.
                </p>
                <p>
                  For a trust account held in the name of a deceased trustee,
                  the succeeding trustee should provide us with a copy of
                  his/her identification document and documentary evidence that
                  he/she has replaced the deceased trustee. The documentary
                  evidence can be a copy of a recent statement of the existing
                  bank account of the trust reflecting the same account number
                  and the name of the succeeding trustee as trustee of the
                  account.
                </p>
                <p>
                  Once we have verified the documents and information, the
                  personal representative will be able to access the deceased
                  User’s account. We reserve the right to debit an
                  administration fee.
                </p>
                <h5>16. Privacy Policy</h5>
                <p>
                  This website uses cookies and session cookies to facilitate
                  activities for individual users. The information is used for
                  identification purposes and to fill in data fields to
                  facilitate the accessibility of services on this website.
                  Cookies are also used for statistical purposes. Session
                  cookies are only used for the duration you are logged in to
                  store session information. The information collected is
                  anonymous. If you do not wish to accept cookies, your browser
                  can be set to deny cookies. This however would affect the
                  functionality that you would experience when using this
                  website.
                </p>
                <p>
                  kimbocorp protects its Users personal integrity and processes
                  User information with full confidentiality. Following the
                  EU-regulation on General Data Protection Regulation, effective
                  25 May 2018, kimbocorp would like to inform you how the
                  company handles personal information. Through its e-commerce
                  system, kimbocorp collects the personal information that its
                  users have supplied the company with. The following
                  information is collected: name, e-mail, telephone number,
                  address, post code, city and country. In some instances,
                  including but limited to when a User has funds on his or her
                  account with kimbocorp, kimbocorp collects additional
                  Know-Your-Customer information such as e.g., but not limited
                  to, the User's nationality, a copy of the User's
                  identification and a copy of the User's proof of address. The
                  collected information is used to assist registered account
                  holders and Users with information and service. kimbocorp also
                  uses personal information for marketing in the form of
                  newsletters and promotional offers via e-mail or mail. It is
                  possible to unsubscribe from the newsletters and promotional
                  offers via the unsubscribe link that all such e-mails contain.
                  kimbocorp is keen to protect its Users’ confidentiality and
                  does not share or sell any personal information with the
                  exception of information that the company is required to
                  disclose under law. kimbocorp saves the Users personal
                  information as long as there are legal obligations for the
                  company to do so. As a User, you can at any time request us to
                  supply you with the personal information registered about you.
                  You can at any time request us to change incorrect information
                  and you can at any time request us to delete your personal
                  information provided that there are no legal obligations for
                  us to retain it. kimbocorp's e-commerce system is rigorously
                  constructed to protect your personal information. There's a
                  limited number of staff members in managerial positions who
                  have access to handle your personal information. If you have
                  any questions concerning the treatment or collection of
                  personal information, please contact kimbocorp at
                  <a href="mailto:support@kimbocorp.com">
                    support@kimbocorp.com.
                  </a>
                </p>
                <h5>17. Law and Disputes</h5>
                <p>
                  This Agreement shall be governed and interpreted according to
                  the laws of Singapore. Any action by either party which does
                  not comply with Singaporean laws shall be deemed void.
                </p>
                <p>
                  Any dispute arising out of, or in connection with this
                  Agreement, shall primarily be subject to mediation between the
                  parties and if not resolved be referred to and resolved by
                  arbitration in Singapore in accordance with the arbitration
                  rules of the Singapore International Arbitration Centre. The
                  tribunal shall consist of one (1) arbitrator and the
                  arbitration shall be held in the language of English.
                </p>
                <h5>18. General and Miscellaneous Provisions</h5>
                <ul className="alphaList">
                  <li>
                    a) The User guarantees that all representations made to
                    kimbocorp are true. The User gives kimbocorp the warranty
                    that none of the funds paid to kimbocorp under this
                    Agreement is connected to any illegal activities.
                  </li>
                  <li>
                    b) All instances of fraud or attempted fraud will be
                    reported by kimbocorp to the police.
                  </li>
                  <li>
                    c) The User is solely responsible for understanding and
                    complying with all laws, rules and regulations in the
                    jurisdictions applicable to the User.
                  </li>
                  <li>
                    d) The User must inform kimbocorp of any changes in the
                    User's contact details and other information provided to us,
                    including, but not limited to: postal address, e-mail
                    address and mobile telephone number. kimbocorp may
                    periodically contact the User to validate the information.
                    If the information cannot be validated, kimbocorp reserves
                    the right to lock the account until kimbocorp can verify the
                    User’s information details.
                  </li>
                  <li>
                    e) Any notice communicated by kimbocorp shall, unless
                    otherwise noted in this Agreement, be e-mailed to the User’s
                    e-mail address provided by the User’s kimbocorp account. Any
                    notice by kimbocorp will take effect on the next day of it
                    being sent.
                  </li>
                  <li>
                    f) The Useror kimbocorp account holder agrees to kimbocorp
                    sending out periodic e-mails with information about the
                    market, products, events, promotions or similarly. The User
                    can always choose to unsubscribe from these e-mails by
                    clicking the unsubscribe link in such an e-mail.
                  </li>
                  <li>
                    g) In the event that the User fails to fulfill the
                    obligations under this Agreement, kimbocorp reserves the
                    right to assign a law firm or a debt collection company to
                    act on kimbocorp’s behalf. Any costs or expenses incurred in
                    relation to such an act shall be borne by the User.
                  </li>
                  <li>
                    i) Communicating with kimbocorp or reading material on
                    kimbocorp’s website should not be taken as investment
                    advice.
                  </li>
                  <li>
                    k) kimbocorp makes no representations about the advisability
                    of purchasing items on our site as investment and does not
                    give financial advice.
                  </li>
                  <li>
                    l) These Terms and Conditions are not a solicitation of any
                    products, plans or services and are not targeting any
                    specific country or market.
                  </li>
                  <li>
                    m) The User may not transfer the rights and obligations
                    under this contract to anyone else.
                  </li>
                </ul>
                <h5>19 Limitation of Liability</h5>
                <h5>19.1 Transactions</h5>
                <p>
                  kimbocorp cannot be held responsible for any loss or damage in
                  the case the User has acted negligently in regard to the
                  security of the User’s account generally, or password or PIN
                  code specifically. kimbocorp can furthermore not be held
                  responsible for any loss or damage in case the User has
                  neglected kimbocorp's strong recommendation of enabling
                  2-factor authentication. Furthermore, kimbocorp cannot be held
                  responsible for any User using an unsecured computer in
                  accessing the User’s account.
                </p>
                <p>
                  kimbocorp will not be liable for any loss resulting from, but
                  not limited to, inaccuracies in bank details, inaccuracies in
                  cryptocurrency payment details such as incorrect
                  cryptocurrency selected or inaccurate cryptocurrency address
                  input or other incorrect User details supplied by the User.
                  kimbocorp shall not be liable for any damages or losses,
                  including direct, indirect, consequential or incidental
                  damages, which the user may incur due to the use or
                  termination of the website, or due to the fault or negligence
                  of any entity furnishing any facilities, equipment or services
                  used in connection with the website, or due to the failure or
                  delays in transmission, malfunction of equipment, breakdown or
                  failure of any telecommunications systems, software or
                  hardware provided by any entity used to operate the website,
                  or due to any other such similar causes beyond the reasonable
                  control or anticipation of kimbocorp.
                </p>
                <p>
                  If it is determined that kimbocorp is held liable to the User
                  with regard to any transaction consistent to this Agreement,
                  kimbocorp’s liability to the User in relation or due to any
                  transaction shall be limited to the amount paid by the User in
                  such transaction. In no event shall kimbocorp be held liable
                  for any indirect, consequential, or exemplary damages.
                </p>
                <p>19.2 Force Majeure</p>
                <p>
                  kimbocorp is not liable for failure to perform its obligations
                  if such failure is a result of a superior or irresistible
                  force or Act of God that cannot be reasonably anticipated or
                  controlled.
                </p>
                <p>
                  If kimbocorp asserts Force Majeure as an excuse for failure to
                  perform its obligation, kimbocorp must prove that it took
                  reasonable steps to minimize delay or damages caused by
                  foreseeable events.
                </p>
                <h5>20. Confidentiality and Privacy</h5>
                <p>
                  kimbocorp protects the confidentiality of its Users. kimbocorp
                  will not divulge any User information relating to this
                  Agreement or otherwise to any third party unless forced to do
                  so under Singapore law. kimbocorp may however disclose User
                  information to its employees, agents and contractors for them
                  to fulfill the obligations and requirements as stipulated in
                  this Agreement.
                </p>
                <p>
                  kimbocorp respects the privacy of its Users. kimbocorp's
                  practices concerning the collection, storage, processing,
                  analysis, transfer, and/or use of the User's personal
                  information reflect kimbocorp's commitment and integrity in
                  providing efficient and reliable services. The User
                  information that is gathered by kimbocorp is used for
                  Userservice purposes only.
                </p>
                <h5>21. Third Party Access</h5>
                <p>
                  The User must not enable any third party to access the User’s
                  kimbocorp account, open or operate an kimbocorp account on the
                  User’s behalf, or access the User’s computer or mobile device
                  from which the aforesaid can be performed. The User must not
                  provide any information, document or any form of verification
                  to any third party which may be used in relation to any
                  account at or transaction with kimbocorp. If the User
                  authorizes a representative to operate the User’s account at
                  or transactions with kimbocorp, that representative must open
                  an kimbocorp account in the representative’s own name and
                  declare to kimbocorp whom the representative acts for.
                </p>
                <p>
                  Without prejudice to any of kimbocorp’s rights under this
                  Agreement, where the User is in breach of any provision under
                  this section: (a) the Userfully indemnifies kimbocorp for any
                  loss, cost, expense, penalty and fee which kimbocorp may incur
                  whether directly or indirectly in relation to the User’s
                  breach (Costs); and (b) kimbocorp will have the right in
                  respect of the User’s account to amend the payment terms of or
                  invalidate any order. Where an order is invalidated under the
                  provisions of this section, kimbocorp will return to the
                  Userany payment made by the User towards the order after
                  deducting Costs to the originating source of the payment, or
                  where that is not possible then in the manner which kimbocorp
                  deems most appropriate for that purpose.
                </p>
                <h5>22. Validity</h5>
                <p>
                  kimbocorp reserves the right to limit, suspend, put on hold or
                  close a User account and/or limit access to funds held on a
                  User account if the User violates the Terms and Conditions in
                  this document or in any other Agreement entered into with
                  kimbocorp.
                </p>
                <p>
                  kimbocorp may terminate this Agreement upon fourteen (14)
                  days' notice given to the User. In case of termination,
                  kimbocorp will send a notice to the registered e-mail address
                  of the User as provided on the User account with kimbocorp or
                  alternatively send a notice to the User's registered postal
                  mail. The User is responsible for the payment of all fees
                  incurred for statutory maintenance of holding companies
                  regardless of the reason for the termination.
                </p>
                <p>kimbocorp may terminate this Agreement at any time if:</p>
                <ul className="alphaList">
                  <li>
                    a) The User breaches any of the terms of this Agreement
                  </li>
                  <li>
                    b) The User goes into liquidation, or is declared bankrupt
                  </li>
                  <li>
                    c) The User is suspected to be using the service of the
                    company for illegal activities, including but not limited
                    to, money laundering and terrorism financing.
                  </li>
                  <li>
                    d) The User fails to make punctual payment of the fees in
                    accordance with the provisions of this Agreement.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
